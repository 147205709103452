import { HOST } from "../constants/HostConstants";

export const userUpdate = async (user, setUser) => {
  const response = await fetch(`${HOST}/api/user/userUpdate`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      userId: user.user_id,
      user,
      token: localStorage.getItem("token"),
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    });

  if (!response.success) {
    throw new Error("Changes Unsuccessful");
  }

  const data = response;

  console.log(data);

  if (data.user) {
    setUser(data.user);
  }

  if (data.token) {
    localStorage.setItem("token", data.token);
  }

  return data;
};

