import { Link } from "react-router-dom";
import { Phone, Mail } from "lucide-react";
const ProfessionalPreview = ({ professional, full }) => {
  const user = professional;
  return (
    <>
      <Link
        to={`/user/${user.user_id}`}
        className="w-full border border-base-300 p-2 flex gap-3 flex-col items-center hover:scale-[1.01] transition-all ease-in-out"
        onClick={() => localStorage.setItem("tab", "Profile")}
      >
        <div className={`relative w-[60px] h-[60px] `}>
          <img
            className="h-full absolute object-cover border border-base-200 w-full rounded-full "
            src={
              user?.picture_url
                ? `https://res.cloudinary.com/diur0igkv/image/upload/${user?.picture_url}`
                : "/error.png"
            }
            alt=""
          />
        </div>

        <h4 className="max-w-[130px] text-center">
          {user?.legal_first_name} {user?.legal_last_name}
        </h4>
        <div className="flex flex-col items-center gap-5 justify-between">
          <div className="space-y-1 flex flex-col items-center justify-center">
            <div className="badge badge-neutral">
              {user.class_type === "Title Closer"
                ? "Escrow Officer"
                : user.class_type}
            </div>
            {user?.class_type === "REALTOR" ||
            user?.class_type === "Broker / Owner" ||
            user?.class_type === "Broker / Associate" ? (
              <div className="text-center">
                <Link
                  className="w-full link link-hover link-primary text-[14px]"
                  to={``}
                >
                  {user?.brokerage_name}
                </Link>
              </div>
            ) : (
              ""
            )}
            {user?.class_type === "Lender" ? (
              <div className="text-center">
                <Link
                  className="link link-primary link-hover text-[14px]"
                  to={``}
                >
                  {user?.lending_company_name}
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="hidden md:flex flex-col gap-3">
            <a
              href={user?.phone_number ? `tel:${user?.phone_number}` : null}
              className="btn btn-xs text-[10px]"
            >
              <Phone size={16} />
              {user?.phone_number ? user?.phone_number : "No Number Found"}
            </a>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProfessionalPreview;
