import { HOST } from "../constants/HostConstants"
export const userPending = async ({setPending}) => {
    fetch(`${HOST}/api/user/userPending`, {
        method: 'GET',
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
        }
    }).then((res)=> res.json()).then(data=> {
        setPending(data)
    })
}