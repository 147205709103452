import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userById } from "../../actions/userById";
const Preferred = ({
  preferred,
  type,
  setLenderFound,
  setTitleCloserFound,
}) => {
  const [poster, setPoster] = useState(false);
  const nav = useNavigate();
  useEffect(() => {
    if (preferred) {
      userById(preferred, { setPoster });
    }
  }, [preferred]);

  useEffect(() => {
    if (type === "Title Closer" && poster === null) {
      setTitleCloserFound(false);
    } else if (type === "Lender" && poster === null) {
      setLenderFound(false);
    }
  }, [poster]);
  return (
    <div
      className="border boder-base-300 rounded-md px-1 py-2 cursor-pointer hover:bg-base-200/50 flex gap-2"
      onClick={() => nav(poster ? `/user/${preferred}` : "")}
    >
      <div className="w-[40px] h-[40px] relative">
        <img
          className="rounded-full w-full h-full absolute object-cover"
          src={
            poster?.picture_url
              ? `https://res.cloudinary.com/diur0igkv/image/upload/${poster?.picture_url}`
              : "/error.png"
          }
          alt="preferred"
        />
      </div>
      {poster ? (
        <div className="space-y-0 pr-2 text-xs">
          <h2>
            {poster?.legal_first_name} {poster?.legal_last_name}
          </h2>
          <div className="text-xs">
            <div>
              {poster?.class_type !== "Consumer" ? (
                poster?.class_type === "REALTOR" ? (
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-wrap">
                      <a
                        href=""
                        className="link link-hover link-primary  max-w-[175px]  truncate"
                      >
                        {poster?.brokerage_name}
                      </a>
                    </div>
                  </div>
                ) : poster?.class_type === "Broker / Owner" ? (
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-wrap">
                      <a
                        href=""
                        className="link link-hover link-primary text-xs max-w-[175px]  truncate"
                      >
                        {poster?.brokerage_name}
                      </a>
                    </div>
                  </div>
                ) : poster?.class_type === "Broker / Associate" ? (
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-wrap">
                      <a
                        href=""
                        className="link link-hover link-primary text-xs max-w-[175px]  truncate"
                      >
                        {poster?.brokerage_name}
                      </a>
                    </div>
                  </div>
                ) : poster?.class_type === "Builder" ? (
                  <div className="flex flex-wrap">
                    <div className="flex flex-wrap">
                      <a
                        href=""
                        className="link link-hover link-primary text-xs max-w-[175px]  truncate"
                      >
                        {poster?.builder_company_name}
                      </a>
                    </div>
                  </div>
                ) : // Continue checking for other class_types
                poster?.class_type === "Lender" ? (
                  <div className="flex md:flex-row flex-col">
                    <div className=" flex flex-wrap">
                      <a
                        href=""
                        className="link link-primary link-hover text-xs max-w-[175px]  truncate"
                      >
                        {poster?.lending_company_name}
                      </a>
                    </div>
                  </div>
                ) : poster?.class_type === "Title Closer" ? (
                  <div className="flex md:flex-row flex-col">
                    <div className="flex flex-wrap">
                      <a
                        href=""
                        className="link link-primary link-hover text-xs max-w-[175px]  truncate"
                      >
                        {poster?.title_company_name}
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-0 pr-4">User Loading...</div>
      )}
    </div>
  );
};

export default Preferred;
