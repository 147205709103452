import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import PieChart from "../components/piechart";
import { keyInformation } from "../actions/key/keyinformation";
import PieChartStaking from "../components/PieStaking";

const KeyInformation = () => {
  const [dataValues, setDataValues] = useState(null);
  const [topTen, setTopTen] = useState(null);
  const [totalKeys, setTotalKeys] = useState(null);
  const [otherKeys, setOtherKeys] = useState(null);
  const [staking, setStaking] = useState(null);
  const [stakingEarned, setStakingEarned] = useState(null);
  useEffect(() => {
    keyInformation({
      setDataValues,
      setTopTen,
      setTotalKeys,
      setOtherKeys,
      setStaking,
      setStakingEarned,
    });
  }, []);
  function addCommasToNumber(number) {
    // Convert number to string
    let numberString = number.toString();

    // Add commas every three digits from the right
    let formattedNumber = "";
    let count = 0;
    for (let i = numberString.length - 1; i >= 0; i--) {
      count++;
      formattedNumber = numberString[i] + formattedNumber;
      if (count % 3 === 0 && i !== 0) {
        formattedNumber = "," + formattedNumber;
      }
    }

    return formattedNumber;
  }
  function calculatePercentage(userKeys, totalKeys) {
    // Ensure both userKeys and totalKeys are numbers
    userKeys = Number(userKeys);
    totalKeys = Number(totalKeys);

    // Check if totalKeys is zero to avoid division by zero
    if (totalKeys === 0) {
      return 0; // Return 0 if totalKeys is zero
    }

    // Calculate the percentage
    let percentage = (userKeys / totalKeys) * 100;

    // Round to the nearest two decimal points
    percentage = Math.round(percentage * 100) / 100;

    return `${percentage}%`;
  }

  return (
    <>
      <main className="mx-auto max-w-[1440px] mt-12 mb-64 px-[24px]  space-y-5">
        <div className="leading-relaxed space-y-4">
          <h3 className="text-3xl font-medium text-center">About</h3>
          <p className="indent-6">
            MyLlave was created by Tony Villeda, Broker / Owner of Glass Realty
            as an answer to two of the largest issues facing real estate right
            now - the access of information and the fair compensation towards
            its content creators - real estate agents, builders, and those who
            sell properties on their own. With the proliferation of paywalls,
            subscription fees, and online portals that utilize creator content
            to generate ad revenue that is never shared, MyLlave is a complete
            answer to these two problems in the form of a free to access online
            database that splits all advertising revenue generated 50/50 with
            its users.
          </p>
          <p className="indent-6">
            This paradigm shifting approach to real estate data and the access
            to information is not only meant to rectify a currently existing
            imbalance in the unjust distribution of said revenue, but also
            create a more comprehensive (and reliable) set of real estate
            information that will allow for better decision making. By taking
            down paywalls, subscription fees, and creating this cash
            incentivization for users to participate in this game changing
            approach, we can all work towards creating a more fair and equitable
            landscape for everyone that is involved in the real estate
            community.
          </p>
          <p className="text-center">This is real estate redefined.</p>
          <p className="text-center font-semibold">This is MyLlave.</p>
          <div className="flex justify-end">
            <p>- Tony Villeda, Creator</p>
          </div>
        </div>

        <h3 className="text-3xl font-medium text-center">About Keys</h3>
        <div className="leading-relaxed space-y-4">
          <h4 className="text-xl font-medium">What are keys?</h4>
          <p>
            Keys are the in-site tokens that users earn when completing certain
            actions such as listing a property, filing a listed property as
            closed or leased, staking their keys, and receiving views on
            properties with more options to come as the site grows.
            Additionally, keys can be given by purchasing them via USD. The
            current transfer rate for purchase is $.005 for 1 key, however, a
            purchase table for advertisers can be seen below.
          </p>
        </div>
        <div className="leading-relaxed space-y-4">
          <h4 className="text-xl font-medium">How can keys be used?</h4>
          <p>
            Keys can be either spent in-site to promote properties, specific ad
            campaigns, or even for your own profile to appear under the promoted
            section of other properties. They can also be withdrawn from the
            site for USD or be used to purchase real life swag.{" "}
          </p>
          <p>
            Keys can also be staked for a period of 3 months, 6 months, 9
            months, or 1 year at various earning rates. This means that your
            keys are not accessible until the time chosen is achieved, but
            during this time the keys are earning more keys as a reward for
            adding stability to the system's ability to continue to operate.
            Once staked, these keys cannot be unstaked without penalty.{" "}
          </p>
        </div>
        <div className="leading-relaxed space-y-4">
          <h4 className="text-xl font-medium">How are keys managed?</h4>
          <p>
            There will only ever be 1,000,000,000 keys created, and this is kept
            in check by an accounting system that you can view below. When a key
            is earned, the key will be debited from the Master Key Holding
            Account which is fed by advertisers and contributors who purchase
            keys to promote on the site. Keys are supplied to the Master Holding
            Account when one sells keys to the site from their account. While
            the value of a key in USD can conceivably change in the future as
            the site grows,{" "}
            <span className="text-error font-semibold">
              there is no promise of profit in this, and this website does not
              promote the speculation of this for profit.
            </span>{" "}
          </p>
        </div>

        <div className="w-full gap-10 lg:gap-5 lg:flex block relative">
          <div className="flex-[0.5] w-1/3 max-h-[450px]  space-y-5 mx-auto relative ">
            <h3 className="text-2xl font-medium text-center">
              Key Distribution
            </h3>
            <PieChart dataValues={dataValues} />
          </div>
          <div className="flex-[0.5] relative lg:mt-0 mt-10">
            <h3 className="text-2xl font-medium text-center mb-5">
              Top 10 User Holding Accounts
            </h3>
            <div className="overflow-x-auto">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number Of Keys</th>
                    <th>Percentage Held</th>
                  </tr>
                </thead>
                <tbody>
                  {dataValues && (
                    <>
                      <tr>
                        <td>Keys Holding</td>
                        <td>{addCommasToNumber(dataValues[0])}</td>
                        <td>{calculatePercentage(dataValues[0], totalKeys)}</td>
                      </tr>
                      <tr>
                        <td>Operating Account</td>
                        <td>{addCommasToNumber(dataValues[1])}</td>
                        <td>{calculatePercentage(dataValues[1], totalKeys)}</td>
                      </tr>
                    </>
                  )}

                  {topTen &&
                    topTen.map((one, index) => (
                      <tr>
                        <td>
                          {one.legal_first_name} {one.legal_last_name}
                        </td>
                        <td>
                          {one.key_balance
                            ? addCommasToNumber(one.key_balance)
                            : ""}
                        </td>
                        <td>
                          {calculatePercentage(one.key_balance, totalKeys)}
                        </td>
                      </tr>
                    ))}
                  {otherKeys && (
                    <tr className="my-2" colSpan="3">
                      <td className="font-medium w-fit col-span-1">
                        Other Users:
                      </td>{" "}
                      <td className="col-span-2">
                        {otherKeys && addCommasToNumber(otherKeys)}
                      </td>
                      <td>{calculatePercentage(otherKeys, totalKeys)}</td>
                    </tr>
                  )}
                  <tr className="my-2" colSpan="3">
                    <td className="font-medium w-fit col-span-1">
                      Total Keys:
                    </td>{" "}
                    <td className="col-span-2">
                      {totalKeys && addCommasToNumber(totalKeys)}
                    </td>
                    <td>100%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="w-full  gap-10 lg:gap-5 lg:flex block relative pt-32">
          <div className="flex-[0.5] w-1/3 max-h-[450px]  space-y-5 mx-auto relative ">
            <h3 className="text-2xl font-medium text-center">Key Staked</h3>
            <PieChartStaking dataValues={staking} />
          </div>
          <div className="flex-[0.5] relative lg:mt-0 mt-10">
            <div className="overflow-x-auto">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Unstaked Keys</td>
                    <td>
                      {staking ? addCommasToNumber(1000000000 - staking) : null}
                    </td>
                  </tr>
                  <tr>
                    <td>Staked Keys</td>
                    <td>{staking ? addCommasToNumber(staking) : null}</td>
                  </tr>

                  <tr className="my-2" colSpan="3">
                    <td>Total Keys:</td>
                    <td>
                      {staking
                        ? addCommasToNumber(staking + (1000000000 - staking))
                        : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default KeyInformation;
