import { HOST } from "../../constants/HostConstants";
export const markAsSold = async (
  close_price,
  close_date,
  pending_date,
  buyer_cc_no_concessions,
  cc_concession_amount,
  financing,
  non_allowables,
  non_realty_items,
  repairs,
  non_conveyances,
  selling_individual,
  property_id,
  user_id,
) => {
  const response = await fetch(`${HOST}/api/property/markAsSold`, {
    method: "POST",
    body: JSON.stringify({
      close_price: close_price,
      close_date: close_date,
      pending_date: pending_date,
      buyer_cc_no_concessions: buyer_cc_no_concessions,
      cc_concession_amount: cc_concession_amount,
      financing: financing,
      non_allowables: non_allowables,
      non_realty_items: non_realty_items,
      repairs: repairs,
      non_conveyances: non_conveyances,
      selling_individual: selling_individual,
      property_id: property_id,
      user_id: user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Property Not Marked As Sold");
  } else {
    return response;
  }
};
