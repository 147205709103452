import { useEffect, useState } from "react";

const spaceType = [
  "Industrial",
  "Medical",
  "Mixed Use",
  "Multi-Family",
  "Office",
  "Retail",
  "Warehouse",
];

const Unit = ({ propertyData, setPropertyData, handleChange }) => {
  const [unitAmount, setUnitAmount] = useState(
    propertyData &&
      propertyData["unit_details"] &&
      propertyData["unit_details"].length > 0
      ? propertyData["unit_details"].length
      : 1,
  );

  useEffect(() => {
    if (!propertyData) return;

    if (
      propertyData.sub_type === "Duplex" &&
      (!propertyData.unit_details || propertyData.unit_details.length === 0)
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        unit_details: [
          {
            building_number: null,
            bedrooms: null,
            bathrooms: null,
            living_areas: null,
            dining_areas: null,
            rented: null,
            rent: null,
            living_sqft: null,
          },
          {
            building_number: null,
            bedrooms: null,
            bathrooms: null,
            living_areas: null,
            dining_areas: null,
            rented: null,
            rent: null,
            living_sqft: null,
          },
        ],
      }));
      setUnitAmount(2);
    } else if (
      propertyData.sub_type === "Fourplex" &&
      (!propertyData.unit_details || propertyData.unit_details.length === 0)
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        unit_details: Array.from({ length: 4 }).map(() => ({
          building_number: null,
          bedrooms: null,
          bathrooms: null,
          living_areas: null,
          dining_areas: null,
          rented: null,
          rent: null,
          living_sqft: null,
        })),
      }));
      setUnitAmount(4);
    } else if (
      propertyData.type === "Commercial" &&
      (!propertyData.unit_details || propertyData.unit_details.length === 0)
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        unit_details: [
          {
            unit_number: null,
            floor_number: null,
            entire_floor: null,
            divisible: null,
            date_available: null,
            lease_expiration_date: null,
            sqft_total_available: null,
            sqft_min_available: null,
            sqft_floor_contiguous: null,
            space_type: null,
            rent_per_sqft: null,
            rent_services: null,
            transaction_type: null,
            occupancy: null,
          },
        ],
      }));
      setUnitAmount(1);
    } else if (
      propertyData["unit_details"] &&
      propertyData["unit_details"].length > 0
    ) {
      setUnitAmount(propertyData["unit_details"].length);
    }
  }, [propertyData]);
  const handleChangeCheck = (e, index) => {
    const { name, checked } = e.target;
    console.log(name);
    console.log(index);

    setPropertyData((prevData) => {
      const updatedUnitDetails = [...prevData.unit_details];
      let currentSpaceType = updatedUnitDetails[index]?.space_type;

      // Convert currentSpaceType to an array if it's a comma-separated string
      if (typeof currentSpaceType === "string") {
        currentSpaceType = currentSpaceType.split(",");
      }

      if (!currentSpaceType) {
        // If space_type is null, initialize it as an empty array
        currentSpaceType = [];
      }

      if (checked) {
        // If checked, add the name to space_type if it's not already there
        if (!currentSpaceType.includes(name)) {
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index], // Keep other fields unchanged
            space_type: [...currentSpaceType, name], // Add the name to space_type
          };
        }
      } else {
        // If unchecked, remove the name from space_type if it's present
        if (currentSpaceType.includes(name)) {
          updatedUnitDetails[index] = {
            ...updatedUnitDetails[index], // Keep other fields unchanged
            space_type: currentSpaceType.filter((type) => type !== name), // Remove the name from space_type
          };
        }
      }

      return { ...prevData, unit_details: updatedUnitDetails };
    });
  };

  // Handle unit value being changed
  const handleUnitChange = (e, index) => {
    console.log(e, index);
    const { name, value } = e.target;

    const updatedPropertyData = { ...propertyData };

    // Retrieve the unit_details array from the propertyData object
    const unitDetails = updatedPropertyData.unit_details;

    console.log(updatedPropertyData.unit_details[index].building_number);
    // Update the specific unit at the given index with the new name-value pair
    if (unitDetails[index]) {
      unitDetails[index][name] = value;
    } else {
      // If the unit at the given index doesn't exist, create a new unit object
      unitDetails[index] = { [name]: value };
    }

    // Update the propertyData object with the updated unit_details array
    updatedPropertyData.unit_details = unitDetails;
    setPropertyData(updatedPropertyData);
  };

  return (
    <>
      {(propertyData["sub_type"] === "Duplex" ||
        propertyData["sub_type"] === "Fourplex") &&
        propertyData["unit_details"] &&
        propertyData["unit_details"].length > 0 && (
          <div className="space-y-5">
            <div className="flex justify-between items-center">
              <h3 className="text-19 font-medium">Unit Values</h3>
              <button
                className="btn btn-outline btn-primary btn-sm"
                onClick={() => {
                  setPropertyData((prevData) => ({
                    ...prevData,
                    unit_details: [
                      ...prevData.unit_details,
                      {
                        building_number: null,
                        bedrooms: null,
                        bathrooms: null,
                        living_areas: null,
                        dining_areas: null,
                        rented: null,
                        rent: null,
                        living_sqft: null,
                      },
                    ],
                  }));
                  setUnitAmount(unitAmount + 1);
                }}
              >
                Add new unit
              </button>
            </div>
            <hr />
            {/* Create functionality to write to the appropriate index array object of unit_details */}
            <div className="space-y-5">
              {Array.from({ length: unitAmount }).map((_, index) => (
                <div
                  key={index}
                  className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 ${index % 2 === 1 ? "bg-base-300" : ""} p-4 !pt-1 px-2 rounded-lg gap-3`}
                >
                  <div className="flex justify-between items-center col-span-2 md:col-span-3 lg:col-span-8">
                    <h2 className="col-span-2 md:col-span-3 lg:col-span-8 pl-1">
                      Unit {index + 1}
                    </h2>
                    {index + 1 === unitAmount && (
                      <button
                        className={`btn ${index % 2 === 1 ? "" : "btn-outline"} btn-error btn-sm mt-2`}
                        onClick={() => {
                          setPropertyData((prevData) => ({
                            ...prevData,
                            unit_details: prevData.unit_details.slice(0, -1),
                          }));
                          setUnitAmount(unitAmount - 1);
                        }}
                      >
                        Remove Unit
                      </button>
                    )}
                  </div>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Building Number</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="building_number"
                      value={
                        propertyData["unit_details"][index].building_number ||
                        ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Bedrooms</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="bedrooms"
                      value={propertyData["unit_details"][index].bedrooms || ""}
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Bathrooms</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="bathrooms"
                      value={
                        propertyData["unit_details"][index].bathrooms || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Living Areas</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="living_areas"
                      value={
                        propertyData["unit_details"][index].living_areas || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Dining Areas</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="dining_areas"
                      value={
                        propertyData["unit_details"][index].dining_areas || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Rented?</span>
                    </div>
                    <select
                      name="rented"
                      value={propertyData["unit_details"][index].rented || ""}
                      className="select select-bordered w-full"
                      onChange={(e) => handleUnitChange(e, index)}
                    >
                      <option default></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>
                  {propertyData["unit_details"][index].rented === "Yes" && (
                    <label className="form-control w-full col-span-1">
                      <div className="label">
                        <span className={`label-text`}>Rent</span>
                      </div>
                      <input
                        type="text"
                        className="input input-bordered w-full "
                        name="rent"
                        value={propertyData["unit_details"][index].rent || ""}
                        onChange={(e) => handleUnitChange(e, index)}
                      />
                    </label>
                  )}
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Living Sq Ft</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="living_sqft"
                      value={
                        propertyData["unit_details"][index].living_sqft || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text">Yearly Property Management</span>
                </div>
                <select
                  name="property_management"
                  value={propertyData["property_management"]}
                  className="select select-bordered w-full"
                  onChange={handleChange}
                >
                  <option default></option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </label>
              {propertyData["property_management"] === "Yes" && (
                <label className="form-control w-full ">
                  <div className="label">
                    <span className="label-text">
                      Yearly Property Management Fee
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder=""
                    name="property_management_fee"
                    value={propertyData["property_management_fee"]}
                    className="input input-bordered w-full "
                    onChange={handleChange}
                  />
                </label>
              )}
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text">
                    Yearly Property Maintenance
                  </span>
                </div>
                <input
                  type="text"
                  placeholder=""
                  name="property_maintenance"
                  value={propertyData["property_maintenance"]}
                  className="input input-bordered w-full "
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text">Yearly Property Insurance</span>
                </div>
                <input
                  type="text"
                  placeholder=""
                  name="property_insurance"
                  value={propertyData["property_insurance"]}
                  className="input input-bordered w-full "
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        )}
      {propertyData["type"] === "Commercial" &&
        propertyData["unit_details"] &&
        propertyData["unit_details"].length > 0 && (
          <div className="space-y-5">
            <div className="flex justify-between items-center">
              <h3 className="text-19 font-medium">Unit Values</h3>
              <button
                className="btn btn-outline btn-primary btn-sm"
                onClick={() => {
                  setPropertyData((prevData) => ({
                    ...prevData,
                    unit_details: [
                      ...prevData.unit_details,
                      {
                        unit_number: null,
                        floor_number: null,
                        entire_floor: null,
                        divisible: null,
                        date_available: null,
                        lease_expiration_date: null,
                        sqft_total_available: null,
                        sqft_min_available: null,
                        sqft_floor_contiguous: null,
                        space_type: null,
                        rent_per_sqft: null,
                        rent_services: null,
                        transaction_type: null,
                        occupancy: null,
                      },
                    ],
                  }));
                  setUnitAmount(unitAmount + 1);
                }}
              >
                Add new unit
              </button>
            </div>
            <hr />
            {/* Create functionality to write to the appropriate index array object of unit_details */}
            <div className="space-y-5">
              {Array.from({ length: unitAmount }).map((_, index) => (
                <div
                  key={index}
                  className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8 ${index % 2 === 1 ? "bg-base-300" : ""} p-4 !pt-1 px-2 rounded-lg gap-3`}
                >
                  <div className="flex justify-between items-center col-span-2 md:col-span-3 lg:col-span-8">
                    <h2 className="col-span-2 md:col-span-3 lg:col-span-8 pl-1">
                      Unit {index + 1}
                    </h2>
                    {index + 1 === unitAmount && (
                      <button
                        className={`btn ${index % 2 === 1 ? "" : "btn-outline"} btn-error btn-sm mt-2`}
                        onClick={() => {
                          setPropertyData((prevData) => ({
                            ...prevData,
                            unit_details: prevData.unit_details.slice(0, -1),
                          }));
                          setUnitAmount(unitAmount - 1);
                        }}
                      >
                        Remove Unit
                      </button>
                    )}
                  </div>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Unit Number</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="unit_number"
                      value={
                        propertyData["unit_details"][index].unit_number || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>

                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Floor Number</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="floor_number"
                      value={
                        propertyData["unit_details"][index].floor_number || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Entire Floor</span>
                    </div>
                    <select
                      name="entire_floor"
                      value={
                        propertyData["unit_details"][index].entire_floor || ""
                      }
                      className="select select-bordered w-full"
                      onChange={(e) => handleUnitChange(e, index)}
                    >
                      <option default></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Divisible</span>
                    </div>
                    <select
                      name="divisible"
                      value={
                        propertyData["unit_details"][index].divisible || ""
                      }
                      className="select select-bordered w-full"
                      onChange={(e) => handleUnitChange(e, index)}
                    >
                      <option default></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Date Available</span>
                    </div>
                    <input
                      type="date"
                      className="input input-bordered w-full "
                      name="date_available"
                      value={
                        propertyData["unit_details"][index].date_available || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>
                        Lease Expiration Date
                      </span>
                    </div>
                    <input
                      type="date"
                      className="input input-bordered w-full "
                      name="lease_expiration_date"
                      value={
                        propertyData["unit_details"][index]
                          .lease_expiration_date || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>
                        Sq Ft Total Available
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="sqft_total_available"
                      value={
                        propertyData["unit_details"][index]
                          .sqft_total_available || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Sq Ft Min Available</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="sqft_min_available"
                      value={
                        propertyData["unit_details"][index]
                          .sqft_min_available || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>
                        Sq Ft Floor Contiguous
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="sqft_floor_contiguous"
                      value={
                        propertyData["unit_details"][index]
                          .sqft_floor_contiguous || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Rent Per Sq Ft</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="rent_per_sqft"
                      value={
                        propertyData["unit_details"][index].rent_per_sqft || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Rent Services</span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="rent_services"
                      value={
                        propertyData["unit_details"][index].rent_services || ""
                      }
                      onChange={(e) => handleUnitChange(e, index)}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Transaction Type</span>
                    </div>
                    <select
                      name="transaction_type"
                      value={
                        propertyData["unit_details"][index].transaction_type ||
                        ""
                      }
                      className="select select-bordered w-full"
                      onChange={(e) => handleUnitChange(e, index)}
                    >
                      <option default></option>
                      <option>Direct</option>
                      <option>Sub-let</option>
                    </select>
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span className={`label-text`}>Occupancy</span>
                    </div>
                    <select
                      name="occupancy"
                      value={
                        propertyData["unit_details"][index].occupancy || ""
                      }
                      className="select select-bordered w-full"
                      onChange={(e) => handleUnitChange(e, index)}
                    >
                      <option default></option>
                      <option>Occupied</option>
                      <option>Vacant</option>
                    </select>
                  </label>
                  <div className="col-span-1 space-y-5">
                    <h3 className="text-19 font-medium">Space Type</h3>
                    <hr />
                    <div>
                      {spaceType.map((feature, featureIndex) => (
                        <label
                          key={featureIndex}
                          className="label cursor-pointer !justify-start w-fit !gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={
                              propertyData["unit_details"][index].space_type &&
                              propertyData["unit_details"][
                                index
                              ].space_type.includes(feature)
                            }
                            className={`checkbox ${propertyData["unit_details"][index].space_type && propertyData["unit_details"][index].space_type.includes(feature) ? "checkbox-primary" : ""}`}
                            name={feature}
                            onChange={(e) => handleChangeCheck(e, index)}
                          />

                          <span className="label-text">{feature}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default Unit;
