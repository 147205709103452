import { HOST } from "../constants/HostConstants";
export const userDelete = async (user_id, email_address, { setPending }) => {
  const response = await fetch(`${HOST}/api/user/userDelete`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      email_address,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Failed to delete user");
  } else {
    setPending(response.results);
  }
  return response;
};

