import { HOST } from "../../constants/HostConstants";

export const properties = async (
  filter,
  page,
  limit,
  category,
  { setResults },
) => {
  try {
    const response = await fetch(`${HOST}/api/property/propertyDiscover`, {
      method: "POST",
      body: JSON.stringify({
        filter,
        page,
        limit,
        category,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch properties");
    }

    console.log(response);
    const data = await response.json();
    console.log(data.properties);
    setResults(data.properties);
    return data.properties; // Return the fetched properties
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
