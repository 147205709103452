import { HOST } from "../../constants/HostConstants";
export const promotedProperties = async ({ setPromoted }) => {
  fetch(`${HOST}/api/property/promotedProperties`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setPromoted(data);
    });
};
