import { HOST } from "../constants/HostConstants";
export const transactionBuy = async (
  transaction_id,
  user_id,
  email_address,
  amount,
  { setPending },
) => {
  const response = await fetch(`${HOST}/api/transaction/transactionBuy`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      transaction_id,
      user_id,
      email_address,
      amount,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  if (!response.success) {
    throw new Error("Failed to accept transaction");
  } else {
    setPending(response.results);
  }
  return response;
};
