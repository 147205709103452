import { HOST } from "../../constants/HostConstants";

export const profileAdvertisements = async ({ setPromoted }) => {
  const response = await fetch(
    `${HOST}/api/advertisement/getProfileAdvertisements`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  const data = response;

  if (!data.success) {
    setPromoted(false);
  }
  if (data.users.length > 0) {
    setPromoted(data.users);
  } else {
    setPromoted(false);
  }
};
