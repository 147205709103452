import { HOST } from "../constants/HostConstants";
export const propertyById = async ({ setProperties }, id) => {
  const response = await fetch(`${HOST}/api/property/propertyById`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      id: id,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    setProperties(false);
  } else {
    console.log(response.property);
    setProperties(response.property);
  }
};
