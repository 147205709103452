import { HOST } from "../../constants/HostConstants";

export const transactionByUser = async (user_id, { setTransactions }) => {
  const response = await fetch(`${HOST}/api/transaction/transactionByUser`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  const data = response;
  if (data.success) {
    setTransactions(data.transactions);
  }
};
