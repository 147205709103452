import { HOST } from "../../constants/HostConstants";
export const soldLeasedProperties = async ({ setSoldLeased }) => {
  fetch(`${HOST}/api/property/soldLeasedRecent`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setSoldLeased(data);
    });
};
