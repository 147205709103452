import { addCommasToPrice } from "../../helpers/conversions";
import { roundToTwoDecimals } from "../../helpers/math";

const Closing = ({ properties }) => {
  const isSoldOrLeased =
    properties?.property_status === "Sold" ||
    properties?.property_status === "Leased";
  const isVacantLand = properties?.vacant_land === "Yes";
  const isNotVacantLand = properties?.vacant_land === "No";
  return (
    <>
      {(isSoldOrLeased || isVacantLand || isNotVacantLand) && (
        <div className="">
          {properties?.property_status === "Sold" &&
            properties?.vacant_land === "No" && (
              <div className="bg-base-200/10 shadow-md space-y-5 shadow-black/5 border border-neutral-content rounded-lg p-6">
                <h5 className="font-medium">Closing Information</h5>
                <div className="grid grid-cols-1  gap-5">
                  <div className="flex flex-wrap gap-2 items-center col-span-1">
                    <h4 className="text-sm">Sold Price - </h4>
                    <p>
                      {properties?.close_price
                        ? `$${addCommasToPrice(properties?.close_price)}`
                        : "?"}
                    </p>
                    <p className="mb-[4px] text-xs">
                      {properties?.vacant_land === "No" &&
                      properties?.type === "Residential"
                        ? `($${roundToTwoDecimals(properties?.close_price, properties?.living_sqft, properties?.unit_1_living_sq_ft, properties?.unit_2_living_sq_ft, properties?.unit_3_living_sq_ft, properties?.unit_4_living_sq_ft)} / Living Sq Ft)`
                        : ""}
                    </p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Closing Date -</h4>
                    <p>{properties?.close_date || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Pending Date -</h4>
                    <p>{properties?.pending_date || "No"}</p>
                  </div>
                  <div className="flex gap-2 flex-wrap">
                    <h4 className="text-sm">Buyer CC / Concessions -</h4>
                    <p>{properties?.buyer_cc_concessions || "No"}</p>
                  </div>
                  {properties?.buyer_cc_concessions === "Yes" && (
                    <div className="flex gap-2 col-span-1 flex-wrap">
                      <h4 className="text-sm">Buyer CC / Concessions -</h4>
                      <p>{properties?.cc_concessions_amount || "No"}</p>
                    </div>
                  )}
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Financing -</h4>
                    <p>{properties?.financing || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non-Allowables -</h4>
                    <p>{properties?.non_allowables || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non Realty Items -</h4>
                    <p>{properties?.non_realty_items || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Repairs -</h4>
                    <p>{properties?.sold_repairs || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non Conveyances -</h4>
                    <p>{properties?.sold_non_conveyances || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Selling Individual -</h4>
                    <p>{properties?.selling_individual || "No"}</p>
                  </div>
                </div>
              </div>
            )}
          {properties?.property_status === "Leased" &&
            properties?.vacant_land === "No" && (
              <div className="bg-base-200/10 shadow-md space-y-5 shadow-black/5 border border-neutral-content rounded-lg p-6">
                <h3 className="font-medium">Closing Information</h3>
                <div className="grid grid-cols-1 gap-5">
                  <div className="flex gap-2 items-center col-span-1 flex-wrap">
                    <h4 className="text-sm">Leased Price - </h4>
                    <p>
                      {properties?.close_price
                        ? `$${addCommasToPrice(properties?.close_price)}`
                        : "?"}
                    </p>
                    <p className="mb-[4px] text-xs">
                      {properties?.vacant_land === "No" &&
                      properties?.type === "Residential"
                        ? `($${roundToTwoDecimals(properties?.close_price, properties?.living_sqft, properties?.unit_1_living_sq_ft, properties?.unit_2_living_sq_ft, properties?.unit_3_living_sq_ft, properties?.unit_4_living_sq_ft)} / Living Sq Ft)`
                        : ""}
                    </p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Closing Date -</h4>
                    <p>{properties?.close_date || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non-Allowables -</h4>
                    <p>{properties?.non_allowables || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non Realty Items -</h4>
                    <p>{properties?.non_realty_items || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Repairs -</h4>
                    <p>{properties?.sold_repairs || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Non Conveyances -</h4>
                    <p>{properties?.sold_non_conveyances || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Selling Individual -</h4>
                    <p>{properties?.selling_individual || "No"}</p>
                  </div>
                </div>
              </div>
            )}

          {properties?.vacant_land === "Yes" &&
            (properties?.property_status === "Leased" ||
              properties?.property_status === "Sold") && (
              <div className="bg-base-200/10 shadow-md space-y-5 shadow-black/5 border border-neutral-content rounded-lg p-6">
                <h3 className="font-medium">Closing Information</h3>
                <div className="grid grid-cols-1 gap-5">
                  <div className="flex gap-2 items-center col-span-1 flex-wrap">
                    <h4 className="text-sm">Sold Price - </h4>
                    <p>
                      {properties?.close_price
                        ? `$${addCommasToPrice(properties?.close_price)}`
                        : "?"}
                    </p>
                    <p className="mb-[4px] text-xs">
                      {properties?.vacant_land === "No"
                        ? `($${roundToTwoDecimals(properties?.close_price, properties?.living_sqft, properties?.unit_1_living_sq_ft, properties?.unit_2_living_sq_ft, properties?.unit_3_living_sq_ft, properties?.unit_4_living_sq_ft)} / Living Sq Ft)`
                        : ""}
                    </p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Closing Date -</h4>
                    <p>{properties?.close_date || "No"}</p>
                  </div>
                  <div className="flex gap-2 col-span-1 flex-wrap">
                    <h4 className="text-sm">Selling Individual -</h4>
                    <p>{properties?.selling_individual || "No"}</p>
                  </div>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default Closing;
