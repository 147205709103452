import { useEffect, useState } from "react";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { verifyEmail } from "../actions/transactions/user/verifyEmail";
import { BadgeCheck, BadgeX } from "lucide-react";
const Verify = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || null;
  const nav = useNavigate();
  const [verified, setVerified] = useState(null);
  const [alreadyVerified, setAlreadyVerified] = useState(null);
  const [profession, setProfession] = useState(null);

  const [countdown, setCountdown] = useState(3);
  const [ran, setRan] = useState(false);

  // Initial navigation check
  if (!token) {
    nav("/");
  }

  // Effect to verify token
  useEffect(() => {
    const verify = async () => {
      console.log(ran);
      try {
        if (token && !ran) {
          console.log("in verify");
          await verifyEmail(
            token,
            { setVerified },
            { setAlreadyVerified },
            { setProfession },
          );
          setRan(true); // Mark verification as ran
        }
      } catch (error) {
        console.error("Verification error:", error);
      }
    };

    verify();
  }, [token, ran]); // Run when token or ran changes

  useEffect(() => {
    let timer;

    if (verified) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [verified]);

  useEffect(() => {
    if (countdown === 0) {
      // Navigate to homepage when countdown reaches 0
      nav("/");
    }
  }, [countdown]);

  return (
    <>
      {token && (
        <div className="mx-auto tablet:px-0 px-8  flex justify-center my-[128px] overflow-x-hidden">
          <div className="max-w-[861px] w-fit space-y-4">
            <div className="flex gap-2 items-center badge badge-ghost !p-4">
              <img
                src={"/LlaveIcon_deliverable.png"}
                className="w-[15px] h-[15px]"
                alt="logo"
              />
              <span className="text-sm">MyLlave</span>
            </div>{" "}
            {verified === null && (
              <div className="space-y-1">
                <h1 className="text-lg font-medium">Verifying Email</h1>
                <div className="flex gap-2">
                  <p>Your email verification request is being processed</p>
                  <span className="loading loading-dots loading-sm"></span>
                </div>
              </div>
            )}
            {verified && verified !== null && !alreadyVerified && (
              <div className="space-y-1">
                <h1 className="text-lg font-medium flex gap-2">
                  <BadgeCheck className="text-primary" />
                  Email Verified
                </h1>
                <div className="flex gap-2">
                  <p>
                    You will be redirected to MyLlave's homepage in {countdown}s
                  </p>
                </div>
                {profession && (
                  <div className="space-y-1 text-[14px] pt-4">
                    <p>
                      Your account is now awaiting approval from administrator
                    </p>
                    <p className="text-[12px]">
                      You will be notified of your account status on action
                    </p>
                  </div>
                )}
              </div>
            )}
            {verified && alreadyVerified && (
              <div className="space-y-1">
                <h1 className="text-lg font-medium flex gap-2">
                  <BadgeCheck className="text-primary" />
                  Email Already Verified
                </h1>
                <div className="flex gap-2">
                  <p>
                    You will be redirected to MyLlave's homepage in {countdown}s
                  </p>
                </div>
              </div>
            )}
            {verified === false && (
              <div className="space-y-1">
                <h1 className="text-lg font-medium flex gap-2">
                  <BadgeX className="text-error" />
                  Email Verification Failed
                </h1>
                <div className="flex gap-2">
                  <p>
                    Your verification request likekly ran out of time, please
                    contact support
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Verify;
