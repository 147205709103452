import React, { useEffect, useState } from "react";
import { filterUsers } from "../actions/filterUsers";
import { Filter, Search } from "lucide-react";
import ProfessionalPreview from "../components/ProfessionalPreview";
import Footer from "../components/Footer";

const Professionals = () => {
  const [all, setAll] = useState(null);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    filterUsers(filter, setAll);
  }, [filter]);

  return (
    <>
      <div className="mx-auto my-12 space-y-5 max-w-[1440px] px-[24px]">
        <h3 className="text-3xl font-medium text-center">
          Discover Professionals
        </h3>
        <div className="max-w-[943px] mx-auto space-y-3 ">
          <p className="text-lg">Who are you looking for?</p>
          <div className="flex sm:flex-row flex-col flex-wrap justify-between gap-5">
            <div className="flex">
              <div className="dropdown dropdown-right block md:hidden">
                <div tabIndex={0} role="button" className="btn">
                  <button className="block md:hidden">
                    <Filter />
                  </button>
                </div>
                <ul
                  tabIndex={0}
                  className="mt-3 z-[1] p-2 space-y-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
                >
                  <li
                    className={`btn btn-sm ${filter === null ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter(null);
                      setAll(null);
                    }}
                  >
                    All
                  </li>
                  <li
                    className={`btn btn-sm ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Broker / Owner");
                      setAll(null);
                    }}
                  >
                    Broker / Owner
                  </li>
                  <button
                    className={`btn btn-sm ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Broker / Associate");
                      setAll(null);
                    }}
                  >
                    Broker / Associate
                  </button>

                  <button
                    className={`btn btn-sm ${filter === "REALTOR" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("REALTOR");
                      setAll(null);
                    }}
                  >
                    REALTOR
                  </button>
                  <button
                    className={`btn btn-sm ${filter === "Builder" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Builder");
                      setAll(null);
                    }}
                  >
                    Builder
                  </button>
                  <button
                    className={`btn btn-sm ${filter === "Lender" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Lender");
                      setAll(null);
                    }}
                  >
                    Lender
                  </button>
                  <button
                    className={`btn btn-sm ${filter === "Title Closer" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Title Closer");
                      setAll(null);
                    }}
                  >
                    Escrow Officer
                  </button>
                  <button
                    className={`btn btn-sm ${filter === "Investor" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Investor");
                      setAll(null);
                    }}
                  >
                    Investor
                  </button>
                  <button
                    className={`btn btn-sm ${filter === "Advertiser" ? "btn-primary" : "hover:!btn-primary"}`}
                    onClick={() => {
                      setFilter("Advertiser");
                      setAll(null);
                    }}
                  >
                    Advertiser
                  </button>
                </ul>
              </div>
              <div className="hidden gap-5 flex-wrap md:flex">
                <button
                  className={`btn btn-xs ${filter === null ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter(null);
                    setAll(null);
                  }}
                >
                  All
                </button>
                <button
                  className={`btn btn-xs ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Broker / Owner");
                    setAll(null);
                  }}
                >
                  Broker / Owner
                </button>
                <button
                  className={`btn btn-xs ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Broker / Associate");
                    setAll(null);
                  }}
                >
                  Broker / Associate
                </button>

                <button
                  className={`btn btn-xs ${filter === "REALTOR" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("REALTOR");
                    setAll(null);
                  }}
                >
                  REALTOR
                </button>
                <button
                  className={`btn btn-xs ${filter === "Builder" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Builder");
                    setAll(null);
                  }}
                >
                  Builder
                </button>
                <button
                  className={`btn btn-xs ${filter === "Lender" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Lender");
                    setAll(null);
                  }}
                >
                  Lender
                </button>
                <button
                  className={`btn btn-xs ${filter === "Title Closer" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Title Closer");
                    setAll(null);
                  }}
                >
                  Escrow Officer
                </button>
                <button
                  className={`btn btn-xs ${filter === "Investor" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Investor");
                    setAll(null);
                  }}
                >
                  Investor
                </button>
                <button
                  className={`btn btn-xs ${filter === "Advertiser" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => {
                    setFilter("Advertiser");
                    setAll(null);
                  }}
                >
                  Advertiser
                </button>
              </div>
            </div>
            <div className="flex flex-[1] gap-5">
              <label className="form-control w-full flex-[1]">
                <input
                  type="text"
                  placeholder="Search by name (coming soon)"
                  className="input input-bordered w-full"
                />
              </label>
              <button className="btn btn-primary" disabled>
                <Search /> Search
              </button>
            </div>
          </div>
        </div>
        <div className="justify-between flex pt-5">
          <p>
            Displaying {filter ? filter?.toLowerCase() : "all"} professionals
          </p>
          {/* <label className="form-control w-fit"> */}
          {/*   <select className="select select-bordered"> */}
          {/*     <option disabled selected> */}
          {/*       Sort By */}
          {/*     </option> */}
          {/*     <option>Price</option> */}
          {/*     <option>Days On Market</option> */}
          {/*     <option>Acreage</option> */}
          {/*   </select> */}
          {/* </label> */}
        </div>
        {!all ? (
          <div className="w-full min-h-screen">
            <div className="flex justify-center items-center gap-4">
              <h3>Looking for professionals</h3>
              <span className="loading loading-dots text-neutral loading-md"></span>
            </div>
          </div>
        ) : all.length > 0 ? (
          <div className="min-h-screen">
            <div className="mx-auto  gap-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
              {all &&
                all.map((user, index) => (
                  <ProfessionalPreview
                    key={index}
                    professional={user}
                    full={false}
                  />
                ))}
            </div>
          </div>
        ) : (
          <div className="min-h-screen text-center text-gray-500">
            No {filter.toLowerCase()} Professonals Found
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Professionals;
