import React, { useEffect, useState } from "react";

import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";

export default function PieChart({ dataValues }) {
  if (!dataValues) return;
  let alteredArary = [
    dataValues[0],
    dataValues[1],
    dataValues[2].highest_balance,
    dataValues[3],
  ];

  const data = {
    labels: [
      "Key Holding",
      "Operating",
      `${dataValues[2].first} ${dataValues[2].last}`,
      "Other Accounts",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: alteredArary,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} options={{ maintainAspectRatio: false }} />;
}
