const Features = ({ properties }) => {
  return (
    <div className="p-4">
      {properties?.for === "Sale" &&
        properties?.vacant_land === "No" &&
        properties?.type !== "Commercial" && (
          <div className="grid grid-cols-2  gap-5">
            {properties?.communityAmenities &&
              properties?.communityAmenities.length > 0 && (
                <div className="space-y-2 col-span-1">
                  <h3 className="text-14 font-medium">Community Amenities</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.communityAmenities.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>
                </div>
              )}
            {properties?.energyFeatures &&
              properties?.energyFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Energy Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.energyFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.laundry && properties?.laundry.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Laundry</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.laundry.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.exteriorFeatures &&
              properties?.exteriorFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Exterior Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.exteriorFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.exteriorSiding &&
              properties?.exteriorSiding.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Exterior Siding</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.exteriorSiding.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.fenceType && properties?.fenceType.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Fence Type</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.fenceType.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.flooringType &&
              properties?.flooringType.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Flooring Type</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.flooringType.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.garageCarport &&
              properties?.garageCarport.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Garage Carport</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.garageCarport.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.hvac && properties?.hvac.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">HVAC</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.hvac.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.interiorFeatures &&
              properties?.interiorFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Interior Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.interiorFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.locationFeatures &&
              properties?.locationFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Location Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.locationFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.roof && properties?.roof.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Roof</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.roof.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.streetSurface &&
              properties?.streetSurface.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Street Surface</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.streetSurface.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.specialFeatures &&
              properties?.specialFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Special Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.specialFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.waterHeater && properties?.waterHeater.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Water Heater</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.waterHeater.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.waterSewer && properties?.waterSewer.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Water Sewer</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.waterSewer.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.conveyingAppliances &&
              properties?.conveyingAppliances.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Conveying Appliances</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.conveyingAppliances.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.countertops && properties?.countertops.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Countertops</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.countertops.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.foundationType &&
              properties?.foundationType.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Foundation Type</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.foundationType.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.masterSuite && properties?.masterSuite.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Master Suite</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.masterSuite.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.poolDetails && properties?.poolDetails.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Pool Details</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.poolDetails.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.windowCoverings &&
              properties?.windowCoverings.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Window Coverings</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.windowCoverings.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.docsOnFile && properties?.docsOnFile.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Docs On File</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.docsOnFile.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          </div>
        )}
      {properties?.for === "Lease" &&
        properties?.vacant_land === "No" &&
        properties?.type !== "Commercial" && (
          <div className="grid grid-cols-2  gap-5">
            {properties?.communityAmenities &&
              properties?.communityAmenities.length > 0 && (
                <div className="space-y-2 col-span-1">
                  <h3 className="text-14 font-medium">Community Amenities</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.communityAmenities.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.laundry && properties?.laundry.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Laundry</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.laundry.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.exteriorFeatures &&
              properties?.exteriorFeatures.length > 0 && (
                <div className="space-y-2 col-span-1">
                  <h3 className="text-14 font-medium">Exterior Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.exteriorFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.garageCarport &&
              properties?.garageCarport.length > 0 && (
                <div className="space-y-2 col-span-1">
                  <h3 className="text-14 font-medium">Garage / Carport</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.garageCarport.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.hvac && properties?.hvac.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">HVAC</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.hvac.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.interiorFeatures &&
              properties?.interiorFeatures.length > 0 && (
                <div className="space-y-2 col-span-1">
                  <h3 className="text-14 font-medium">Interior Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.interiorFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.masterSuite && properties?.masterSuite.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Master Suite</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.masterSuite.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.poolDetails && properties?.poolDetails.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Pool Details</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.poolDetails.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.docsOnFile && properties?.docsOnFile.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Docs On File</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.docsOnFile.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          </div>
        )}
      {properties?.vacant_land === "Yes" && (
        <div className="grid grid-cols-2  gap-5">
          {properties?.landFeatures && properties?.landFeatures.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Land Features</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.landFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.communityAmenities &&
            properties?.communityAmenities.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Community Amenities</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.communityAmenities.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          {properties?.fenceType && properties?.fenceType.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Fence Type</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.fenceType.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.locationFeatures &&
            properties?.locationFeatures.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Location Features</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.locationFeatures.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          {properties?.streetSurface &&
            properties?.streetSurface.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Street Surface</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.streetSurface.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          {properties?.waterSewer && properties?.waterSewer.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Water Sewer</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.waterSewer.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.access && properties?.access.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Access</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.access.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.docsOnFile && properties?.docsOnFile.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Docs On File</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.docsOnFile.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.crops && properties?.crops.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Crops</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.crops.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.improvements && properties?.improvements.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Improvements</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.improvements.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.suitableUse && properties?.suitableUse.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Suitable use</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.suitableUse.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
          {properties?.topographyDescription &&
            properties?.topographyDescription.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Topography Description</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.topographyDescription.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          {properties?.utilities && properties?.utilities.length > 0 && (
            <div className="space-y-2 col-span-1">
              <h3 className="text-14 font-medium">Utilities</h3>
              <hr />
              <ul className="text-sm list-disc pl-4">
                {properties?.utilities.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>{" "}
            </div>
          )}
        </div>
      )}
      {properties?.for === "Sale" &&
        properties?.vacant_land === "No" &&
        properties?.type === "Commercial" && (
          <div className="grid grid-cols-2  gap-5">
            {properties?.energyFeatures &&
              properties?.energyFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Energy Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.energyFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}

            {properties?.exteriorFeatures &&
              properties?.exteriorFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Exterior Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.exteriorFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}

            {properties?.exteriorSiding &&
              properties?.exteriorSiding.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Exterior Siding</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.exteriorSiding.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.hvac && properties?.hvac.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">HVAC</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.hvac.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.roof && properties?.roof.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Roof</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.roof.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.waterHeater && properties?.waterHeater.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Water Heater</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.waterHeater.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}

            {properties?.access && properties?.access.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Access</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.access.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.amenities && properties?.amenities.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Amenities</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.amenities.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.docsOnFile && properties?.docsOnFile.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Docs On File</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.docsOnFile.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}

            {properties?.grounds && properties?.grounds.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Grounds</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.grounds.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.location && properties?.location.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Location</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.location.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.parking && properties?.parking.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Parking</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.parking.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}

            {properties?.siteFeatures &&
              properties?.siteFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Site Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.siteFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.presentUse && properties?.presentUse.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Present Use</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.presentUse.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          </div>
        )}

      {properties?.for === "Lease" &&
        properties?.vacant_land === "No" &&
        properties?.type === "Commercial" && (
          <div className="grid grid-cols-2  gap-5">
            {properties?.access && properties?.access.length > 0 && (
              <div className="space-y-2 col-span-1">
                <h3 className="text-14 font-medium">Access</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.access.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.docsOnFile && properties?.docsOnFile.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Docs On File</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.access.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.parking && properties?.parking.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Parking</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.parking.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
            {properties?.siteFeatures &&
              properties?.siteFeatures.length > 0 && (
                <div className="space-y-2 col-span-1 mt-0">
                  <h3 className="text-14 font-medium">Site Features</h3>
                  <hr />
                  <ul className="text-sm list-disc pl-4">
                    {properties?.siteFeatures.map((feature) => (
                      <li>{feature}</li>
                    ))}
                  </ul>{" "}
                </div>
              )}
            {properties?.presentUse && properties?.presentUse.length > 0 && (
              <div className="space-y-2 col-span-1 mt-0">
                <h3 className="text-14 font-medium">Present Use</h3>
                <hr />
                <ul className="text-sm list-disc pl-4">
                  {properties?.siteFeatures.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </ul>{" "}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default Features;
