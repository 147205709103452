import { useState } from "react";
import { MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  addCommasToPrice,
  extractStateAbbreviation,
} from "../../helpers/conversions";
import { KeyRound } from "lucide-react";
import {
  addBathrooms,
  addBedrooms,
  addCommercialSqFt,
  addLivingSqFt,
} from "../../helpers/math";

const UserTypeDisplay = ({ user_data }) => {
  if (user_data.class_type === "Consumer") {
    return null; // If user is a consumer, don't render anything
  }

  let content = null;

  switch (user_data.class_type) {
    case "REALTOR":
      content = (
        <div className="flex md:flex-row flex-col">
          <div className="space-x-1 flex">
            <p>{user_data.class_type} at </p>
            <a href="#" className="link link-hover link-primary ml-1">
              {user_data.brokerage_name}
            </a>
          </div>
        </div>
      );
      break;
    case "Broker / Owner":
    case "Broker / Associate":
      content = (
        <div className="flex md:flex-row flex-col">
          <div className="space-x-1 flex">
            <p>{user_data.class_type} of </p>
            <a href="#" className="link link-hover link-primary ml-1">
              {user_data.brokerage_name}
            </a>
          </div>
        </div>
      );
      break;
    case "Builder":
      content = (
        <div className="flex flex-wrap">
          <div className="space-x-1 flex">
            <p>{user_data.position} at </p>
            <a href="#" className="link link-hover link-primary ml-1">
              {user_data.builder_company_name}
            </a>
          </div>
        </div>
      );
      break;
    case "Lender":
      content = (
        <div className="flex md:flex-row flex-col">
          <div className="space-x-1 flex">
            <p>{user_data.class_type} at </p>
            <a href="#" className="link link-primary link-hover ml-1">
              {user_data.lending_company_name}
            </a>
          </div>
        </div>
      );
      break;
    case "Investor":
      content = (
        <div className="flex md:flex-row flex-col">
          <div className="space-x-1 flex">
            <p>{user_data.position} at </p>
            <a href="#" className="link link-primary link-hover ml-1">
              {user_data.entity_name}
            </a>
          </div>
        </div>
      );
      break;
    default:
      content = null;
      break;
  }

  return <div className="text-[10px]">{content}</div>;
};

const Preview = ({ property, user_data_embeded_further }) => {
  const [loaded, setLoaded] = useState(false);
  const user_data = user_data_embeded_further
    ? property.user_data[0]
    : property.user_data;

  const nav = useNavigate();

  if (!property) return;
  return (
    <div
      className="bg-white  gap-5 relative transition-all h-full  cursor-pointer border border-transparent rounded-lg ease-linear "
      onClick={() => nav(`/property/${property?.property_id}`)}
    >
      {/* Image */}
      {property?.media_url && (
        <img
          className="w-full h-[122px] md:h-[162px] relative object-cover select-none rounded-t-lg"
          draggable={false}
          src={`https://res.cloudinary.com/diur0igkv/image/upload/${property?.media_url.split(",")[0]}`}
          alt="Main Property"
          onLoad={() => setLoaded(true)}
        />
      )}

      <div className="absolute top-2 left-2 flex gap-2 text-[12px]">
        <div
          className={`p-2 text-center text-white  mb-2 rounded-[5px] text-[12px] ${
            property.property_status === "Active"
              ? "bg-green-500"
              : property?.property_status === "Sold" ||
                  property?.property_status === "Leased"
                ? "bg-error"
                : property?.property_status === "Option" ||
                    property?.property_status === "Pending"
                  ? "bg-orange-500"
                  : property?.property_status === "Withdrawn" ||
                      property?.property_status === "Expired"
                    ? "bg-purple-400"
                    : ""
          }`}
        >
          {property.property_status}
        </div>

        <div className="">
          {property?.for === "Sale" ? (
            <h1 className="font-medium bg-error p-2 w-fit mb-2 text-white rounded-[5px]">
              For {property?.for}
            </h1>
          ) : (
            ""
          )}
          {property?.for === "Lease" ? (
            <h1 className="font-medium bg-error p-2 w-fit mb-2 text-white rounded-[5px]">
              For {property?.for}
            </h1>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex flex-col justify-between border border-base-300 p-2">
        <div className="space-y-1 flex flex-col justify-between min-h-[159px]">
          <div>
            {/* Address block*/}
            <div className="flex items-start gap-1">
              <MapPin size={16} />
              <p className="text-[13px] max-w-[351px] truncate">
                {property?.street_number} {property?.dir_prefix}{" "}
                {property?.street_name}{" "}
                {property?.street_type ? " " + property?.street_type : ""}{" "}
                {property?.unit_number
                  ? ", Unit # " + property?.unit_number
                  : ""}{" "}
                , {property?.city},{" "}
                {property?.state
                  ? extractStateAbbreviation(property?.state)
                  : ""}
                , {property?.zip}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">
                $
                {property?.list_price
                  ? addCommasToPrice(property?.list_price)
                  : null}{" "}
                {property?.for === "Lease" ? "/ Month" : ""}
              </p>
            </div>
            {/* Badges Group */}
            <div className="flex flex-wrap gap-2 pt-3">
              {property?.vacant_land === "No" &&
                (((property?.sub_type === "Duplex" ||
                  property?.sub_type === "Fourplex") &&
                  property?.unit_details && (
                    <div className="badge badge-ghost badge-md !flex !gap-1">
                      <h4>{addBedrooms(property?.unit_details)}</h4>
                      <p>Beds</p>
                    </div>
                  )) ||
                  (property?.beds_total && (
                    <div className="badge badge-ghost badge-md !flex !gap-1">
                      <h4>{property?.beds_total}</h4>
                      <p>Beds</p>
                    </div>
                  )))}
              {property?.vacant_land === "No" &&
                (((property?.sub_type === "Duplex" ||
                  property?.sub_type === "Fourplex") &&
                  property?.unit_details && (
                    <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                      <h4 className="">
                        {addBathrooms(property?.unit_details)}
                      </h4>
                      <p className="">Baths</p>
                    </div>
                  )) ||
                  (property?.baths_full && (
                    <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                      <h4 className="">{property?.baths_full}</h4>
                      <p className="">Baths</p>
                    </div>
                  )))}
              <div className="badge badge-ghost badge-md">
                {property?.acreage || "?"} Acres
              </div>
              {property?.vacant_land === "No" &&
                (((property?.sub_type === "Duplex" ||
                  property?.sub_type === "Fourplex") &&
                  property?.unit_details && (
                    <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                      <h4 className="">
                        {addCommasToPrice(
                          addLivingSqFt(property?.unit_details),
                        )}
                      </h4>
                      <p className="">Living Sq Ft</p>
                    </div>
                  )) ||
                  (property?.living_sqft && (
                    <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                      <h4 className="">
                        {addCommasToPrice(property?.living_sqft)}
                      </h4>
                      <p className="">Living Sq Ft</p>
                    </div>
                  )))}{" "}
              {property?.vacant_land === "No" &&
                property?.type === "Commercial" && (
                  <>
                    {(property?.office_sqft ||
                      property?.other_sqft ||
                      property?.other_bldg_sqft ||
                      property?.retail_sqft ||
                      property?.warehouse_sqft) && (
                      <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                        <h4 className="">
                          {addCommercialSqFt(
                            property?.office_sqft,
                            property?.other_sqft,
                            property?.other_bldg_sqft,
                            property?.retail_sqft,
                            property?.warehouse_sqft,
                          )}
                        </h4>
                        <p className="">Total Sq Ft</p>
                      </div>
                    )}
                    {property?.unit_details && (
                      <div className="!flex badge badge-ghost badge-md !gap-1 !items-end">
                        <h4 className="">#{property?.unit_details.length}</h4>
                        <p className="">Units</p>
                      </div>
                    )}
                  </>
                )}
              {property?.vacant_land === "No" &&
                property?.type === "Residential" &&
                property?.for === "Lease" &&
                property?.pets_allowed === "Yes" && (
                  <div className="badge badge-ghost badge-md !flex !gap-1">
                    <p>Pets Allowed</p>
                  </div>
                )}
              {property?.vacant_land === "No" &&
                property?.type === "Residential" &&
                property?.proposedFinancing &&
                property?.proposedFinancing.includes("Owner Financing") && (
                  <div className="badge badge-ghost badge-md !flex !gap-1">
                    <p>Owner Financing</p>
                  </div>
                )}
            </div>
          </div>
          <div className="flex h-full items-end justify-between">
            <div className="flex gap-2 items-center pt-2">
              <div className="w-[34px] h-[34px] relative">
                <img
                  className="rounded-full absolute object-cover h-full"
                  src={
                    user_data?.picture_url
                      ? `https://res.cloudinary.com/diur0igkv/image/upload/${user_data?.picture_url}`
                      : "/error.png"
                  }
                  alt="property poster"
                />
              </div>

              <div className="">
                <h2 className="text-[14px]">
                  {user_data.legal_first_name} {user_data.legal_last_name}
                </h2>
                <div className="text-[10px]">
                  <UserTypeDisplay user_data={user_data} />
                </div>
              </div>
            </div>
            {loaded && (
              <div className="flex items-center gap-1 text-sm">
                <KeyRound size={16} />
                {property?.property_id}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
