import { HOST } from "../../constants/HostConstants";
export const advertisementByUser = async ({ setAdvertisements }, user_id) => {
  console.log(user_id);
  const response = await fetch(
    `${HOST}/api/advertisement/advertisementByUser`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        user_id: user_id,
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    });

  if (!response.success) {
    return null;
  } else {
    setAdvertisements(response.advertisements);
    return response.advertisements;
  }
};
