import { HOST } from "../constants/HostConstants";
export const advertiseGrab = async (direction, setAdvertisement) => {
  fetch(`${HOST}/api/advertisement/advertisementGrab`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      direction,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return setAdvertisement(data);
    });
};

