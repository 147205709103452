import Nav from "../components/Nav";
import { useEffect, useState } from "react";

const Welcome = () => {
  const [email, setEmail] = useState(localStorage.getItem("emailForRegister"));
  useEffect(() => {
    if (!email) {
      let tempEmail = prompt("Enter email address requesting verification");
      setEmail(tempEmail);
      // Verify that the email sent for "verification" is already created i.e. waiting for verification
    } else {
      localStorage.removeItem("emailForRegister");
    }
  }, []);
  return (
    <>
      {email && (
        <div className="mx-auto tablet:px-0 px-8  flex justify-center my-[128px] overflow-x-hidden">
          <div className="max-w-[861px] w-fit space-y-4">
            <div className="flex gap-2 items-center badge badge-ghost !p-4">
              <img
                src={"/LlaveIcon_deliverable.png"}
                className="w-[15px] h-[15px]"
                alt="logo"
              />
              <span className="text-sm">MyLlave</span>
            </div>{" "}
            <div className="space-y-1">
              <h1 className="text-lg font-medium">Verification Email Sent!</h1>
              <p>
                We have sent a confirmation link to{" "}
                <span className="font-semibold">{email}</span>. Check your email
                for a link to verify your email address.
              </p>
            </div>
            <div className="space-y-1 text-[14px] mt-4">
              <p>Didn&apos;t get a confirmation email?</p>
              <p className="text-[12px]">
                Check your spam folder or{" "}
                <button className="link link-primary no-underline">
                  resend email
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
