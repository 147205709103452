import { useEffect } from "react";
import { useStepperContext } from "../../context/MainContext";

import { PatternFormat } from "react-number-format";

const tenantPay = [
  "Cable TV",
  "Electricty",
  "Gas",
  "Internet",
  "Other",
  "POA",
  "Pool Maintenance",
  "Security",
  "Sewer",
  "Trash",
  "Water",
  "Yard Maintenance",
];

const Remarks = () => {
  const { propertyData, setPropertyData } = useStepperContext();

  console.log(propertyData);
  useEffect(() => {
    if (
      !propertyData["pets_allowed"] ||
      propertyData["pets_allowed"] === undefined ||
      propertyData["pets_allowed"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, pets_allowed: "No" }));
    }
    if (!Array.isArray(propertyData["tenantPay"])) {
      setPropertyData((prevData) => ({ ...prevData, tenantPay: [] }));
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setPropertyData({ ...propertyData, [name]: value });
  };

  const handleChangeCheckSpecial = (e, featureCategory) => {
    const { name, value } = e.target;

    if (featureCategory === "tenantPay") {
      if (
        propertyData.tenantPay.length > 0 &&
        propertyData.tenantPay.includes(name)
      ) {
        setPropertyData((prevData) => ({
          ...prevData,
          tenantPay: prevData.tenantPay.filter((item) => item !== name),
        }));
      } else {
        setPropertyData((prevData) => ({
          ...prevData,
          tenantPay: [...prevData.tenantPay, name],
        }));
      }
    }
  };

  const handleChangeCheck = (e) => {
    const { name, value } = e.target;

    if (propertyData[name] === "Yes") {
      setPropertyData({ ...propertyData, [name]: "No" });
    } else if (propertyData[name] === "No") {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    } else if (!value) {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    }
    // Typically handles the data that requires text whether than checkboxes.
    else {
      setPropertyData({ ...propertyData, [name]: value });
    }
  };

  console.log(propertyData["public_remarks"]);
  return (
    <div className="my-12 space-y-5 grid">
      <label className="form-control">
        <div className="label">
          <span className="label-text">Public Remarks</span>
        </div>
        <textarea
          className="textarea textarea-bordered h-24"
          name="public_remarks"
          value={propertyData["public_remarks"] || ""}
          onChange={handleChange}
        ></textarea>
        <div className="text-xs mt-2">
          Characters Remaining:{" "}
          {!propertyData["public_remarks"]
            ? 1524
            : 1524 - propertyData["public_remarks"].length}
        </div>
      </label>

      {propertyData["for"] === "Lease" &&
        propertyData["vacant_land"] === "No" &&
        propertyData["type"] === "Residential" && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text flex gap-1`}>
                    Application Fee
                    <p className="text-[14px] text-gray-500">(no commas)</p>
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="application_fee"
                  value={propertyData["application_fee"] || ""}
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text`}>
                    Contact Phone # Of Landlord / Property Manager
                  </span>
                </div>
                <PatternFormat
                  className="input input-bordered w-full"
                  format="+1 (###) ### ####"
                  name="contact_phone"
                  value={propertyData["contact_phone"] || ""}
                  onChange={(e) => handleChange(e)}
                  allowEmptyFormatting
                  mask="_"
                />{" "}
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text`}>
                    Landlord / Property Manager
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="landlord_property_management"
                  value={propertyData["landlord_property_management"] || ""}
                  onChange={handleChange}
                />
              </label>
              <div className="col-span-1 space-y-5">
                <h3 className="text-19 font-medium">Tenant Responsible For</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["tenantPay"]) &&
                    tenantPay.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={
                            propertyData["tenantPay"].length > 0 &&
                            propertyData["tenantPay"].includes(feature)
                          }
                          className={`checkbox ${propertyData["tenantPay"].length > 0 && propertyData["tenantPay"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheckSpecial(e, "tenantPay")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>

              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text flex gap-1`}>
                    Security Deposit{" "}
                    <p className="text-[14px] text-gray-500">(no commas)</p>
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="security_deposit"
                  value={propertyData["security_deposit"] || ""}
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text `}>Term Length</span>
                </div>
                <select
                  className="select select-bordered"
                  name="term_length"
                  value={propertyData["term_length"] || ""}
                  onChange={handleChange}
                >
                  <option disabled value="default" hidden></option>
                  <option></option>
                  <option>12 months</option>
                  <option>&lt; 12 months</option>
                  <option>Other</option>
                </select>
              </label>
            </div>
            <label className="label gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">Pets Allowed</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["pets_allowed"] === "Yes" ? "checkbox-primary" : ""}`}
                name="pets_allowed"
                checked={propertyData["pets_allowed"] === "Yes"}
                onChange={handleChangeCheck}
              />
            </label>

            {propertyData["pets_allowed"] == "Yes" && (
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Pet Fee</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="pet_fee"
                    value={propertyData["pet_fee"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text `}>Pet Fee Type</span>
                  </div>
                  <select
                    className="select select-bordered"
                    name="pet_fee_frequency"
                    value={propertyData["pet_fee_frequency"] || ""}
                    onChange={handleChange}
                  >
                    <option disabled value="default" hidden></option>
                    <option></option>
                    <option>Non-Refundable</option>
                    <option>Monthly Pet Fee</option>
                    <option>One Time Deposit</option>
                  </select>
                </label>
              </div>
            )}
          </>
        )}
      {propertyData["vacant_land"] === "No" &&
        propertyData["type"] === "Commercial" &&
        propertyData["for"] === "Lease" && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Annual Amount Sq Ft{" "}
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="annual_amount_sqft"
                value={propertyData["annual_amount_sqft"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text `}>
                  Right to Lease Exclusively
                </span>
              </div>
              <select
                className="select select-bordered"
                name="right_to_lease_exclusively"
                value={propertyData["right_to_lease_exclusively"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Base Rent Amount
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="base_rent_amount"
                value={propertyData["base_rent_amount"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>Lease Period</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="lease_period"
                value={propertyData["lease_period"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Security Deposit
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="security_deposit"
                value={propertyData["security_deposit"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Annual Insurance
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="annual_insurance"
                value={propertyData["annual_insurance"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Property Manager
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="property_manager"
                value={propertyData["property_manager"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Property Manager Concession
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="property_manager_concession"
                value={propertyData["property_manager_concession"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  Potential Lease type
                </span>
              </div>
              <select
                className="select select-bordered"
                name="potential_lease_type"
                value={propertyData["potential_lease_type"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                <option>Gross</option>
                <option>N Lease</option>
                <option>NN Lease</option>
                <option>NNN Lease</option>
                <option>NNN Lease</option>
                <option>Other</option>
              </select>
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>Availability</span>
              </div>
              <select
                className="select select-bordered"
                name="availability"
                value={propertyData["availability"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                <option>Immediate</option>
                <option>Negotiable</option>
                <option>Other</option>
                <option>Tenant Rights</option>
              </select>
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>Available Date</span>
              </div>
              <input
                type="date"
                className="input input-bordered w-full "
                name="available_date"
                value={propertyData["available_date"] || ""}
                onChange={handleChange}
              />
            </label>
          </div>
        )}
    </div>
  );
};

export default Remarks;
