import { useContext } from "react";
import { HOST } from "../constants/HostConstants";
import { AlertContext } from "../context/MainContext";

export const advertisementDelete = async (
  advertisement_id,
  user_id,
  email_address,
  cost,
  setPending,
) => {
  const response = await fetch(
    `${HOST}/api/advertisement/advertisementDelete`,
    {
      method: "POST",
      body: JSON.stringify({
        advertisement_id,
        user_id,
        email_address,
        cost,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  if (!response.success) {
    throw new Error("Failed to delete advertisement");
  } else {
    setPending(response.results);
  }
  return response;
};

