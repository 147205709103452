import { HOST } from "../../constants/HostConstants";
export const getPredefinedValues = async ({ setPropertyData }) => {
  fetch(`${HOST}/api/property/getPredefinedValues`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.property) {
        setPropertyData((prevData) => ({
          ...prevData,
          school_districts: data.property.school_districts,
          cities: data.property.cities,
          counties: data.property.counties,
        }));
      }
      return;
    });
};
