export function MoneyFormat(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      }) + "B"
    : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) + "M"
      : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }) + "K"
        : Math.abs(Number(labelValue)).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          });
}
