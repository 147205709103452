import { useContext } from "react";
import { HOST } from "../constants/HostConstants";
import { AlertContext } from "../context/MainContext";

export const advertisementRequest = async (
  user_id,
  advertisement_url,
  direction,
  email_address,
  type,
  redirect,
  redirect_type,
  property_id,
  impression_limit,
  cost,
  { setUser },
) => {
  console.log("impression limit before i send: ", impression_limit);
  const response = await fetch(
    `${HOST}/api/advertisement/advertisementRequest`,
    {
      method: "POST",
      body: JSON.stringify({
        user_id,
        advertisement_url,
        direction,
        email_address,
        type,
        redirect,
        redirect_type,
        impression_limit,
        property_id,
        cost,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Advertisement Request Not Sent");
  }

  const data = response;
  if (data.success) {
    localStorage.setItem("token", data.token);
    setUser(data.user);
    return data;
  }
};
