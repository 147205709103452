import { HOST } from "../constants/HostConstants";
export const propertyImpressions = async (id, { setProperties }) => {
  fetch(`${HOST}/api/property/impression`, {
    method: "POST",
    body: JSON.stringify({
      id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {});
};

