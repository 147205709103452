import React, { useEffect, useState } from "react";
import { userStats } from "../actions/userStats";
import Footer from "../components/Footer";
import { propertyStats } from "../actions/propertyStats";
import { advertiseGrab } from "../actions/advertiseGrab";
import { useNavigate, Link } from "react-router-dom";
import { propertiesRecent } from "../actions/propertiesRecent";
import { promotedProperties } from "../actions/home/promotedProperties";
import Preview from "../components/properties/preview";
import { hotProperties } from "../actions/home/hotProperties";
import { soldLeasedProperties } from "../actions/home/soldLeasedProperties";
import { withdrawnExpired } from "../actions/home/withdrawnExpired";
import { transactionStats } from "../actions/transaction/transactionStats";
import { ArrowRight } from "lucide-react";

import { getCities } from "../actions/home/getCities";
import { getMaxPrice } from "../actions/home/getMaxPrice";
import WebStats from "../components/WebStats";
import Section from "../components/professionals/section";
import ProfessionalSection from "../components/professionals/section";

const PropertiesByCategory = ({
  title,
  subtitle,
  redirect_category,
  embeded_further,
  properties,
}) => {
  return (
    <section className="space-y-[20px] ">
      <span className="flex justify-between items-end flex-wrap gap-5">
        <div className="">
          <h2 className="text-[24px] font-medium">{title}</h2>
          {subtitle && <p className="text-gray-500 text-[13px]">{subtitle}</p>}
        </div>
        <Link
          to={`/properties?category=${redirect_category}`}
          className="btn btn-ghost btn-sm !text-primary"
        >
          view all
        </Link>
      </span>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 h-full w-full min-h-[301px]">
        {properties &&
          properties.length > 0 &&
          properties.map((property, index) => (
            <Preview
              key={index}
              property={property}
              user_data_embeded_further={embeded_further}
            />
          ))}
      </div>
    </section>
  );
};

const Home = () => {
  const [stats, setStats] = useState(null);
  const [propStats, setPropStats] = useState(null);
  const [advertisement, setAdvertisement] = useState(null);
  const [promoted, setPromoted] = useState(null);
  const [recent, setRecent] = useState(null);
  const [hot, setHot] = useState(null);
  const [soldLeased, setSoldLeased] = useState(null);
  const [expired, setExpired] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [searchOption, setSearchOption] = useState("Buy");
  const [searchFilters, setSearchFilters] = useState({
    address: null,
    type: null,
    city: null,
    price: null,
    beds: null,
    baths: null,
  });
  const [viewCategory, setViewCategory] = useState("Just Listed");
  const [cities, setCities] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  // State to prevent api calls from fetching twice in development

  console.log(promoted);
  const nav = useNavigate();

  useEffect(() => {
    promotedProperties({ setPromoted });
    propertiesRecent({ setRecent });
    hotProperties({ setHot });
    soldLeasedProperties({ setSoldLeased });
    withdrawnExpired({ setExpired });

    getCities({ setCities });

    // Grab stats regarding users
    userStats({ setStats });

    // Grab stats regarding properties
    propertyStats({ setPropStats });

    // Grab stats regarding keys
    transactionStats({ setTransactions });

    // If the user loads MyLlave in a device that has a width > 640, then a vertical advertisement can fit
    if (window.innerWidth > 640) {
      advertiseGrab("Vertical", setAdvertisement);
    }
  }, []);

  useEffect(() => {
    if (searchOption) {
      getMaxPrice(searchOption, { setMaxPrice });
      setSearchFilters({
        address: null,
        type: null,
        city: null,
        price: null,
        beds: null,
        baths: null,
      });
    }
  }, [searchOption]);

  // useEffect(() => {
  //   if (!isLooping) return;
  //   const interval = setInterval(() => {
  //     // Find the current index of viewCategory in the categories array
  //     const currentIndex = categories.indexOf(viewCategory);
  //
  //     // Calculate the next index (loop back to 0 if at the end)
  //     const nextIndex = (currentIndex + 1) % categories.length;
  //
  //     // Set the next category
  //     setViewCategory(categories[nextIndex]);
  //   }, 5000); // 5000 milliseconds = 5 seconds
  //
  //   return () => clearInterval(interval); // Cleanup function to clear interval on unmount or re-render
  // }, [viewCategory]); // useEffect dependency on viewCategory to ensure it runs when viewCategory changes

  return (
    <div>
      <main className="px-[24px] flex gap-5 my-12 mx-auto max-w-[1440px] text-base-content space-y-[23px]">
        <div className="w-full space-y-10">
          <PropertiesByCategory
            title={"Promoted Properties"}
            subtitle={"Featured Properties In Our Listings"}
            redirect_category={"promoted"}
            embeded_further={false}
            properties={
              promoted && promoted?.properties ? promoted.properties : null
            }
          />
          <PropertiesByCategory
            title={"Just Listed!"}
            subtitle={"Properties Most Recently Uploaded"}
            redirect_category={"just-listed"}
            embeded_further={true}
            properties={recent && recent?.properties ? recent.properties : null}
          />
          <ProfessionalSection />
          <PropertiesByCategory
            title={"Hot Properties"}
            subtitle={"Most Viewed Properties In The Past 24 Hours"}
            redirect_category={"hot-properties"}
            embeded_further={true}
            properties={hot && hot?.properties ? hot.properties : null}
          />
          <PropertiesByCategory
            title={"Just Sold & Leased"}
            subtitle={"Properties Sold & Leased Past 3 Days"}
            redirect_category={"sold-and-leased"}
            embeded_further={true}
            properties={
              soldLeased && soldLeased?.properties
                ? soldLeased.properties
                : null
            }
          />
          <PropertiesByCategory
            title={"Just Withdrawn & Expired"}
            subtitle={"Properties Withdrawn & Expired Past 3 Days"}
            redirect_category={"withdrawn-and-expired"}
            embeded_further={true}
            properties={
              expired && expired?.properties ? expired.properties : null
            }
          />
        </div>
        <div className="xl:block hidden w-[244px] relative !mt-0">
          <div className="w-[244px] space-y-5 sticky top-5 left-0">
            {/* Stats Display */}
            <WebStats
              user_stats={stats}
              property_stats={propStats}
              key_stats={transactions}
            />
            <div>
              {advertisement && advertisement.advertisement_url && (
                <a
                  className=""
                  href={
                    advertisement?.redirect_type === "External"
                      ? advertisement?.redirect
                      : `/user/${advertisement?.user_id}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`https://res.cloudinary.com/diur0igkv/image/upload${advertisement?.advertisement_url}`}
                    className="border border-base-300 rounded-md"
                    alt="advertisement"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;
