import { HOST } from "../../constants/HostConstants";

export const getMaxPrice = async (option, { setMaxPrice }) => {
  await fetch(`${HOST}/api/property/getMaxPrice`, {
    method: "POST",
    body: JSON.stringify({
      option,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setMaxPrice(data.max);
    });
};
