import { HOST } from "../constants/HostConstants";
export const transactionDelete = async (
  transaction_id,
  user_id,
  email_address,
  cost,
  option,
  { setPending },
) => {
  const response = await fetch(`${HOST}/api/transaction/transactionDelete`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      transaction_id,
      user_id,
      email_address,
      amount: cost,
      option,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  if (!response.success) {
    throw new Error("Failed to deny transaction");
  } else {
    setPending(response.results);
  }
};
