import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SiTwitter, SiFacebook, SiInstagram, SiTiktok } from "react-icons/si";
import { userById } from "../actions/userById";
import Edit from "../components/Edit";
import ReactPlayer from "react-player";
import { getByIdUser } from "../actions/getByIdUser";
import ProfileView from "../components/ProfileView";

const User = () => {
  const { id } = useParams();
  const [poster, setPoster] = useState(null);
  useEffect(() => {
    userById(id, { setPoster });
  }, []);

  console.log(poster?.class_type);
  return <>{poster && <ProfileView user={poster} preview={true} />}</>;
};

export default User;
