import { useEffect, useState } from "react";
import { userKeyHistory } from "../../actions/history/userKeyHistory";
import { addCommasToPrice } from "../../helpers/conversions";

const KeyHistoryTab = ({ user, setUser }) => {
  const [filter, setFilter] = useState(null);
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(null);

  localStorage.removeItem("tab");
  useEffect(() => {
    reloadKeyHistory();
  }, []);
  const reloadKeyHistory = async () => {
    console.log("in reload");
    setLoading(true);
    await userKeyHistory(user?.user_id, { setHistory });
    setLoading(false);
  };
  console.log(history);
  return (
    <div className="border-t border-base-300 flex md:flex-row flex-col-reverse px-[24px] space-y-5">
      <div className="my-5 w-full space-y-5">
        <h3 className="text-3xl font-medium">My Key History</h3>
        {/* <div className="flex w-full justify-between items-center"> */}
        {/*   <div className="dropdown dropdown-right block xl:hidden"> */}
        {/*     <div tabIndex={0} role="button" className="btn"> */}
        {/*       <button className="block xl:hidden"> */}
        {/*         <Filter /> */}
        {/*       </button> */}
        {/*     </div> */}
        {/*     <ul */}
        {/*       tabIndex={0} */}
        {/*       className="mt-3 z-[1] p-2 space-y-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52" */}
        {/*     > */}
        {/*       <li */}
        {/*         className={`btn btn-sm ${filter === null ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*         onClick={() => setFilter(null)} */}
        {/*       > */}
        {/*         All */}
        {/*       </li> */}
        {/*       <li */}
        {/*         className={`btn btn-sm ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*         onClick={() => setFilter("Broker / Owner")} */}
        {/*       > */}
        {/*         Global Advertisement */}
        {/*       </li> */}
        {/*       <button */}
        {/*         className={`btn btn-sm ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*         onClick={() => setFilter("Broker / Associate")} */}
        {/*       > */}
        {/*         Property Promotion */}
        {/*       </button> */}
        {/**/}
        {/*       <button */}
        {/*         className={`btn btn-sm ${filter === "REALTOR" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*         onClick={() => setFilter("REALTOR")} */}
        {/*       > */}
        {/*         Profile Promotion */}
        {/*       </button> */}
        {/*     </ul> */}
        {/*   </div> */}
        {/*   <div className="flex-wrap gap-5 w-fit rounded-md xl:flex hidden"> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${filter === null ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setFilter(null)} */}
        {/*     > */}
        {/*       All */}
        {/*     </button> */}
        {/**/}
        {/*     <button */}
        {/*       className={`btn btn-sm ${filter === "Global Advertisement" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setFilter(null)} */}
        {/*     > */}
        {/*       Global Advertisement */}
        {/*     </button> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setFilter("Broker / Owner")} */}
        {/*     > */}
        {/*       Property Promotion */}
        {/*     </button> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setFilter("Broker / Associate")} */}
        {/*     > */}
        {/*       Profile Promotion */}
        {/*     </button> */}
        {/*   </div> */}
        {/*   <div> */}
        {/*     <input */}
        {/*       type="text" */}
        {/*       placeholder="Search by name" */}
        {/*       className="input input-bordered w-full max-w-md" */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
        {history && history.length > 0 ? (
          <div className="overflow-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>Key Transaction Id</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Created</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="overflow-y-visible">
                {history.map((data, index) => (
                  <tr key={index} className="overflow-y-visible items-center">
                    <th>{index + 1}</th>
                    <td>{data.transaction_id}</td>
                    <td>{data.transaction_type}</td>
                    <td>{addCommasToPrice(data.transaction_amount)}</td>
                    <td>{data.created_at}</td>
                    <td>{data.description}</td>
                    <td>{data.transaction_action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : loading ? (
          <div className="w-full min-h-screen ">
            <div className="h-[250px] flex justify-center items-center gap-4">
              <h3>Looking for key history</h3>
              <span className="loading loading-dots min-h-screen text-neutral loading-md"></span>
            </div>
          </div>
        ) : (
          <p className="mt-5 text-neutral text-center">No key history found</p>
        )}
      </div>
    </div>
  );
};

export default KeyHistoryTab;
