import { Phone, Mail } from "lucide-react";
import { Link } from "react-router-dom";

const ProfessionalPreview = ({ user }) => {
  return (
    <Link
      to={`/user/${user?.user_id}`}
      className="border border-base-300 rouded-lg p-4 flex flex-wrap gap-2"
    >
      <div className="relative w-[80px] h-[80px] flex justify-center">
        <img
          className="h-full absolute object-cover border border-base-200 w-full rounded-full "
          src={
            user?.picture_url
              ? `https://res.cloudinary.com/diur0igkv/image/upload/${user?.picture_url}`
              : "/error.png"
          }
          alt=""
        />
      </div>
      <div>
        <span className="">
          <p className="font-medium w-full truncate whitespace-nowrap">
            {user?.legal_first_name} {user?.legal_last_name}
          </p>
          <p className="text-[12px] w-full truncate whitespace-nowrap">
            {user?.position
              ? user?.position
              : user?.class_type
                ? user?.class_type
                : ""}{" "}
            {user?.brokerage_name
              ? "- " + user?.brokerage_name
              : user?.builder_company_name
                ? "- " + user?.builder_company_name
                : user?.business_name
                  ? "- " + user?.business_name
                  : user?.lending_company_name
                    ? "- " + user?.lending_company_name
                    : user?.title_company_name
                      ? "- " + user?.title_company_name
                      : ""}
          </p>
        </span>
        <span className="">
          <button
            className="btn btn-outline btn-xs mt-2"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Phone size={12} />
            {user?.phone_number && <p>{user?.phone_number}</p>}
          </button>
        </span>
      </div>
    </Link>
  );
};

export default ProfessionalPreview;
