import React, { useEffect, useRef } from "react";
import { MdEdit } from "react-icons/md";
import { Pencil } from "lucide-react";

const UploadWidgetEdit = ({ setUploadedItems }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "diur0igkv",
        uploadPreset: "thv0xa9w",
      },
      function (error, result) {
        console.log(result);
        if (result["event"] === "success") {
          setUploadedItems((current) => [...current, result["info"]["path"]]);
        }
      },
    );
    if (widgetRef.current.event === "success") {
    }
  }, []);
  return (
    <div
      className="btn btn-neutral btn-circle absolute -right-4 top-0 m-2 hover:scale-105 hover:cursor-pointer transition-all"
      onClick={() => widgetRef.current.open()}
    >
      <Pencil size={32} />
    </div>
  );
};

export default UploadWidgetEdit;
