import { HOST } from "../../constants/HostConstants";
export const transactionStats = async ({ setTransactions }) => {
  fetch(`${HOST}/api/transaction/transactionStats`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.success) {
        setTransactions(data.transactions);
      }
    });
};
