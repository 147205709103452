import { HOST } from "../constants/HostConstants";
export const logoutUser = async ({ setUser }) => {
  // fetch(`${HOST}/api/user/userLogout`, {
  //     method: 'GET',
  //     credentials: 'include',
  //     headers: {
  //         Accept: "application/json, text/plain, */*",
  //         "Content-Type": "application/json",
  //     }
  // }).then((res)=> res.json()).then(data=> {
  //     setUser(false)
  // })
  localStorage.clear("token");
  setUser(false);
};

