import { HOST } from "../constants/HostConstants";

export const userUpdateProfile = async (user, uploadedItems, setUser) => {
  fetch(`${HOST}/api/user/userUpdatePicture`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      userId: user.user_id,
      uploadedItems,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      localStorage.setItem("token", data.token);
      setUser(data.user);
    });
};

