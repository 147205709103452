import { HOST } from "../constants/HostConstants";
export const propertyDelete = async (property_id, user_id) => {
  const response = await fetch(`${HOST}/api/property/propertyDelete`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      property_id,
      user_id,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Property Deletion Unsuccesful");
  } else {
    return response;
  }
};

