import {createContext, useContext, useState} from "react"

export const UserContext = createContext();
export const AlertContext = createContext();
export const PropertyContext = createContext();
export const PropertyDataContext = createContext();

const StepperContext = createContext({ propertyData: "", setPropertyData: null });

export function UseContextProvider({ children }) {
  const [propertyData, setPropertyData] = useState("");
  return (
    <StepperContext.Provider value={{ propertyData, setPropertyData }}>
      {children}
    </StepperContext.Provider>
  );
}

export function useStepperContext() {
  const { propertyData, setPropertyData } = useContext(StepperContext);
  return { propertyData, setPropertyData };
}