import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../actions/userLogin";
import { AlertContext, UserContext } from "../context/MainContext";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import toast from "react-hot-toast";
const Login = () => {
  const [email_address, setEmail_Address] = useState(null);
  const [err, setErr] = useState(null);
  const [password, setPassword] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const nav = useNavigate();
  const handleSubmit = async () => {
    console.log(email_address, password);
    if (!email_address || !password) {
      setErr("You must enter Email Address and Password.");
    } else {
      setLoading(true);
      await toast.promise(loginUser(email_address, password, setUser), {
        loading: "Logging you in...",
        success: (data) => {
          setLoading(false);
          console.log("data is: ", data);
          return "Login Successful!";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      });
    }
  };
  useEffect(() => {
    if (user) {
      nav("/");
    }
  }, [user]);

  return (
    <div className="mx-auto  flex justify-center my-12 overflow-x-hidden">
      <div className="max-w-[861px] w-full p-2 relative space-y-4">
        <h1 className="text-center font-medium text-3xl">Welcome Back</h1>
        <div className="w-[80%] mx-auto">
          <p className="text-center text-sm">
            Enter your credentials to access your account.
          </p>
        </div>
        <div className="flex flex-col px-2 sm:px-8 text-19 space-y-5">
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Email Address</span>
            </div>
            <input
              type="text"
              placeholder="example@gmail.com"
              className="input input-bordered w-full"
              onChange={(e) => setEmail_Address(e.target.value)}
            />
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Password</span>
            </div>
            <div className="relative flex">
              <input
                type={revealPassword ? "text" : "password"}
                placeholder=""
                className="input input-bordered w-full"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="tooltip cursor-pointer absolute right-3 top-3"
                data-tip="Reveal Password"
                onClick={() => setRevealPassword(!revealPassword)}
              >
                {revealPassword ? <EyeOff /> : <Eye />}
              </div>
            </div>
          </label>
          <div className="label flex-wrap gap-5">
            <span className="label-text-alt">
              Don't have an account?{" "}
              <Link to="/register" className="link link-primary">
                Register
              </Link>
            </span>
            {/* <span className="label-text-alt"> */}
            {/*   <Link className="cursor-not-allowed">Forgot Password?</Link> */}
            {/* </span> */}
          </div>
          <button
            className="btn btn-primary mt-8"
            onClick={() => handleSubmit()}
            disabled={loading === true ? true : false}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
