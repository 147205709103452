import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { advertiseGrab } from "../actions/advertiseGrab";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const HorizontalAdd = ({ setUniversalAd }) => {
  const [advertisement, setAdvertisement] = useState(null);

  useEffect(() => {
    advertiseGrab("Horizontal", setAdvertisement);
  }, []);

  return (
    <div className="bg-black text-white z-50 w-full flex justify-center relative">
      {advertisement && (
        <a
          href={
            advertisement?.redirect_type === "External"
              ? advertisement?.redirect
              : `/user/${advertisement?.user_id}`
          }
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-full md:max-w-[734px]"
            src={`https://res.cloudinary.com/diur0igkv/image/upload${advertisement?.advertisement_url}`}
            alt="horizontal ad placement"
          />
        </a>
      )}
      <AiOutlineClose
        className="absolute z-60 top-0 right-0 bg-black hover:scale-105 hover:bg-slate-300 hover:cursor-pointer hover:text-black transition-all"
        size={30}
        onClick={() => setUniversalAd(false)}
      />
    </div>
  );
};

export default HorizontalAdd;
