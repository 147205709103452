import { HOST } from "../constants/HostConstants";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
export const addUser = async (
  username,
  email_address,
  password,
  legal_first_name,
  legal_last_name,
  class_type,
  phone_number,
  professionId,
  companyName,
  position,
  key_balance,
  brokerage_name,
  brokerage_license,
  broker_name,
  broker_license,
  builder_company_name,
  nmls_number,
  lending_company_name,
  title_company_name,
  company,
  companyIndustry,
  entityName,
  referrer,
) => {
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, "0");

  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY

  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const response = await fetch(`${HOST}/api/user/userAdd`, {
    method: "POST",
    body: JSON.stringify({
      username,
      emailAddress: email_address,
      password,
      legalFirstName: legal_first_name,
      legalLastName: legal_last_name,
      classType: class_type,
      phoneNumber: phone_number,
      keyBalance: key_balance,
      companyName: companyName,
      position,
      professionId,
      date: currentDate,
      brokerageName: brokerage_name,
      brokerageLicense: brokerage_license,
      brokerName: broker_name,
      brokerLicense: broker_license,
      builderCompanyName: builder_company_name,
      nmlsNumber: nmls_number,
      lendingCompanyName: lending_company_name,
      titleCompanyName: title_company_name,
      company: company,
      companyIndustry: companyIndustry,
      entityName: entityName,
      referrer: referrer,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error(response.statusText);
  }

  const data = response;
  return data;
};
