import { HOST } from "../constants/HostConstants";
export const propertyStats = async ({ setPropStats }) => {
  fetch(`${HOST}/api/property/propertyStats`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setPropStats(data);
    });
};

