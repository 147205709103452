import { HOST } from "../constants/HostConstants";
export const propertyCreate = async (
  propertyData,
  setPropertyData,
  user,
  uploadedItems,
) => {
  const date = new Date();

  let currentDay = String(date.getDate()).padStart(2, "0");

  let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

  let currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY

  let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  const response = await fetch(`${HOST}/api/property/propertyCreate`, {
    method: "POST",
    body: JSON.stringify({
      propertyData,
      user_id: user.user_id,
      uploadedItems,
      currentDate,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
      // setPropertyData(false);
    });

  console.log(response);
  if (!response.success) {
    throw new Error("Property Upload Unsuccessful");
  }

  const data = response;
  console.log(data);

  if (data.property_id) {
    console.log(data.property_id);
    return data.property_id;
  }
};
