import { useContext, useEffect, useState } from "react";
import { UserContext, useStepperContext } from "../../context/MainContext";
import UploadWidget from "../UploadWidget";
import { propertyCreate } from "../../actions/propertyCreate";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CircleHelp } from "lucide-react";
import ReactPlayer from "react-player";
import { propertyUpdate } from "../../actions/propertyUpdate";
import { getTitleClosers } from "../../actions/property/getTitleClosers";
import { getLenders } from "../../actions/property/getLenders";

const contractRequiredFields = [
  "agreement_type",
  "cso",
  "var_comm",
  "escrow",
  "list_price",
];

const possession = [
  "Closing and Funding",
  "Immediate",
  "Negotiable",
  "See Remarks",
  "Subject to Tenant Rights",
];

const showingInstructions = [
  "24 Hour Notice",
  "Alarm Code",
  "Call LO/LA",
  "Call Occupant",
  "CBS Code",
  "Gate Code",
  "Guard Dog",
  "LA Must Accompany",
  "Lockbox",
  "No Sign",
  "Owner Occupied",
  "Other",
  "Pets",
  "Pick Up Key",
  "Restricted Days/Hours",
  "Show Anytime",
  "Tenant Occupied",
  "Vacant",
  "Vacant Land",
];

const proposedFinancing = [
  "Cash",
  "Conventional",
  "FHA",
  "Lease Available",
  "Other",
  "Owner Financing",
  "Texas Veterans",
  "VA",
];

const Upload = ({
  uploadedItems,
  setUploadedItems,
  loading,
  setLoading,
  id,
}) => {
  const { propertyData, setPropertyData } = useStepperContext();
  const [contractError, setContractError] = useState(false);
  const [contractFieldsError, setContractFieldsError] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [titleClosers, setTitleClosers] = useState(null);
  const [lenders, setLenders] = useState(null);
  const nav = useNavigate();

  useEffect(() => {
    getTitleClosers({ setTitleClosers });
    getLenders({ setLenders });
    if (
      !propertyData["lockbox"] ||
      propertyData["lockbox"] === undefined ||
      propertyData["lockbox"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, lockbox: "No" }));
    }
    if (
      !propertyData["var_comm"] ||
      propertyData["var_comm"] === undefined ||
      propertyData["var_comm"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, var_comm: "No" }));
    }

    if (!Array.isArray(propertyData["possession"])) {
      setPropertyData((prevData) => ({ ...prevData, possession: [] }));
    }
    if (!Array.isArray(propertyData["showingInstructions"])) {
      setPropertyData((prevData) => ({ ...prevData, showingInstructions: [] }));
    }
    if (!Array.isArray(propertyData["proposedFinancing"])) {
      setPropertyData((prevData) => ({ ...prevData, proposedFinancing: [] }));
    }
  }, []);
  console.log(titleClosers);
  console.log(lenders);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setPropertyData({ ...propertyData, [name]: value });
  };

  // Special case handler for updating fields for property data
  // These fields should only require Yes/No options for field
  // Requires name and value to be set for element used for
  const handleChangeCheckSpecial = (e) => {
    const { name, value } = e.target;

    if (propertyData[name] === "Yes") {
      setPropertyData({ ...propertyData, [name]: "No" });
    } else if (propertyData[name] === "No") {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    } else if (!value) {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    }
    // Typically handles the data that requires text whether than checkboxes.
    else {
      setPropertyData({ ...propertyData, [name]: value });
    }
  };

  const handleChangeCheck = (e, featureCategory) => {
    const { name, value } = e.target;

    if (featureCategory === "possession") {
      if (propertyData.possession.includes(name)) {
        setPropertyData((prevData) => ({
          ...prevData,
          possession: prevData.possession.filter((item) => item !== name),
        }));
      } else {
        setPropertyData((prevData) => ({
          ...prevData,
          possession: [...prevData.possession, name],
        }));
      }
    }
    if (featureCategory === "showingInstructions") {
      if (propertyData.showingInstructions.includes(name)) {
        setPropertyData((prevData) => ({
          ...prevData,
          showingInstructions: prevData.showingInstructions.filter(
            (item) => item !== name,
          ),
        }));
      } else {
        setPropertyData((prevData) => ({
          ...prevData,
          showingInstructions: [...prevData.showingInstructions, name],
        }));
      }
    }
    if (featureCategory === "proposedFinancing") {
      if (propertyData.proposedFinancing.includes(name)) {
        setPropertyData((prevData) => ({
          ...prevData,
          proposedFinancing: prevData.proposedFinancing.filter(
            (item) => item !== name,
          ),
        }));
      } else {
        setPropertyData((prevData) => ({
          ...prevData,
          proposedFinancing: [...prevData.proposedFinancing, name],
        }));
      }
    }
  };

  const upload = async () => {
    if (user === false) {
      return nav("/");
    } else if (!id) {
      await toast.promise(
        propertyCreate(propertyData, setPropertyData, user, uploadedItems),
        {
          loading: "Uploading Property...",
          success: (data) => {
            setLoading(false);
            nav(`/property/${data}`);
            return "Property Uploaded";
          },
          error: (err) => {
            setLoading(false);
            return err.message;
          },
        },
      );
    } else {
      await toast.promise(propertyUpdate(propertyData, user, uploadedItems), {
        loading: "Updating Property...",
        success: (data) => {
          setLoading(false);
          nav(`/property/${data}`);
          return "Property Updated";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      });
    }
  };

  console.log(propertyData["proposedFinancing"]);
  console.log(propertyData["title_company_used"]);
  console.log(propertyData["lender_used"]);
  return (
    <>
      <dialog id="my_modal_2" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            {id ? "Update Property" : "Upload Property"}
          </h3>
          <p>Edits can be made later</p>
          <form method="dialog" className="py-4 space-y-3">
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_2").close();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("my_modal_2").close();
                  upload();
                }}
              >
                {id
                  ? loading
                    ? "Updating..."
                    : "Update Property"
                  : loading
                    ? "Uploading..."
                    : "Upload Property"}
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="my-12 space-y-10">
        <div className="space-y-5">
          <h3
            className={`text-19 font-medium ${contractError ? "text-error" : ""}`}
          >
            Contract Information
          </h3>
          <hr />

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit h-fit">
              <span className="label-text">Variable Commission</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["var_comm"] === "Yes" ? "checkbox-primary" : ""}`}
                name="var_comm"
                checked={propertyData["var_comm"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>

            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label_text`}>Agreement Type</span>
              </div>
              <select
                className="select select-bordered"
                name="agreement_type"
                value={propertyData["agreement_type"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                {propertyData["for"] === "Lease" ? (
                  <>
                    <option>Exclusive Right To Lease</option>
                    <option>Exclusive Right To Lease With Exclusions</option>
                  </>
                ) : (
                  <>
                    <option>Exclusive Right To Sell</option>
                    <option>Exclusive Right To Sell With Exclusions</option>
                  </>
                )}
              </select>
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>CSO</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="cso"
                value={propertyData["cso"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label_text`}>CSO Type</span>
              </div>
              <select
                className="select select-bordered"
                name="cso_type"
                value={propertyData["cso_type"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                <option>Dollar Amount</option>
                <option>Percentage</option>
              </select>
            </label>

            {/* <label className="form-control w-full col-span-1"> */}
            {/*   <div className="label"> */}
            {/*     <span className={`label-text`}>Escrow</span> */}
            {/*   </div> */}
            {/*   <input */}
            {/*     type="text" */}
            {/*     className="input input-bordered w-full " */}
            {/*     name="escrow" */}
            {/*     value={propertyData["escrow"] || ""} */}
            {/*     onChange={handleChange} */}
            {/*   /> */}
            {/* </label> */}
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>Listing Date</span>
              </div>
              <input
                type="date"
                className="input input-bordered w-full "
                name="listing_date"
                value={propertyData["listing_date"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>Date Available</span>
              </div>
              <input
                type="date"
                className="input input-bordered w-full "
                name="date_available"
                value={propertyData["date_available"] || ""}
                onChange={handleChange}
              />
            </label>

            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>Expiration Date</span>
              </div>
              <input
                type="date"
                className="input input-bordered w-full "
                name="expiration_date"
                value={propertyData["expiration_date"] || ""}
                onChange={handleChange}
              />
            </label>

            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text flex gap-1`}>
                  List Price
                  <p className="text-[14px] text-gray-500">(no commas)</p>
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="list_price"
                value={propertyData["list_price"] || ""}
                onChange={handleChange}
              />
            </label>
            {propertyData["for"] === "Lease" ? (
              <label className="form-control w-full col-span-1">
                <div className="label flex justify-start gap-1">
                  <span className={`label-text`}>Security Deposit</span>

                  <p className="text-[14px] text-gray-500">(no commas)</p>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="security_deposit"
                  value={propertyData["security_deposit"] || ""}
                  onChange={handleChange}
                />
              </label>
            ) : (
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span className={`label-text`}>Escrow</span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="escrow"
                  value={propertyData["escrow"] || ""}
                  onChange={handleChange}
                />
              </label>
            )}
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>Owners Name</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="owners_name"
                value={propertyData["owners_name"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span className={`label-text`}>Owner 2 Name</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="owners_2_name"
                value={propertyData["owners_2_name"] || ""}
                onChange={handleChange}
              />
            </label>

            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Showing Instructions</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["showingInstructions"]) &&
                  showingInstructions.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["showingInstructions"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["showingInstructions"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "showingInstructions")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Possession</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["possession"]) &&
                  possession.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["possession"].includes(feature)}
                        className={`checkbox ${propertyData["possession"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "possession")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            {(propertyData["vacant_land"] === "Yes" ||
              propertyData["for"] === "Sale") && (
              <div className="col-span-1 space-y-5">
                <h3 className="text-19 font-medium">Proposed Financing</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["proposedFinancing"]) &&
                    proposedFinancing.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["proposedFinancing"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["proposedFinancing"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "proposedFinancing")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {propertyData["proposedFinancing"] &&
          propertyData["proposedFinancing"].length > 0 &&
          propertyData["proposedFinancing"].includes("Owner Financing") &&
          propertyData["for"] === "Sale" && (
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Finance Values</h3>
              <hr />
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Down Payment %</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="down_payment_percent"
                    value={propertyData["down_payment_percent"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Year Amortization</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="year_amortization"
                    value={propertyData["year_amortization"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Year Balloon</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="year_balloon"
                    value={propertyData["year_balloon"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Interest</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="interest"
                    value={propertyData["interest"] || ""}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
          )}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
          {propertyData["for"] !== "Lease" && (
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <span className="label-text">Preferred Lender</span>
              </div>
              <select
                className="select select-bordered"
                name="lender_used"
                value={propertyData["lender_used"] || ""}
                onChange={(e) => {
                  const selectedCloserId = e.target.value;
                  setPropertyData((prevData) => ({
                    ...prevData,
                    lender_used: selectedCloserId,
                  }));
                }}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                {lenders &&
                  lenders.length > 0 &&
                  lenders.map((lender, index) => (
                    <option key={index} value={lender.user_id}>
                      {lender.lending_company_name} - {lender.legal_last_name},{" "}
                      {lender.legal_first_name}
                    </option>
                  ))}
              </select>{" "}
            </label>
          )}
          {propertyData["for"] !== "Lease" && (
            <div className="space-y-5">
              <label className="form-control w-full max-w-xs">
                <div className="label">
                  <span className="label-text">Preferred Title Closer</span>
                </div>
                <select
                  className="select select-bordered"
                  name="title_closer_used"
                  value={propertyData["title_closer_used"] || ""}
                  onChange={(e) => {
                    const selectedCloserId = e.target.value;
                    setPropertyData((prevData) => ({
                      ...prevData,
                      title_closer_used: selectedCloserId,
                    }));
                  }}
                >
                  <option disabled value="default" hidden></option>
                  <option></option>
                  {titleClosers &&
                    titleClosers.length > 0 &&
                    titleClosers.map((closer, index) => (
                      <option key={index} value={closer.user_id}>
                        {closer.title_company_name} - {closer.legal_last_name},{" "}
                        {closer.legal_first_name}
                      </option>
                    ))}
                </select>{" "}
              </label>
            </div>
          )}
        </div>

        <div className="space-y-5">
          <h3 className={`text-19 font-medium`}>
            Media Upload / Organize Order
          </h3>
          <hr />
          <UploadWidget
            uploadedItems={uploadedItems}
            setUploadedItems={setUploadedItems}
          />
          <div>
            <label className="form-control w-full max-w-lg">
              <div className="label gap-2 !items-center !justify-start">
                <span className="label-text">Add videos</span>
                <div
                  className="tooltip"
                  data-tip="Add video url to display on property view page. If video url is supported, it will display below. If no video is playable it is not supported. Displaying multiple videos currently does not work, please only submit one in format: https://full_url.com"
                >
                  <button className="">
                    <CircleHelp />
                  </button>
                </div>
              </div>
              <input
                type="text"
                placeholder=""
                name="embeded_url"
                value={propertyData["embeded_url"] || ""}
                className="input input-bordered w-full max-w-lg"
                onChange={handleChange}
              />
            </label>
            <div className="my-10 h-full">
              {propertyData["embeded_url"] ? (
                propertyData["embeded_url"].includes(",") ? (
                  <div
                    className={`h-full grid grid-rows-[${propertyData["embeded_url"].length - 1}]`}
                  >
                    {propertyData["embeded_url"]
                      .split(",")
                      .map((url, index) => (
                        <ReactPlayer
                          key={index}
                          className="pl-2 mt-4"
                          width="60%"
                          height="100%"
                          url={url.trim()} // Use the trimmed URL from split
                          controls
                        />
                      ))}
                  </div>
                ) : (
                  <ReactPlayer
                    className="pl-2"
                    width="60%"
                    height="100%"
                    url={propertyData["embeded_url"]}
                    controls
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upload;
