import { HOST } from "../../constants/HostConstants";
export const stakingsByUser = async (user_id, { setWithdraws }) => {
  const response = await fetch(`${HOST}/api/transaction/stakingsByUser`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      user_id,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  console.log(response);
  setWithdraws(response);
  return response;
};
