import { HOST } from "../../constants/HostConstants";
export const getUserStakeAmount = async (user_id, { setStake }) => {
  fetch(`${HOST}/api/user/getUserStakeAmount`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        setStake(data.amount);
      }
    });
};
