import React, { useContext, useEffect, useState } from "react";
import { addUser } from "../actions/userRegister";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { CircleHelp } from "lucide-react";
import { PatternFormat } from "react-number-format";
import { Eye, EyeOff } from "lucide-react";

import toast from "react-hot-toast";
import { UserContext } from "../context/MainContext";

const RealtorFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">REALTOR License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              professionId: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageLicense: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Broker Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Broker License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerLicense: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const InvestorFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Entity Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              entityName: e.target.value,
            }))
          }
        />
      </label>

      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Position</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              position: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const BrokerOwnerFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">REALTOR License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              professionId: e.target.value,
            }))
          }
        />
      </label>

      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageLicense: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const BrokerAssociateFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">REALTOR License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              professionId: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Brokerage License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerageLicense: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Broker Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Broker License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              brokerLicense: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const BuilderFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control flex-[0.5]">
        <div className="label">
          <span className="label-text">Building Company Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              buildingCompanyName: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control flex-[0.5]">
        <div className="label">
          <span className="label-text">Position</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              position: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const LenderFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">NMLS License #</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              nmlsNumber: e.target.value,
            }))
          }
        />
      </label>

      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Lending Company Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              lendingCompanyName: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const AdvertiserFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Company</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              company: e.target.value,
            }))
          }
        />
      </label>

      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Company Industry</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              companyIndustry: e.target.value,
            }))
          }
        />
      </label>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Position</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              position: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const TitleCloserFields = ({ setUserData }) => {
  return (
    <>
      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Title Company Name</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              titleCompanyName: e.target.value,
            }))
          }
        />
      </label>

      <label className="form-control w-full">
        <div className="label">
          <span className="label-text">Position</span>
        </div>
        <input
          type="text"
          placeholder=""
          className="input input-bordered w-full"
          onChange={(e) =>
            setUserData((prevData) => ({
              ...prevData,
              position: e.target.value,
            }))
          }
        />
      </label>
    </>
  );
};

const Register = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referrer = queryParams.get("referrer") || null;

  // Current User
  const { user } = useContext(UserContext);

  // Register Fields
  const [userData, setUserData] = useState({
    emailAddress: null,
    password: null,
    legalFirstName: null,
    legalLastName: null,
    profession: null,
    phoneNumber: null,
    companyName: null,
    position: null,
    brokerName: null,
    brokerLicense: null,
    brokerageName: null,
    brokerageLicense: null,
    builderCompanyName: null,
    nmlsNumber: null,
    lendingCompanyName: null,
    titleCompanyName: null,
    company: null,
    companyIndustry: null,
    professionId: null,
    entityName: null,
  });

  const [loading, setLoading] = useState(false);

  const [revealPassword, setRevealPassword] = useState(false);
  const [rulesAgreed, setRulesAgreed] = useState(false);

  const nav = useNavigate();
  const handleSubmit = async () => {
    // Any user must have email and password set
    if (!userData.emailAddress || !userData.password) {
      toast.error("Missing Required Fields.");
      return;
    }

    // Check conditionally required fields based on users profession
    if (userData.profession === "Title Closer") {
      if (!userData.position || !userData.titleCompanyName) {
        toast.error("Misssing Title Company Details.");
        return;
      }
    }
    if (userData.profession === "Lender") {
      if (!userData.lendingCompanyName || !userData.nmlsNumber) {
        toast.error("Missing Lender Details.");
        return;
      }
    }
    if (userData.profession === "Builder") {
      if (!userData.builderCompanyName || !userData.profession) {
        toast.error("Missing Builder Details.");
        return;
      }
    }
    if (userData.profession === "Broker / Associate") {
      if (!userData.brokerName || !userData.brokerLicense) {
        toast.error("Missing Broker Details.");
        return;
      }
    }
    if (userData.profession === "Broker / Owner") {
      if (!userData.brokerageName || !userData.brokerLicense) {
        toast.error("Missing Brokerage Details.");
        return;
      }
    }
    if (userData.profession === "Advertiser") {
      if (!userData.company || !userData.companyIndustry) {
        toast.error("Missing Brokerage Details.");
        return;
      }
    }
    if (userData.profession === "Investor") {
      if (!userData.entityName || !userData.position) {
        toast.error("Missing Investor Details.");
        return;
      }
    }

    if (userData.profession === "REALTOR") {
      if (!userData.professionId) {
        toast.error("Missing Realtor License #");
        return;
      }
    }

    setLoading(true);
    await toast.promise(
      addUser(
        null,
        userData.emailAddress,
        userData.password,
        userData.legalFirstName,
        userData.legalLastName,
        userData.profession,
        userData.phoneNumber,
        userData.professionId,
        userData.companyName,
        userData.position,
        0,
        userData.brokerageName,
        userData.brokerageLicense,
        userData.brokerName,
        userData.brokerLicense,
        userData.builderCompanyName,
        userData.nmlsNumber,
        userData.lendingCompanyName,
        userData.titleCompanyName,
        userData.company,
        userData.companyIndustry,
        userData.entityName,
        referrer,
      ),
      {
        loading: "Registering account...",
        success: () => {
          setLoading(false);
          localStorage.setItem("emailForRegister", userData.emailAddress);
          nav("/welcome");
          return "Registration Successful";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  // If user is currently logged in, redirect back home
  useEffect(() => {
    if (user) {
      nav("/");
    }
  }, [user]);

  return (
    <div className="mx-auto flex justify-center my-12 overflow-x-hidden">
      <dialog id="my_modal_21" className="modal ">
        <div className="modal-box !overflow-y-auto space-y-5">
          <div className="space-y-2">
            <h3 className="font-bold text-xl">Registration Rules</h3>
            <p className="text-[14px]">
              Welcome to MyLlave. To foster a community built on honesty and
              trust, we ask all members to adhere to the following registration
              rules.
            </p>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">1. Real Identity Requirement</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Real Names:</span> All members
                must register using their real names. Pseudonyms or aliases are
                not permitted.
              </li>
              <li>
                <span className="font-medium">Profile Information:</span>{" "}
                Provide accurate and truthful information in your profile,
                including a valid email address.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">2. Age Requirement</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Minimum Age:</span> Members must
                be at least 18 years old to register. By registering, you
                confirm that you meet this agre requirement.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">3. Account Security</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Unique Password:</span> Choose a
                strong, unique password for your account and keep it
                confidential. Do not share your password with anyone.
              </li>
              <li>
                <span className="font-medium">Account protection:</span> You are
                responsible for all activity on your account. Contact
                administrators if you suspect any unauthorized use.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">4. Respectful conduct</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Community Guidelines:</span> By
                registering, you agree to abide by our community guidelines,
                which include maintaining respectful interactions and not
                engaging in harassment, hate speech, or any form of abuse.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">5. Prohibited Activities</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">False Information:</span>{" "}
                Providing false information during registration or in your
                profile is strictly prohibited.
              </li>
              <li>
                <span className="font-medium">Impersonation:</span>{" "}
                Impersonating other members or individuals is prohibited.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">
              6. Privacy and Data Protection
            </h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Data Usage:</span> Your personal
                information will be used in accordance with our Privacy Policy,
                which details how we protect your data and your rights regarding
                it.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">7. Compliance with Laws</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Legal Compliance:</span> By
                registering, you agree to comply with all appliacable laws and
                regulations.
              </li>
            </ul>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Reporting Violations:</span>{" "}
                Report any violations of these rules or suspicious activities to
                the administrators.
              </li>
            </ul>
          </div>
          <div className="space-y-3">
            <h2 className="text-lg font-bold">8. Account Violation</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Violation of Rules:</span>{" "}
                Violating any of these rules may result in account suspension or
                termination without prior notice.
              </li>
            </ul>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Appeal Processs:</span> If your
                account is terminated, you may appeal the decision by contacting
                the forum administrators with a detailed explanation.
              </li>
            </ul>
          </div>

          <div className="space-y-3">
            <h2 className="text-lg font-bold">9. Amendments to Rules</h2>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Rule Changes:</span>{" "}
                Administrators reserve the right to amend these rules at any
                time. Members will be notified of any changes, and continue use
                of MyLlave constitutes acceptance of the new rules.
              </li>
            </ul>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <span className="font-medium">Reporting Violations:</span>{" "}
                Report any violations of these rules or suspicious activities to
                the administrators.
              </li>
            </ul>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="p-2 relative  space-y-4 ">
        <h1 className="text-center font-medium text-3xl">
          Create Your Account
        </h1>
        <div className="w-[80%] mx-auto">
          <p className="text-center text-sm">
            You're about to become part of a vibrant real estate community that
            values inclusivity and rewards our users for their contributions.{" "}
          </p>
        </div>
        <div className="flex flex-col px-2 sm:px-8 text-19 space-y-5">
          <div role="alert" className="alert text-[14px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="stroke-info shrink-0 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span>Impersonation is a bannable offense.</span>
          </div>

          {/* Default fields */}
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text flex items-center gap-2">
                Profession{" "}
                <div
                  className="tooltip"
                  data-tip="This site promotes those in the real estate industry. Are you any of the following?"
                >
                  <button className="">
                    <CircleHelp size={18} />
                  </button>
                </div>
              </span>
            </div>
            <select
              className="select select-bordered w-full"
              onChange={(e) =>
                setUserData((prevData) => ({
                  ...prevData,
                  profession: e.target.value,
                }))
              }
              defaultValue="default"
            >
              <option disabled value="default" hidden>
                Select Profession
              </option>
              <option>Advertiser</option>
              <option>Broker / Owner</option>
              <option>Broker / Associate</option>
              <option>Builder</option>
              <option>Consumer</option>
              <option>Investor</option>
              <option>Lender</option>
              <option>REALTOR</option>
              <option>Title Closer</option>
            </select>
          </label>
          <div className="flex gap-4 flex-wrap">
            <label className="form-control flex-[0.5]">
              <div className="label">
                <span className="label-text">Legal First Name</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full"
                onChange={(e) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    legalFirstName: e.target.value,
                  }))
                }
              />
            </label>
            <label className="form-control flex-[0.5]">
              <div className="label">
                <span className="label-text">Legal Last Name</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full"
                onChange={(e) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    legalLastName: e.target.value,
                  }))
                }
              />
            </label>
          </div>

          {/* Profession fields that require unique input */}
          {userData.profession === "REALTOR" && (
            <RealtorFields setUserData={setUserData} />
          )}
          {userData.profession === "Title Closer" && (
            <TitleCloserFields setUserData={setUserData} />
          )}
          {userData.profession === "Investor" && (
            <InvestorFields setUserData={setUserData} />
          )}
          {userData.profession === "Broker / Owner" && (
            <BrokerOwnerFields setUserData={setUserData} />
          )}
          {userData.profession === "Broker / Associate" && (
            <BrokerAssociateFields setUserData={setUserData} />
          )}
          {userData.profession === "Builder" && (
            <BuilderFields setUserData={setUserData} />
          )}
          {userData.profession === "Lender" && (
            <LenderFields setUserData={setUserData} />
          )}
          {userData.profession === "Advertiser" && (
            <AdvertiserFields setUserData={setUserData} />
          )}

          {/* Continuing default fields */}
          <label className="form-control flex-[0.5]">
            <div className="label">
              <span className="label-text">Phone Number</span>
            </div>
            <PatternFormat
              className="input input-bordered w-full"
              format="+1 (###) ### ####"
              onChange={(e) =>
                setUserData((prevData) => ({
                  ...prevData,
                  phoneNumber: e.target.value,
                }))
              }
              allowEmptyFormatting
              mask="_"
            />
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Email Address</span>
            </div>
            <input
              type="text"
              placeholder="example@gmail.com"
              className="input input-bordered w-full"
              onChange={(e) =>
                setUserData((prevData) => ({
                  ...prevData,
                  emailAddress: e.target.value,
                }))
              }
            />
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Password</span>
            </div>
            <div className="relative flex">
              <input
                type={revealPassword ? "text" : "password"}
                placeholder=""
                className="input input-bordered w-full"
                onChange={(e) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    password: e.target.value,
                  }))
                }
              />
              <div
                className="tooltip cursor-pointer absolute right-3 top-3"
                data-tip="Reveal Password"
                onClick={() => setRevealPassword(!revealPassword)}
              >
                {revealPassword ? <EyeOff /> : <Eye />}
              </div>
            </div>
          </label>
          <div className="space-y-1 flex justify-between items-end">
            <div className="form-control w-fit">
              <label className="label cursor-pointer space-x-5">
                <span className="label-text">
                  I agree to the{" "}
                  <button
                    className="link link-primary link-underline"
                    onClick={() =>
                      document.getElementById("my_modal_21").showModal()
                    }
                  >
                    registration rules
                  </button>
                </span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-primary"
                  onClick={() => setRulesAgreed(!rulesAgreed)}
                />
              </label>
            </div>
            <div className="label">
              <span className="label-text-alt">
                Already have an account?{" "}
                <Link to="/login" className="link link-primary">
                  Log In
                </Link>
              </span>
            </div>
          </div>
          <button
            className="btn btn-primary mt-8"
            onClick={() => handleSubmit()}
            disabled={loading || !rulesAgreed === true ? true : false}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
