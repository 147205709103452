import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { propertyById } from '../actions/propertyById'
import PropColumn from '../components/PropColumn'

const Search = () => {
  const {id} = useParams()
  const [properties, setProperties] = useState(null)

  useEffect(()=> {
    propertyById({setProperties},id)
  },[])
  return (
    <div className="mt-32">
      <h1 className="text-30 py-4 px-6 shadow-md bg-slate-100 mt-32 relative">Search Results for <i>{id}</i></h1>    
        <div className="flex justify-evenly flex-wrap gap-2 mt-6 relative">
          <PropColumn property={properties}/>
      </div>
    </div>
  )
}

export default Search