import { useContext, useEffect, useState } from "react";
import { UserContext, useStepperContext } from "../../context/MainContext";
import { Hotel, Home, HandCoins, PiggyBank } from "lucide-react";
import toast from "react-hot-toast";
import { propertyByIdByUser } from "../../actions/property/specific/propertyByIdByUser";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { getPredefinedValues } from "../../actions/property/getPredefinedValues";
import Unit from "./unit";

const locationFieldNames = [
  "sub_type",
  "street_number",
  "dir_prefix",
  "street_name",
  "dir_suffix",
  "street_type",
  "city",
  "county",
  "state",
  "zip",
  "unit_number",
  "subdivision",
  "school_district",
  "elementary_school",
  "middle_school",
  "high_school",
];

const valuesNoVacantFieldNames = [
  "price",
  "beds_total",
  "baths_full",
  "living_areas",
  "dining_area",
  "acreage",
  "living_sqft",
  "est_year_built",
  "property_tax_number",
  "legal_description",
];

const valuesVacantFieldNames = [
  "price",
  "acreage",
  "property_tax_number",
  "lot_dimensions",
  "taxes_no_exemption",
  "tax_year",
  "zoning",
  // 'lease_expiration_date',
  "legal_description",
];

const poaFieldNames = [
  "poa_mandatory",
  "poa_fees",
  "poa_term",
  "poa_transfer_fee",
  "poa_email_address",
  "poa_name",
  "poa_phone",
  "poa_website",
];

const Details = ({ verifyNextStep, setVerifyNextStep, id }) => {
  // Temporary bypass for skipping step
  const bypass = true;
  const nav = useNavigate();

  const { propertyData, setPropertyData } = useStepperContext();
  const { user, setUser } = useContext(UserContext);

  // Used to update error for specific field where user changed
  const [update, setUpdate] = useState(false);

  // Title Error Handlers
  const [typeError, setTypeError] = useState(false);
  const [actionError, setActionError] = useState(false);

  // Children Error Handlers
  const [locationChildrenErrors, setLocationChildrenErrors] = useState([]);
  const [valuesNoVacant, setValuesNoVacant] = useState([]);
  const [valuesVacant, setValuesVacant] = useState([]);
  const [poaErrors, setPoaErrors] = useState([]);

  // Use Effect To Handle All Use Cases Of Property (Add/Edit)
  // If any checkboxes are undefined set them to No (They default value in the database)
  // This allows the user to see checkboxes when they go back a step if they checked them or not
  // Should work on edit page
  useEffect(() => {
    if (!id) {
      getPredefinedValues({ setPropertyData });
    }
    if (
      !propertyData["vacant_land"] ||
      propertyData["vacant_land"] === undefined ||
      propertyData["vacant_land"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, vacant_land: "No" }));
    }
    if (
      !propertyData["poa"] ||
      propertyData["poa"] === undefined ||
      propertyData["poa"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, poa: "No" }));
    }
    if (
      !propertyData["poa_mandatory"] ||
      propertyData["poa_mandatory"] === undefined ||
      propertyData["poa"] === ""
    ) {
      setPropertyData((prevData) => ({ ...prevData, poa_mandatory: "No" }));
    }
  }, []);

  useEffect(() => {
    if (verifyNextStep === false) {
      // Get rid of this bypass in production
      if (bypass) {
        setVerifyNextStep(true);
      }

      checkRequiredFields();
    }
  }, [verifyNextStep]);

  console.log(propertyData);
  useEffect(() => {
    if (
      id &&
      user !== false &&
      user?.user_id &&
      !propertyData["unit_details"]
    ) {
      propertyByIdByUser({ setPropertyData }, id, user?.user_id);
    } else if (user === false) {
      nav("/");
    }
  }, [id, user]);

  useEffect(() => {
    if (propertyData === false) {
      nav("/");
    }
  }, [propertyData]);
  useEffect(() => {
    if (propertyData["type"]) setTypeError(false);
    if (propertyData["for"]) setActionError(false);
    // If location field has any missing fields
    if (locationChildrenErrors.length) setVerifyNextStep(null);
    if (valuesVacant.length) setVerifyNextStep(null);
    if (valuesNoVacant.length) setVerifyNextStep(null);
    if (poaErrors.length) setVerifyNextStep(null);
  }, [update]);

  const updateLocationErrors = (fieldName) => {
    if (locationFieldNames.includes(fieldName)) {
      const updatedErrors = locationChildrenErrors.filter(
        (error) => error !== fieldName,
      );
      setLocationChildrenErrors(updatedErrors);
      setVerifyNextStep(null);
    }
  };

  const updateValuesVacantErrors = (fieldName) => {
    if (valuesVacantFieldNames.includes(fieldName)) {
      const updatedErrors = valuesVacant.filter((error) => error !== fieldName);
      setValuesVacant(updatedErrors);
      setVerifyNextStep(null);
    }
  };

  const updateValuesNoVacantErrors = (fieldName) => {
    if (valuesNoVacantFieldNames.includes(fieldName)) {
      const updatedErrors = valuesNoVacant.filter(
        (error) => error !== fieldName,
      );
      setValuesNoVacant(updatedErrors);
      setVerifyNextStep(null);
    }
  };
  const updatePoaErrors = (fieldName) => {
    if (poaFieldNames.includes(fieldName)) {
      const updatedErrors = poaErrors.filter((error) => error !== fieldName);
      setPoaErrors(updatedErrors);
      setVerifyNextStep(null);
    }
  };

  const checkRequiredFields = () => {
    let missingValuesNoVacant = [];
    let missingValuesVacant = [];
    let missingPoa = [];

    // Check Location Required Fields
    const missingLocationFields = locationFieldNames.filter(
      (field) => !propertyData[field],
    );

    // If vacant land is not selected check required fields
    if (
      propertyData["vacant_land"] === "No" ||
      !propertyData["vacant_land"] ||
      propertyData["vacant_land"] === undefined
    ) {
      missingValuesNoVacant = valuesNoVacantFieldNames.filter(
        (field) => !propertyData[field],
      );
    }

    if (propertyData["vacant_land"] === "Yes") {
      missingValuesVacant = valuesVacantFieldNames.filter(
        (field) => !propertyData[field],
      );
    }

    if (propertyData["poa"] === "Yes") {
      missingPoa = poaFieldNames.filter((field) => !propertyData[field]);
    }
    if (!propertyData["type"] && !bypass) setTypeError(true);
    if (!propertyData["for"] && !bypass) setActionError(true);
    setLocationChildrenErrors(missingLocationFields);
    setValuesVacant(missingValuesVacant);
    setValuesNoVacant(missingValuesNoVacant);
    setPoaErrors(missingPoa);
    if (missingLocationFields.length && !bypass)
      toast.error("Missing Required Fields");
    else if (missingValuesNoVacant.length && !bypass)
      toast.error("Missing Required Fields");
    else if (missingValuesVacant.length && !bypass)
      toast.error("Missing Required Fields");
    else if (missingPoa.length && !bypass)
      toast.error("Missing Required Fields");
    else setVerifyNextStep(true);
  };

  // Main handler for updating fields for property data
  // Requires name and value to be set for element used for
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check which section name sent is located and update it
    if (locationFieldNames.includes(name)) {
      console.log("in location field names update:");
      updateLocationErrors(name);
    }
    if (valuesVacant.includes(name)) {
      console.log("in values vacant update1k");
      updateValuesVacantErrors(name);
    }
    if (valuesNoVacant.includes(name)) {
      updateValuesNoVacantErrors(name);
    }
    if (poaFieldNames.includes(name)) {
      updatePoaErrors(name);
    }
    setPropertyData({ ...propertyData, [name]: value });
  };

  // Special case handler for updating fields for property data
  // These fields should only require Yes/No options for field
  // Requires name and value to be set for element used for
  const handleChangeCheck = (e) => {
    setUpdate(!update);
    const { name, value } = e.target;

    if (propertyData[name] === "Yes") {
      setPropertyData({ ...propertyData, [name]: "No" });
    } else if (propertyData[name] === "No") {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    } else if (!value) {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    }
    // Typically handles the data that requires text whether than checkboxes.
    else {
      setPropertyData({ ...propertyData, [name]: value });
    }
  };

  return (
    <div className="my-12 space-y-10">
      <div role="alert" className="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-info shrink-0 w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <span>
          Properties being posted to be immediately marked as sold or leased are
          not to be older than 6 months old since their closing.
        </span>
      </div>
      <div className="space-y-5">
        <p className="text-center text-error"></p>
        <h3 className={`text-19 font-medium ${typeError ? "text-error" : ""}`}>
          Property Type
        </h3>
        <hr />
        <div className="flex gap-5 flex-wrap">
          <button
            className={`btn btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !h-full ${propertyData["type"] === "Commercial" ? "btn-primary" : "hover:!btn-primary"}`}
            value={propertyData["type"] || ""}
            onClick={() => {
              setPropertyData({
                ...propertyData,
                type: "Commercial",
              });
              setUpdate(!update);
            }}
          >
            <Hotel size={32} />
            Commercial
          </button>
          <button
            className={`btn btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !h-full ${propertyData["type"] === "Residential" ? "btn-primary" : "hover:!btn-primary"}`}
            value={propertyData["type"] || ""}
            onClick={() => {
              setPropertyData({ ...propertyData, type: "Residential" });
              setUpdate(!update);
            }}
          >
            <Home size={32} />
            Residential
          </button>
        </div>
      </div>
      <div className="space-y-5">
        <h3
          className={`text-19 font-medium ${actionError ? "text-error" : ""}`}
        >
          Property Is For
        </h3>
        <hr />
        <div className="flex gap-5 flex-wrap">
          <button
            className={`btn min-w-[75px] !btn-base-300 !px-5 !py-6 !flex !flex-col !h-full ${propertyData["for"] === "Sale" ? "btn-primary" : "hover:!btn-primary"}`}
            value={propertyData["for"] || ""}
            onClick={() => {
              setPropertyData({ ...propertyData, for: "Sale" });
              setUpdate(!update);
            }}
          >
            Sale
          </button>
          <button
            className={`btn !btn-base-300 !px-5 !py-6 !flex !flex-col !h-full ${propertyData["for"] === "Lease" ? "btn-primary" : "hover:!btn-primary"}`}
            value={propertyData["for"] || ""}
            onClick={() => {
              setPropertyData({ ...propertyData, for: "Lease" });
              setUpdate(!update);
            }}
          >
            Lease
          </button>
        </div>
      </div>
      <div className="space-y-5">
        <h3 className={`text-19 font-medium `}>Property Location</h3>
        <hr />
        <label className="label gap-5 justify-start cursor-pointer w-fit">
          <span className="label-text">Property is vacant land</span>
          <input
            type="checkbox"
            className={`checkbox ${propertyData["vacant_land"] === "Yes" ? "checkbox-primary" : ""}`}
            name="vacant_land"
            checked={propertyData["vacant_land"] === "Yes"}
            onChange={(e) => {
              handleChangeCheck(e);
            }}
          />
        </label>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-5">
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("sub_type") ? "text-error" : ""}`}
              >
                Property Sub Type
              </span>
            </div>
            <select
              className="select select-bordered"
              name="sub_type"
              value={propertyData["sub_type"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              {propertyData["vacant_land"] === "Yes" ? (
                propertyData["type"] === "Commercial" ? (
                  <>
                    <option>Acreage</option>
                    <option>Commercial Lot</option>
                    <option>Groves</option>
                  </>
                ) : (
                  <>
                    <option>Acreage</option>
                    <option>Groves</option>
                    <option>Resdential Lot</option>
                  </>
                )
              ) : (
                <>
                  <option>Apartment</option>
                  <option>Condo</option>
                  <option>Duplex</option>
                  <option>Fourplex</option>
                  <option>Single Family</option>
                  <option>Town Home</option>
                </>
              )}
            </select>
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("street_number") ? "text-error" : ""}`}
              >
                Street Number
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="street_number"
              value={propertyData["street_number"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("dir_prefix") ? "text-error" : ""}`}
              >
                Prefix
              </span>
            </div>
            <select
              className="select select-bordered"
              name="dir_prefix"
              value={propertyData["dir_prefix"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>N</option>
              <option>S</option>
              <option>E</option>
              <option>W</option>
              <option>NE</option>
              <option>SE</option>
              <option>SW</option>
              <option>NW</option>
            </select>
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("street_name") ? "text-error" : ""}`}
              >
                Street Name
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="street_name"
              value={propertyData["street_name"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("dir_suffix") ? "text-error" : ""}`}
              >
                Suffix
              </span>
            </div>
            <select
              className="select select-bordered"
              name="dir_suffix"
              value={propertyData["dir_suffix"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>N</option>
              <option>S</option>
              <option>E</option>
              <option>W</option>
              <option>NE</option>
              <option>SE</option>
              <option>SW</option>
              <option>NW</option>
            </select>
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("street_type") ? "text-error" : ""}`}
              >
                Street Type
              </span>
            </div>
            <select
              className="select select-bordered"
              name="street_type"
              value={propertyData["street_type"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>Alley</option>
              <option>Avenue</option>
              <option>Circle</option>
              <option>Court</option>
              <option>Cove</option>
              <option>Creek</option>
              <option>Crest</option>
              <option>Drive</option>
              <option>Freeway</option>
              <option>Highway</option>
              <option>Lane</option>
              <option>Loop</option>
              <option>Parkway</option>
              <option>Pass</option>
              <option>Place</option>
              <option>Point</option>
              <option>Ridge</option>
              <option>Road</option>
              <option>Service Road</option>
              <option>Street</option>
              <option>Terrace</option>
              <option>Trace</option>
              <option>Trail</option>
            </select>
          </label>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-5">
          <label className="form-control w-full col-span-1">
            <div className="label flex justify-between">
              <span
                className={`label-text flex justify-between ${locationChildrenErrors.includes("city") ? "text-error" : ""}`}
              >
                Choose City
              </span>
            </div>
            <select
              className="select select-bordered"
              name="city"
              value={propertyData["city"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              {propertyData["cities"] &&
                propertyData["cities"]
                  .sort()
                  .map((city, index) => <option key={index}>{city}</option>)}
            </select>
          </label>

          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("city") ? "text-error" : ""}`}
              >
                Enter City
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="city"
              value={propertyData["city"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label flex justify-between">
              <span
                className={`label-text flex justify-between ${locationChildrenErrors.includes("county") ? "text-error" : ""}`}
              >
                Choose County
              </span>
            </div>
            <select
              className="select select-bordered"
              name="county"
              value={propertyData["county"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              {propertyData["counties"] &&
                propertyData["counties"]
                  .sort()
                  .map((county, index) => (
                    <option key={index}>{county}</option>
                  ))}
            </select>
          </label>

          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("county") ? "text-error" : ""}`}
              >
                Enter County
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="county"
              value={propertyData["county"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("dir_suffix") ? "text-error" : ""}`}
              >
                State
              </span>
            </div>
            <select
              className="select select-bordered"
              name="state"
              value={propertyData["state"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>Texas (TX)</option>
            </select>
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("zip") ? "text-error" : ""}`}
              >
                Zip
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="zip"
              value={propertyData["zip"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("unit_number") ? "text-error" : ""}`}
              >
                Unit Number
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="unit_number"
              value={propertyData["unit_number"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("subdivision") ? "text-error" : ""}`}
              >
                Subdivision
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="subdivision"
              value={propertyData["subdivision"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label flex justify-between">
              <span
                className={`label-text flex justify-between ${locationChildrenErrors.includes("school_district") ? "text-error" : ""}`}
              >
                Choose School District
              </span>
            </div>
            <select
              className="select select-bordered"
              name="school_district"
              value={propertyData["school_district"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              {propertyData["school_districts"] &&
                propertyData["school_districts"]
                  .sort()
                  .map((district, index) => (
                    <option key={index}>{district}</option>
                  ))}
            </select>
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("school_district") ? "text-error" : ""}`}
              >
                Enter School District
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="school_district"
              value={propertyData["school_district"] || ""}
              onChange={handleChange}
            />
          </label>

          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("elementary_school") ? "text-error" : ""}`}
              >
                Elementary School
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="elementary_school"
              value={propertyData["elementary_school"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("middle_school") ? "text-error" : ""}`}
              >
                Middle School
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="middle_school"
              value={propertyData["middle_school"] || ""}
              onChange={handleChange}
            />
          </label>
          <label className="form-control w-full col-span-1">
            <div className="label">
              <span
                className={`label-text ${locationChildrenErrors.includes("high_school") ? "text-error" : ""}`}
              >
                High School
              </span>
            </div>
            <input
              type="text"
              className="input input-bordered w-full "
              name="high_school"
              value={propertyData["high_school"] || ""}
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
      <Unit
        propertyData={propertyData}
        setPropertyData={setPropertyData}
        handleChange={handleChange}
      />
      <div className="space-y-5">
        <h3 className="text-19 font-medium">Property Values</h3>
        <hr />
        <label className="label gap-5 justify-start cursor-pointer w-fit">
          <span className="label-text">POA present</span>
          <input
            type="checkbox"
            className={`checkbox ${propertyData["poa"] === "Yes" ? "checkbox-primary" : ""}`}
            name="poa"
            checked={propertyData["poa"] === "Yes"}
            onChange={handleChangeCheck}
          />
        </label>

        {/* Other expensves */}
        {propertyData["vacant_land"] === "No" ||
        !propertyData["vacant_land"] ? (
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-5">
            {propertyData["sub_type"] !== "Duplex" &&
              propertyData["sub_type"] !== "Fourplex" &&
              propertyData["type"] !== "Commercial" && (
                <>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label_text ${valuesNoVacant.includes("beds_total") ? "text-error" : ""}`}
                      >
                        Beds Total
                      </span>
                    </div>
                    <select
                      className="select select-bordered"
                      name="beds_total"
                      value={propertyData["beds_total"] || ""}
                      onChange={handleChange}
                    >
                      <option disabled value="default" hidden></option>
                      <option></option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                  </label>

                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label_text ${valuesNoVacant.includes("baths_full") ? "text-error" : ""}`}
                      >
                        Baths
                      </span>
                    </div>
                    <select
                      className="select select-bordered"
                      name="baths_full"
                      value={propertyData["baths_full"] || ""}
                      onChange={handleChange}
                    >
                      <option disabled value="default" hidden></option>
                      <option></option>
                      <option>1/2</option>
                      <option>1</option>
                      <option>1 1/2</option>
                      <option>2</option>
                      <option>2 1/2</option>
                      <option>3</option>
                      <option>3 1/2</option>
                      <option>4</option>
                      <option>4 1/2</option>
                      <option>5</option>
                      <option>5 1/2</option>
                      <option>6</option>
                      <option>6 1/2</option>
                      <option>7</option>
                      <option>7 1/2</option>
                      <option>8</option>
                      <option>8 1/2</option>
                      <option>9</option>
                      <option>9 1/2</option>
                      <option>10</option>
                      <option>10 1/2</option>
                      <option>11</option>
                      <option>11 1/2</option>
                      <option>12</option>
                      <option>12 1/2</option>
                    </select>
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label_text ${valuesNoVacant.includes("living_areas") ? "text-error" : ""}`}
                      >
                        Living Areas
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="living_areas"
                      value={propertyData["living_areas"] || ""}
                      onChange={handleChange}
                    />
                  </label>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label_text ${valuesNoVacant.includes("dining_area") ? "text-error" : ""}`}
                      >
                        Dining Areas
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="dining_area"
                      value={propertyData["dining_area"] || ""}
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label_text ${valuesNoVacant.includes("acreage") ? "text-error" : ""}`}
                >
                  Acreage
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="acreage"
                value={propertyData["acreage"] || ""}
                onChange={handleChange}
              />
            </label>
            {propertyData["sub_type"] !== "Duplex" &&
              propertyData["sub_type"] !== "Fourplex" && (
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span
                      className={`label_text ${valuesNoVacant.includes("living_sqft") ? "text-error" : ""}`}
                    >
                      Living Sq Ft
                    </span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="living_sqft"
                    value={propertyData["living_sqft"] || ""}
                    onChange={handleChange}
                  />
                </label>
              )}
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label_text ${valuesNoVacant.includes("est_year_built") ? "text-error" : ""}`}
                >
                  Estimated Year Built
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="est_year_built"
                value={propertyData["est_year_built"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label_text ${valuesNoVacant.includes("property_tax_number") ? "text-error" : ""}`}
                >
                  Property Tax ID #
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="property_tax_number"
                value={propertyData["property_tax_number"] || ""}
                onChange={handleChange}
              />
            </label>
            {propertyData["type"] !== "Residential" &&
              propertyData["for"] !== "Lease" && (
                <>
                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label-text ${valuesVacant.includes("taxes_no_exemption") ? "text-error" : ""}`}
                      >
                        Property Taxes w/o Exemption
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="taxes_no_exemption"
                      value={propertyData["taxes_no_exemption"] || ""}
                      onChange={handleChange}
                    />
                  </label>

                  <label className="form-control w-full col-span-1">
                    <div className="label">
                      <span
                        className={`label-text ${valuesVacant.includes("tax_year") ? "text-error" : ""}`}
                      >
                        Tax Year
                      </span>
                    </div>
                    <input
                      type="text"
                      className="input input-bordered w-full "
                      name="tax_year"
                      value={propertyData["tax_year"] || ""}
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
            {propertyData["type"] === "Commercial" && (
              <>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Office Sq Ft</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="office_sqft"
                    value={propertyData["office_sqft"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Other Bldg Sq Ft</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="other_bldg_sqft"
                    value={propertyData["other_bldg_sqft"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Retail Sq Ft</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="retail_sqft"
                    value={propertyData["retail_sqft"] || ""}
                    onChange={handleChange}
                  />
                </label>
                {/* <label className="form-control w-full col-span-1"> */}
                {/*   <div className="label"> */}
                {/*     <span className={`label-text`}>Total SqFt</span> */}
                {/*   </div> */}
                {/*   <input */}
                {/*     type="text" */}
                {/*     className="input input-bordered w-full " */}
                {/*     name="total_sqft" */}
                {/*     value={propertyData["total_sqft"] || ""} */}
                {/*     onChange={handleChange} */}
                {/*   /> */}
                {/* </label> */}
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Stories</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="stories"
                    value={propertyData["stories"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Warehouse Sq Ft</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="warehouse_sqft"
                    value={propertyData["warehouse_sqft"] || ""}
                    onChange={handleChange}
                  />
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label-text`}>Sq Ft Source</span>
                  </div>
                  <select
                    className="select select-bordered"
                    name="sqft_source"
                    value={propertyData["sqft_source"] || ""}
                    onChange={handleChange}
                  >
                    <option disabled value="default" hidden></option>
                    <option></option>
                    <option>Agt Measured</option>
                    <option>Appraisal</option>
                    <option>Blue Prints</option>
                    <option>Brooks CAD</option>
                    <option>Cameron CAD</option>
                    <option>Galveston CAD</option>
                    <option>Hidalgo CAD</option>
                    <option>Loredo CAD</option>
                    <option>Nueces CAD</option>
                    <option>Seller Measured</option>
                    <option>Star CAD</option>
                    <option>Survey</option>
                    <option>Willacy CAD</option>
                    <option>Zapata CAD</option>
                  </select>
                </label>
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span
                      className={`label-text ${valuesVacant.includes("zoning") ? "text-error" : ""}`}
                    >
                      Zoning
                    </span>
                  </div>
                  <select
                    className="select select-bordered"
                    name="zoning"
                    value={propertyData["zoning"] || ""}
                    onChange={handleChange}
                  >
                    <option disabled value="default" hidden></option>
                    <option></option>
                    <option>O</option>
                    <option>4G</option>
                    <option>7CJ</option>
                    <option>7JC</option>
                    <option>A</option>
                    <option>A-R</option>
                    <option>A1</option>
                    <option>A2</option>
                    <option>A6</option>
                    <option>AI</option>
                    <option>AO</option>
                    <option>AOI</option>
                    <option>AOP</option>
                    <option>AZ</option>
                    <option>B</option>
                    <option>B1</option>
                    <option>B2</option>
                    <option>B3</option>
                    <option>B4</option>
                    <option>C</option>
                    <option>C1</option>
                    <option>C2</option>
                    <option>C3</option>
                    <option>C3L</option>
                    <option>C4</option>
                    <option>C5</option>
                    <option>CN</option>
                    <option>CNTY</option>
                    <option>CO</option>
                    <option>D1</option>
                    <option>D2</option>
                    <option>D3</option>
                    <option>D4</option>
                    <option>D5</option>
                    <option>D6</option>
                    <option>D7</option>
                    <option>D8</option>
                    <option>D9</option>
                    <option>DZ</option>
                    <option>E1</option>
                    <option>ETJ</option>
                    <option>F1</option>
                    <option>F2</option>
                    <option>GR</option>
                    <option>HC</option>
                    <option>I</option>
                    <option>I1</option>
                    <option>I2</option>
                    <option>IA</option>
                    <option>IPI</option>
                    <option>J1</option>
                    <option>J2</option>
                    <option>J3</option>
                    <option>J4</option>
                    <option>J5</option>
                    <option>L</option>
                    <option>LI</option>
                    <option>M</option>
                    <option>M1</option>
                    <option>M2</option>
                    <option>MBHM</option>
                    <option>M4</option>
                    <option>NC</option>
                    <option>O</option>
                    <option>O1</option>
                    <option>O2</option>
                    <option>PUD</option>
                    <option>R</option>
                    <option>R1</option>
                    <option>R1A</option>
                    <option>R1T</option>
                    <option>R2</option>
                    <option>R3</option>
                    <option>R3A</option>
                    <option>R3C</option>
                    <option>R3T</option>
                    <option>R4</option>
                    <option>R5</option>
                    <option>RA1</option>
                    <option>RA2</option>
                    <option>RB1</option>
                    <option>RB2</option>
                    <option>RM</option>
                    <option>RMH</option>
                    <option>RS</option>
                    <option>RU</option>
                    <option>S35B</option>
                    <option>U</option>
                    <option>PV</option>
                    <option>S34R</option>
                    <option>RC</option>
                  </select>
                </label>
              </>
            )}
            <label className="form-control w-full col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="label">
                <span
                  className={`label_text ${valuesNoVacant.includes("legal_description") ? "text-error" : ""}`}
                >
                  Legal Description
                </span>
              </div>
              <textarea
                className="textarea textarea-bordered w-full "
                name="legal_description"
                value={propertyData["legal_description"] || ""}
                onChange={handleChange}
              />
            </label>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-5">
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("acreage") ? "text-error" : ""}`}
                >
                  Acreage
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="acreage"
                value={propertyData["acreage"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("property_tax_number") ? "text-error" : ""}`}
                >
                  Property Tax ID #
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="property_tax_number"
                value={propertyData["property_tax_number"] || ""}
                onChange={handleChange}
              />
            </label>

            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("lot_dimensions") ? "text-error" : ""}`}
                >
                  Lot Dimensions
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="lot_dimensions"
                value={propertyData["lot_dimensions"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("taxes_no_exemption") ? "text-error" : ""}`}
                >
                  Property Taxes w/o Exemption
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="taxes_no_exemption"
                value={propertyData["taxes_no_exemption"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("tax_year") ? "text-error" : ""}`}
                >
                  Tax Year
                </span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full "
                name="tax_year"
                value={propertyData["tax_year"] || ""}
                onChange={handleChange}
              />
            </label>
            <label className="form-control w-full col-span-1">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("zoning") ? "text-error" : ""}`}
                >
                  Zoning
                </span>
              </div>
              <select
                className="select select-bordered"
                name="zoning"
                value={propertyData["zoning"] || ""}
                onChange={handleChange}
              >
                <option disabled value="default" hidden></option>
                <option></option>
                <option>O</option>
                <option>4G</option>
                <option>7CJ</option>
                <option>7JC</option>
                <option>A</option>
                <option>A-R</option>
                <option>A1</option>
                <option>A2</option>
                <option>A6</option>
                <option>AI</option>
                <option>AO</option>
                <option>AOI</option>
                <option>AOP</option>
                <option>AZ</option>
                <option>B</option>
                <option>B1</option>
                <option>B2</option>
                <option>B3</option>
                <option>B4</option>
                <option>C</option>
                <option>C1</option>
                <option>C2</option>
                <option>C3</option>
                <option>C3L</option>
                <option>C4</option>
                <option>C5</option>
                <option>CN</option>
                <option>CNTY</option>
                <option>CO</option>
                <option>D1</option>
                <option>D2</option>
                <option>D3</option>
                <option>D4</option>
                <option>D5</option>
                <option>D6</option>
                <option>D7</option>
                <option>D8</option>
                <option>D9</option>
                <option>DZ</option>
                <option>E1</option>
                <option>ETJ</option>
                <option>F1</option>
                <option>F2</option>
                <option>GR</option>
                <option>HC</option>
                <option>I</option>
                <option>I1</option>
                <option>I2</option>
                <option>IA</option>
                <option>IPI</option>
                <option>J1</option>
                <option>J2</option>
                <option>J3</option>
                <option>J4</option>
                <option>J5</option>
                <option>L</option>
                <option>LI</option>
                <option>M</option>
                <option>M1</option>
                <option>M2</option>
                <option>MBHM</option>
                <option>M4</option>
                <option>NC</option>
                <option>O</option>
                <option>O1</option>
                <option>O2</option>
                <option>PUD</option>
                <option>R</option>
                <option>R1</option>
                <option>R1A</option>
                <option>R1T</option>
                <option>R2</option>
                <option>R3</option>
                <option>R3A</option>
                <option>R3C</option>
                <option>R3T</option>
                <option>R4</option>
                <option>R5</option>
                <option>RA1</option>
                <option>RA2</option>
                <option>RB1</option>
                <option>RB2</option>
                <option>RM</option>
                <option>RMH</option>
                <option>RS</option>
                <option>RU</option>
                <option>S35B</option>
                <option>U</option>
                <option>PV</option>
                <option>S34R</option>
                <option>RC</option>
              </select>
            </label>

            <label className="form-control w-full col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="label">
                <span
                  className={`label-text ${valuesVacant.includes("legal_description") ? "text-error" : ""}`}
                >
                  Legal Description
                </span>
              </div>
              <textarea
                className="textarea textarea-bordered w-full "
                name="legal_description"
                value={propertyData["legal_description"] || ""}
                onChange={handleChange}
              />
            </label>
          </div>
        )}

        {propertyData["poa"] === "Yes" && propertyData["for"] === "Sale" ? (
          <div className="space-y-5 pt-5">
            <h3 className="text-19 font-medium">POA Values</h3>
            <hr />
            <label className="label gap-5 justify-start cursor-pointer w-fit">
              <span
                className={`label-text ${poaErrors.includes("poa_mandatory") ? "text-error" : ""}`}
              >
                POA mandatory
              </span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["poa_mandatory"] === "Yes" ? "checkbox-primary" : ""}`}
                name="poa_mandatory"
                checked={propertyData["poa_mandatory"] === "Yes"}
                onChange={handleChangeCheck}
              />
            </label>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-5">
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_fees") ? "text-error" : ""}`}
                  >
                    POA Fees
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="poa_fees"
                  value={propertyData["poa_fees"] || ""}
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_term") ? "text-error" : ""}`}
                  >
                    POA Term
                  </span>
                </div>
                <select
                  className="select select-bordered"
                  name="poa_term"
                  value={propertyData["poa_term"] || ""}
                  onChange={handleChange}
                >
                  <option disabled value="default" hidden></option>
                  <option></option>
                  <option>Monthly</option>
                  <option>Semi-Annually</option>
                  <option>Annually</option>
                </select>
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_tansfer_fee") ? "text-error" : ""}`}
                  >
                    POA Transfer Fee
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="poa_transfer_fee"
                  value={propertyData["poa_transfer_fee"]}
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_name") ? "text-error" : ""}`}
                  >
                    POA Name
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="poa_name"
                  value={propertyData["poa_name"] || ""}
                  onChange={handleChange}
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_email_address") ? "text-error" : ""}`}
                  >
                    POA Email Address
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="poa_email_address"
                  value={propertyData["poa_email_address"] || ""}
                  onChange={handleChange}
                />
              </label>

              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_phone") ? "text-error" : ""}`}
                  >
                    POA Phone
                  </span>
                </div>
                <PatternFormat
                  className="input input-bordered w-full"
                  format="+1 (###) ### ####"
                  name="poa_phone"
                  value={user?.phone_number}
                  onChange={(e) => handleChange(e)}
                  allowEmptyFormatting
                  mask="_"
                />
              </label>
              <label className="form-control w-full col-span-1">
                <div className="label">
                  <span
                    className={`label-text ${poaErrors.includes("poa_website") ? "text-error" : ""}`}
                  >
                    POA Website
                  </span>
                </div>
                <input
                  type="text"
                  className="input input-bordered w-full "
                  name="poa_website"
                  value={propertyData["poa_website"] || ""}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Details;
