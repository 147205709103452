import { useEffect, useState } from "react";
import ProfessionalPreview from "../professionals/preview.js";
import { profileAdvertisements } from "../../actions/advertisements/profileAdvertisements.js";
const ProfessionalSection = () => {
  const [promoted, setPromoted] = useState(null);
  useEffect(() => {
    profileAdvertisements({ setPromoted });
  }, []);
  console.log(promoted);
  return (
    <section className="space-y-[20px]">
      <span className="">
        <div>
          <h2 className="text-[24px] font-medium">Promoted Professionals</h2>
          <p className="text-gray-500 text-[13px]">
            Recommended Professionals In Our Network
          </p>
        </div>
      </span>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 h-fit w-full">
        {promoted &&
          promoted.length > 0 &&
          promoted.map((user, index) => (
            <ProfessionalPreview key={index} user={user} />
          ))}
      </div>
    </section>
  );
};

export default ProfessionalSection;
