import { HOST } from "../constants/HostConstants";

export const userByIdPreview = async (user_id, { setPoster }) => {
  fetch(`${HOST}/api/user/getUserByIdPreview`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setPoster(data[0]);
    });
};

export const userById = async (user_id, { setPoster }) => {
  await fetch(`${HOST}/api/user/getUserById`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setPoster({ ...data[0], user_id: user_id });
    });
};
