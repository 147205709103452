import { HOST } from "../constants/HostConstants";

export const advertisementPending = async ({ setPending }) => {
  fetch(`${HOST}/api/advertisement/advertisementPending`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setPending(data);
    });
};
