import { HOST } from "../../constants/HostConstants";

export const transactionUpdate = async (amount, { setUpdated }) => {
  const response = await fetch(`${HOST}/api/transaction/transactionUpdate`, {
    method: "POST",
    body: JSON.stringify({
      amount,
      token: localStorage.getItem("token"),
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  const data = response;
  if (!data.success) {
    throw new Error("Failed");
  } else if (data.user) {
    setUpdated(true);
  }
  return data;
};
