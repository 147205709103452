import { HOST } from "../../constants/HostConstants";

export const transactionStakeRequest = async (
  user_id,
  first_name,
  last_name,
  email_address,
  amount,
  duration,
  percentage,
  { setUser },
) => {
  const response = await fetch(
    `${HOST}/api/transaction/transactionStakeRequest`,
    {
      method: "POST",
      body: JSON.stringify({
        user_id,
        first_name,
        last_name,
        email_address,
        amount,
        duration,
        percentage,
      }),
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    },
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  const data = response;
  if (!data.success) {
    throw new Error(`Stake request not sent`);
  } else if (data.user) {
    setUser(data.user);
  }
  return data;
};
