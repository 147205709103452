import { HOST } from "../constants/HostConstants"
export const filterUsers = async (filter, setAll) => {
    fetch(`${HOST}/api/user/filterUsers`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            filter
        }),
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
        }
    }).then((res)=> res.json()).then(data=> {
      console.log(data)
        setAll(data)
    })
}
