import {
  Mail,
  Facebook,
  KeyRound,
  Phone,
  X,
  Twitter,
  Instagram,
  Vault,
} from "lucide-react";
import { useEffect, useState } from "react";
import { getUserStakeAmount } from "../../actions/user/getUserStakeAmount";

import ReactPlayer from "react-player";
import { userUpdateProfile } from "../../actions/userUpdateProfile";

import { FaTiktok } from "react-icons/fa";
import { addCommasToPrice } from "../../helpers/conversions";
const ProfileTab = ({ user, setUser }) => {
  const [stake, setStake] = useState(null);
  localStorage.removeItem("tab");
  const [uploadedItems, setUploadedItems] = useState([]);
  useEffect(() => {
    userUpdateProfile(user, uploadedItems, setUser);
  }, [uploadedItems]);
  console.log(user);

  useEffect(() => {
    const fetchStakeAmount = async () => {
      await getUserStakeAmount(user?.user_id, { setStake });
    };
    fetchStakeAmount();
  }, []);

  return (
    <>
      <div className="border-t border-base-300 flex lg:flex-row flex-col-reverse px-[24px]">
        <div className="my-5 flex flex-[0.7] flex-col gap-10">
          <div className="space-y-5">
            <h3 className="text-19 font-medium">User Information</h3>

            {user.class_type === "Broker / Owner" && (
              <div className="pl-2">
                <div className="flex gap-2 items-center">
                  <h4>Brokerage Name -</h4>
                  <p className="text-sm">
                    {user?.brokerage_name
                      ? user?.brokerage_name
                      : "No Brokerage Name Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Brokage License # -</h4>

                  <p className="text-sm">
                    {user?.brokerage_number
                      ? user?.brokerage_number
                      : "No Brokerage ID Found"}
                  </p>
                </div>
              </div>
            )}
            {user.class_type === "Broker / Associate" && (
              <div className="space-y-2 pl-2">
                <div className="flex gap-2 items-center">
                  <h4>Brokerage Name -</h4>
                  <p className="text-sm">
                    {user?.brokerage_name
                      ? user?.brokerage_name
                      : "No Brokerage Name Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Brokerage License # -</h4>
                  <p className="text-sm">
                    {user?.brokerage_number
                      ? user?.brokerage_number
                      : "No Brokerage ID Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Broker Name -</h4>
                  <p className="text-sm">
                    {user?.broker_name
                      ? user?.broker_name
                      : "No Broker Name Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Broker License # -</h4>
                  <p className="text-sm">
                    {user?.broker_number
                      ? user?.broker_number
                      : "No Broker ID Found"}
                  </p>
                </div>
              </div>
            )}
            {(user?.class_type === "REALTOR" ||
              user?.class_type === "Realtor") && (
              <div className="space-y-2 pl-2">
                <div className="flex gap-2 items-center">
                  <h4>Brokerage Name -</h4>
                  <p className="text-sm">
                    {user?.brokerage_name
                      ? user?.brokerage_name
                      : "No Brokerage Name Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Brokerage License # -</h4>
                  <p className="text-sm">
                    {user?.brokerage_number
                      ? user?.brokerage_number
                      : "No Brokage ID Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Broker Name -</h4>
                  <p className="text-sm">
                    {user?.broker_name
                      ? user?.broker_name
                      : "No Broker Name Found"}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <h4>Broker License # -</h4>
                  <p className="text-sm">
                    {user?.broker_number
                      ? user?.broker_number
                      : "No Broker ID Found"}
                  </p>
                </div>
              </div>
            )}

            <div className="space-y-2 pl-2">
              <h4>Contact Information</h4>
              <div className="flex gap-3">
                <button className="btn btn-md">
                  <Phone />
                  {user?.phone_number ? user?.phone_number : "No Number Found"}
                </button>
                <button className="btn btn-md">
                  <Mail />
                  {user?.email_address ? user?.email_address : "No Email Found"}
                </button>
              </div>
            </div>
            <div className="space-y-2 pl-2">
              <h4>Socials</h4>
              {user?.facebook ||
              user?.instagram ||
              user?.tiktok ||
              user?.twitter ? (
                <div className="flex gap-3">
                  {user?.facebook && (
                    <a
                      href={user?.facebook}
                      target="_blank"
                      className="btn btn-outline btn-sm"
                      rel="noreferrer"
                    >
                      <Facebook size={18} />
                      Facebook
                    </a>
                  )}
                  {user?.twitter && (
                    <a
                      href={user?.twitter}
                      target="_blank"
                      className="btn btn-outline btn-sm"
                      rel="noreferrer"
                    >
                      <Twitter /> Twitter
                    </a>
                  )}
                  {user?.instagram && (
                    <a
                      href={user?.instagram}
                      target="_blank"
                      className="btn btn-outline btn-sm"
                      rel="noreferrer"
                    >
                      <Instagram size={18} />
                      Instagram
                    </a>
                  )}
                  {user?.tiktok && (
                    <a
                      href={user?.tiktok}
                      target="_blank"
                      className="btn btn-outline btn-sm"
                      rel="noreferrer"
                    >
                      <FaTiktok size={18} />
                      Tiktok
                    </a>
                  )}
                </div>
              ) : (
                <p className="text-sm">No Socials Listed</p>
              )}
            </div>
          </div>
          <div className="space-y-5">
            <h3 className="text-19 font-medium">About</h3>
            <p className="pl-2">{user?.about ? user.about : "Not set"}</p>
          </div>
          <div className="space-y-5">
            <h3 className="text-19 font-medium">Media</h3>
            {user?.video ? (
              <ReactPlayer
                className="pl-2"
                width="60%"
                height="100%"
                url={user?.video}
                controls
              />
            ) : (
              <p className="pl-2">No Media Set</p>
            )}
          </div>
        </div>
        <div className="flex-[0.3] flex flex-col gap-5 my-5">
          <div className="border rounded-md border-base-300 p-3 space-y-2">
            <h3 className="text-[19px] font-medium mb-4">Current Balance</h3>
            <p className="text-center flex gap-2">
              <KeyRound />
              {user?.key_balance ? addCommasToPrice(user?.key_balance) : ""}
            </p>
            <p className="text-center flex gap-2">
              <Vault />
              {stake ? addCommasToPrice(stake) : "0"}
            </p>
            <hr />
            <div className="flex justify-between flex-wrap">
              <p>Total Keys</p>

              {user?.key_balance && stake
                ? addCommasToPrice(user?.key_balance + stake)
                : addCommasToPrice(user?.key_balance)}
            </div>

            <div className="flex justify-end">
              <a href="/store" className="btn btn-primary btn-xs">
                Go To Store
              </a>
            </div>
          </div>
          {/* <div className="border rounded-md border-base-300 p-3"> */}
          {/*   <h3 className="text-[19px] font-medium">Stats</h3> */}
          {/*   <p className="text-center flex gap-2 items-center justify-center"> */}
          {/*     <KeyRound /> */}
          {/*     500 */}
          {/*   </p> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ProfileTab;
