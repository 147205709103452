import { HOST } from "../../../constants/HostConstants";
export const propertyStatusUpdate = async (
  property_id,
  property_status,
  user_id,
) => {
  const response = await fetch(`${HOST}/api/property/propertyStatusUpdate`, {
    method: "POST",
    body: JSON.stringify({
      property_id: property_id,
      property_status: property_status,
      user_id: user_id,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Property Status Not Changed");
  } else {
    return response;
  }
};
