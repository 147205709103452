import { HOST } from "../constants/HostConstants";
export const propertiesRecent = async ({ setRecent }) => {
  fetch(`${HOST}/api/property/recent`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      setRecent(data);
    });
};

