import { HOST } from "../constants/HostConstants";
export const propertyUpdate = async (propertyData, user, uploadedItems) => {
  console.log(propertyData);
  const response = await fetch(`${HOST}/api/property/propertyUpdate`, {
    method: "POST",
    body: JSON.stringify({
      propertyData: propertyData,
      user_id: user.user_id,
      uploadedItems,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (!response.success) {
    throw new Error("Property Update Failed");
  }

  const data = response;

  if (data.property_id) {
    console.log(data.property_id);
    return data.property_id;
  }
};
