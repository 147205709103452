import { useContext, useEffect } from "react";
import ProfileView from "../components/ProfileView";

import { UserContext } from "../context/MainContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user, setUser } = useContext(UserContext);
  const nav = useNavigate();
  useEffect(() => {
    if (user === false) {
      nav("/");
    }
  }, [user]);
  return <>{user && <ProfileView user={user} setUser={setUser} />}</>;
};

export default Profile;
