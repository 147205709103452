import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { propertyById } from "../actions/propertyById";
import { userByIdPreview } from "../actions/userById";
import { advertiseGrab } from "../actions/advertiseGrab";
import { commentCreate } from "../actions/commentCreate";
import { UserContext } from "../context/MainContext";
import { KeyRound, Mail, Phone, MapPin, Share, Bed } from "lucide-react";
import VideoPlayer from "../components/VideoPlayer";
import Features from "../components/properties/features";
import Contract from "../components/properties/contract";
import Preferred from "../components/properties/preferred";
import {
  addCommasToPrice,
  calculateDaysSince,
  extractStateAbbreviation,
} from "../helpers/conversions";
import { propertyImpressions } from "../actions/propertyImpression";
import { profileAdvertisements } from "../actions/advertisements/profileAdvertisements";
import ProfessionalPreview from "../components/ProfessionalPreview";
import Footer from "../components/Footer";
import toast from "react-hot-toast";
import {
  addBathrooms,
  addBedrooms,
  addLivingSqFt,
  calculateCurrentCapRate,
  calculateGrossIncome,
  calculatePropertyManagementFee,
  calculateTotalNetIncome,
  roundToTwoDecimals,
} from "../helpers/math";
import Closing from "../components/properties/closing";

const Property = () => {
  const { id } = useParams();
  const { user, setUser } = useContext(UserContext);
  const [properties, setProperties] = useState(null);
  const [image, setImage] = useState(null);
  const [poster, setPoster] = useState(null);
  const [advertisement, setAdvertisement] = useState(null);
  const [promoted, setPromoted] = useState(null);
  const [lenderFound, setLenderFound] = useState(true);
  const [titleCloserFound, setTitleCloserFound] = useState(true);
  const nav = useNavigate();

  console.log(properties);
  useEffect(() => {
    propertyById({ setProperties }, id);
  }, []);

  useEffect(() => {
    if (!properties) return;
    if (properties) {
      // From the returned media_urls of the property, set the first one to be the main image on initial load
      if (properties?.media_url) {
        setImage(
          `https://res.cloudinary.com/diur0igkv/image/upload/${properties?.media_url.split(",")[0]}`,
        );
      }
      // Grab user who posted this property information
      userByIdPreview(properties?.user_id, { setPoster });
      advertiseGrab("Horizontal", setAdvertisement);

      profileAdvertisements({ setPromoted });
      // Check if the user has already incremented the impression within the last 24 hours
      const storedData = localStorage.getItem(
        `property_${properties?.property_id}`,
      );

      if (storedData) {
        const { lastImpressionTime } = JSON.parse(storedData);
        const now = new Date().getTime();
        const timeDiff = now - lastImpressionTime;
        if (timeDiff <= 24 * 60 * 60 * 1000) {
          return;
        }
      } else {
        propertyImpressions(properties?.property_id, { setProperties });
        // Update localStorage with the new impression count and timestamp
        const now = new Date().getTime();
        localStorage.setItem(
          `property_${properties?.property_id}`,
          JSON.stringify({ lastImpressionTime: now }),
        );
      }

      // advertiseProfile(setProfiles)
    }
  }, [properties]);
  const items = [];
  if (properties && properties?.media_url) {
    properties?.media_url.split(",").forEach((property, index) => {
      items.push(
        `https://res.cloudinary.com/diur0igkv/image/upload/${property}`,
      );
    });
  }

  function handleCopy() {
    navigator.clipboard.writeText(`https://myllave.com/property/${id}`);
    toast.success("Copied URL to clipboard");
    document.getElementById("my_modal_14").close();
  }

  return (
    <>
      <dialog id="my_modal_17" className="modal !z-[20]">
        <div className="modal-box space-y-3">
          <h3 className="font-bold text-lg">All Property Images</h3>
          <hr />
          <div className="relative w-full grid grid-cols-2 gap-5">
            {properties?.media_url.split(",").map((url, index) => (
              <button
                className=""
                onClick={() => {
                  setImage(
                    `https://res.cloudinary.com/diur0igkv/image/upload/${url}`,
                  );
                  document.getElementById("my_modal_17").close();
                }}
              >
                <img
                  className=""
                  src={`https://res.cloudinary.com/diur0igkv/image/upload/${url}`}
                  alt={`property ${index}`}
                />
              </button>
            ))}
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <dialog id="my_modal_14" className="modal !z-[20]">
        <div className="modal-box space-y-3">
          <h3 className="font-bold text-lg">Share this property</h3>
          <hr />
          <form method="dialog" className="flex gap-5">
            <a
              className="btn btn-default"
              href={`mailto:?subject=Check out this property at MyLlave! ${properties?.street_number} ${properties?.dir_prefix ? properties?.dir_prefix + " " : ""}${properties?.street_name} ${properties?.street_type ? properties?.street_type + " " : ""}${properties?.unit_number ? ", Unit # " + properties?.unit_number : ""}, ${properties?.city}, ${properties?.state ? extractStateAbbreviation(properties?.state) : ""}, ${properties?.zip}`}
            >
              <Mail />
            </a>
          </form>
          <div className="relative w-full ">
            <div className="input input-bordered pt-[8px]">
              https://myllave.com/property/{id}
            </div>
            <button
              className="btn btn-primary absolute right-2 bottom-2 btn-sm"
              onClick={handleCopy}
            >
              Copy
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <main className="space-y-3 max-w-[1440px] mx-auto my-12 px-[24px] min-h-screen">
        <div className="flex justify-between items-start flex-wrap gap-5">
          <div className="space-y-2">
            <div className="flex gap-2 items-center">
              <div
                className={`rounded-full ${properties?.property_status === "Leased" || properties?.property_status === "Sold" ? "bg-error" : "bg-success animate-pulse"} w-[10px] h-[10px]`}
              ></div>
              {properties?.property_status === "Leased" ||
              properties?.property_status === "Sold" ? (
                <div>{properties?.property_status}</div>
              ) : (
                <div>For {properties?.for}</div>
              )}
            </div>

            <div className="flex flex-col">
              <div className="flex gap-2">
                <div className="flex items-center gap-2">
                  <MapPin />
                  <p className="text-19">
                    {properties?.street_number} {properties?.dir_prefix}{" "}
                    {properties?.street_name}{" "}
                    {properties?.street_type
                      ? " " + properties?.street_type
                      : ""}{" "}
                    {properties?.unit_number
                      ? ", Unit # " + properties?.unit_number
                      : ""}{" "}
                    , {properties?.city},{" "}
                    {properties?.state
                      ? extractStateAbbreviation(properties?.state)
                      : ""}
                    , {properties?.zip}
                  </p>
                </div>
              </div>
              <div className="flex gap-2 flex-wrap mt-2 text-gray-700">
                {properties?.vacant_land === "No" &&
                  (((properties?.sub_type === "Duplex" ||
                    properties?.sub_type === "Fourplex") &&
                    properties?.unit_details && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {addBedrooms(properties.unit_details)}
                        </h4>
                        <p className="mb-[1px]">Beds</p>
                      </div>
                    )) ||
                    (properties?.beds_total && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {properties?.beds_total}
                        </h4>
                        <p className="mb-[1px]">Beds</p>
                      </div>
                    )))}
                {properties?.vacant_land === "No" &&
                  (((properties?.sub_type === "Duplex" ||
                    properties?.sub_type === "Fourplex") &&
                    properties?.unit_details && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {addBathrooms(properties.unit_details)}
                        </h4>
                        <p className="mb-[1px]">Baths</p>
                      </div>
                    )) ||
                    (properties?.baths_full && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {properties?.baths_full}
                        </h4>
                        <p className="mb-[1px]">Baths</p>
                      </div>
                    )))}
                {properties?.vacant_land === "No" &&
                  (((properties?.sub_type === "Duplex" ||
                    properties?.sub_type === "Fourplex") &&
                    properties?.unit_details && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {addLivingSqFt(properties.unit_details)}
                        </h4>
                        <p className="mb-[1px]">Living Sq Ft</p>
                      </div>
                    )) ||
                    (properties?.living_sqft && (
                      <div className="flex items-center gap-1">
                        <h4 className="font-medium">
                          {properties?.living_sqft}
                        </h4>
                        <p className="mb-[1px]">Living Sq Ft</p>
                      </div>
                    )))}
                {properties?.acreage && (
                  <div className="flex items-center gap-1">
                    <h4 className="font-medium">{properties?.acreage}</h4>
                    <p className="mb-[1px]">Acres</p>
                  </div>
                )}
                {properties?.available_date && (
                  <div className="flex items-center gap-1">
                    <p className="mb-[1px]">
                      Available {properties?.available_date}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-sm">List Price</h2>
            <div className="flex items-end gap-1">
              <h3 className="text-2xl md:text-4xl">
                {properties?.list_price
                  ? `$${addCommasToPrice(properties?.list_price)}`
                  : "?"}
              </h3>
              <div className="flex flex-col">
                <p className="text-[12px]">
                  {properties?.for === "Lease" ? "/ Month" : ""}
                </p>
                <p className="mb-[4px] text-[12px]">
                  {properties?.vacant_land === "No" && properties?.living_sqft
                    ? `($${roundToTwoDecimals(properties?.list_price, properties?.living_sqft, properties?.unit_1_living_sq_ft, properties?.unit_2_living_sq_ft, properties?.unit_3_living_sq_ft, properties?.unit_4_living_sq_ft)} / Living Sq. Ft.)`
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between flex-wrap gap-2 w-full">
          <div className="space-y-0 flex items-center flex-wrap gap-2">
            <h5 className="text-xs">Quick Navigate &gt; </h5>
            <div className="text-[14px] flex flex-wrap gap-2">
              <a href="#details" className="link link-hover">
                Details
              </a>
              <a href="#features" className="link link-hover">
                Features
              </a>
              <a href="#contract" className="link link-hover">
                Contract Info
              </a>
            </div>
          </div>
          <button
            className="btn btn-primary btn-outline btn-xs"
            onClick={() => document.getElementById("my_modal_14").showModal()}
          >
            <Share size={16} /> Share Property
          </button>
        </div>

        <div className="flex md:flex-row md:max-h-[400px] flex-col gap-5">
          <div className={`flex-[0.7] relative min-h-[257px]`}>
            {image && properties ? (
              <img
                className="w-full h-full object-cover absolute"
                src={image}
                alt="viewable main"
              />
            ) : (
              <div className="w-full bg-base-200 flex justify-center items-center md:h-[400px]">
                {
                  properties ? "Image Not Uploaded" : "Loading..." // Display loading indicator while properties are not filled
                }
              </div>
            )}
          </div>{" "}
          <div
            className={`flex-[0.3] grid grid-rows-2 md:grid-rows-3 grid-cols-3 md:grid-cols-2 gap-5 relative  `}
          >
            {properties?.media_url &&
              properties?.media_url.split(",").map(
                (url, index) =>
                  index <= 4 && (
                    <div
                      className="relative hover:scale-[1.01] cursor-pointer"
                      onClick={() =>
                        setImage(
                          `https://res.cloudinary.com/diur0igkv/image/upload/${url}`,
                        )
                      }
                    >
                      <img
                        className="h-full w-full object-cover"
                        src={`https://res.cloudinary.com/diur0igkv/image/upload/${url}`}
                        alt="viewable"
                      />
                    </div>
                  ),
              )}
            {properties?.media_url.split(",").length > 4 && (
              <button
                className="btn btn-outline !h-full"
                onClick={() =>
                  document.getElementById("my_modal_17").showModal()
                }
              >
                + {properties?.media_url.split(",").length - 4} Photos
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            {properties?.type === "Residential" &&
              properties?.vacant_land === "No" && (
                <div className="">Residential Property</div>
              )}

            {properties?.vacant_land === "Yes" &&
              properties?.type === "Residential" && (
                <div className="">Residential - Vacant Land</div>
              )}
            {properties?.vacant_land === "Yes" &&
              properties?.type === "Commercial" && (
                <div className="">Commercial - Vacant Land</div>
              )}
          </div>
          <div className="text-sm flex h-fit gap-3">
            <span>
              {" "}
              {calculateDaysSince(properties?.listed_date)} Days On Market
            </span>
            <div className="border-l border-neutral-content"></div>
            <span>
              {" "}
              {properties?.impressions > 0 || properties?.impressions === 0
                ? properties?.impressions
                : "0"}{" "}
              Impressions
            </span>
            <div className="border-l border-neutral-content"></div>
            <div className="flex items-center gap-1 text-sm">
              <KeyRound size={16} />
              {properties?.property_id}
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col-reverse gap-5">
          <div className="flex-[0.6] overflow-hidden w-full space-y-5">
            {(properties?.property_status === "Leased" ||
              properties?.property_status === "Sold") && (
              <Closing properties={properties} />
            )}
            <div className="p-4 shadow-md shadow-black/5 border border-base-300 gap-5 relative  space-y-2 rounded-lg">
              {properties?.public_remarks && (
                <div className="py-5 space-y-2">
                  <h4 className="font-medium">Remarks</h4>
                  <hr className="pb-2" />
                  {properties?.public_remarks}
                </div>
              )}
            </div>
            <div className="w-full lg:hidden block">
              <div className="p-4 shadow-md shadow-black/5 border border-base-300 gap-5 relative  space-y-2 rounded-lg">
                <div
                  className="flex gap-2 cursor-pointer p-2 hover:bg-base-200 rounded-lg border-transparent border hover:border-base-300"
                  onClick={() => nav(`/user/${poster?.user_id}`)}
                >
                  <div className="w-[65px] h-[65px] relative">
                    <img
                      className="rounded-full absolute object-cover h-full"
                      src={
                        poster?.picture_url
                          ? `https://res.cloudinary.com/diur0igkv/image/upload/${poster?.picture_url}`
                          : "/error.png"
                      }
                      alt="property poster"
                    />
                  </div>
                  <div className="">
                    <div>
                      {poster?.legal_first_name} {poster?.legal_last_name}
                    </div>
                    <div className="text-sm">
                      {poster?.class_type !== "Consumer" ? (
                        poster?.class_type === "REALTOR" ? (
                          <div className="flex md:flex-row flex-col">
                            <div className="space-x-1 flex">
                              <p>{poster?.class_type} at </p>
                              <a
                                href=""
                                className="link link-hover link-primary ml-1"
                              >
                                {poster?.brokerage_name}
                              </a>
                            </div>
                          </div>
                        ) : poster?.class_type === "Broker / Owner" ? (
                          <div className="flex md:flex-row flex-col">
                            <div className="space-x-1 flex">
                              <p>{poster?.class_type} of </p>
                              <a
                                href=""
                                className="link link-hover link-primary ml-1"
                              >
                                {poster?.brokerage_name}
                              </a>
                            </div>
                          </div>
                        ) : poster?.class_type === "Broker / Associate" ? (
                          <div className="flex md:flex-row flex-col">
                            <div className="space-x-1 flex">
                              <p>{poster?.class_type} at </p>
                              <a
                                href=""
                                className="link link-hover link-primary ml-1"
                              >
                                {poster?.brokerage_name}
                              </a>
                            </div>
                          </div>
                        ) : poster?.class_type === "Builder" ? (
                          <div className="flex flex-wrap">
                            <div className="space-x-1 flex">
                              <p>{poster?.position} at </p>
                              <a
                                href=""
                                className="link link-hover link-primary ml-1"
                              >
                                {poster?.builder_company_name}
                              </a>
                            </div>
                          </div>
                        ) : // Continue checking for other class_types
                        poster?.class_type === "Lender" ? (
                          <div className="flex md:flex-row flex-col">
                            <div className="space-x-1 flex">
                              <p>{poster?.class_type} at </p>
                              <a
                                href=""
                                className="link link-primary link-hover ml-1"
                              >
                                {poster?.lending_company_name}
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        // If user is a consumer (reg account)
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <h4 className="">Schedule A Showing</h4>
                  <div className="flex gap-5">
                    <a
                      href={`mailto:${poster?.email_address}?subject=I would like to schedule a showing for ${properties?.street_number || ""} ${properties?.dir_prefix || ""} ${properties?.street_name || ""}, ${properties?.city || ""}, TX ${properties?.zip || ""}`}
                      className="btn btn-outline btn-sm flex-[1]"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Mail />
                      Message
                    </a>
                    {/* <button className="btn btn-outline btn-sm flex-[0.5]"> */}
                    {/*   <Phone /> */}
                    {/*   Call */}
                    {/* </button> */}
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  {properties?.lender_used && lenderFound && (
                    <div className="space-y-1 flex-[0.5] w-fit">
                      <h3 className="text-sm">Preferred Lender</h3>
                      <Preferred
                        preferred={properties?.lender_used}
                        type={"Lender"}
                        setLenderFound={setLenderFound}
                        setTitleCloserFound={null}
                      />
                    </div>
                  )}
                  {properties?.title_closer_used && titleCloserFound && (
                    <div className="space-y-1 flex-[0.5] w-fit">
                      <h3 className="text-sm">Preferred Escrow Officer</h3>
                      <Preferred
                        preferred={properties?.title_closer_used}
                        type={"Title Closer"}
                        setLenderFound={null}
                        setTitleCloserFound={setTitleCloserFound}
                      />
                    </div>
                  )}
                </div>
              </div>
              {advertisement && (
                <div className="pt-3">
                  <a
                    href={
                      advertisement?.redirect_type === "External"
                        ? advertisement?.redirect
                        : `/user/${advertisement?.user_id}`
                    }
                    target="_blank"
                    className="w-full"
                    rel="noreferrer"
                  >
                    <img
                      className="w-full"
                      src={`https://res.cloudinary.com/diur0igkv/image/upload${advertisement?.advertisement_url}`}
                      alt="horizontal ad placement"
                    />
                  </a>
                </div>
              )}

              {properties?.embeded_url && (
                <div className="mx-auto ">
                  <VideoPlayer urls={properties?.embeded_url} />
                </div>
              )}
            </div>

            <div
              className="p-4 shadow-md shadow-black/5 border border-base-300 gap-5 relative  space-y-2 rounded-lg"
              id="details"
            >
              <h4 className="font-medium">Property Details</h4>
              <div className="p-4 space-y-2">
                <h5>General</h5>
                <div className="grid grid-cols-3 gap-3">
                  {properties?.vacant_land === "Yes" && (
                    <div className="col-span-1 bg-white shadow-md shadow-black/5 border border-base-300  px-2 rounded-md">
                      <h4 className="text-19 font-medium">
                        {properties?.taxes_no_exemption
                          ? `$${addCommasToPrice(properties?.taxes_no_exemption)}`
                          : ""}
                      </h4>
                      <p className="mb-[1px] text-sm">
                        Property Taxes w/o Exemption
                      </p>
                    </div>
                  )}
                  {properties?.pool === "Yes" && (
                    <div className="col-span-1 shadow-md shadow-black/5 border border-base-300 px-2 rounded-md">
                      <h4 className="text-19 font-medium">
                        {properties?.pool}
                      </h4>
                      <p className="mb-[1px] text-sm">Pool</p>
                    </div>
                  )}
                  {properties?.vacant_land === "No" &&
                    properties?.est_year_built && (
                      <div className="col-span-1 shadow-md shadow-black/5 border border-base-300 px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Year Built</p>
                        <h4 className="text-19 font-medium">
                          {properties?.est_year_built || "?"}
                        </h4>
                      </div>
                    )}
                  {properties?.for !== "Lease" && properties?.tax_year && (
                    <div className="col-span-1 shadow-md shadow-black/5 border border-base-300 px-2 rounded-md">
                      <p className="mb-[1px] text-sm">Tax Year</p>
                      <h4 className="text-19 font-medium">
                        {properties?.tax_year || "?"}
                      </h4>
                    </div>
                  )}
                  {properties?.vacant_land === "No" &&
                    (((properties?.sub_type === "Duplex" ||
                      properties?.sub_type === "Fourplex") && (
                      <div className="col-span-1 shadow-md shadow-black/5 border border-base-300 px-2 rounded-md">
                        <p className="mb-[1px]">Living Sq. Ft.</p>
                        <h4 className="text-19 font-medium">
                          {addLivingSqFt(properties?.unit_details)}
                        </h4>
                      </div>
                    )) ||
                      (properties?.living_sqft && (
                        <div className="col-span-1 shadow-md shadow-black/5 border border-base-300 px-2 rounded-md">
                          <p className="!mt-[1px] text-sm">Living Sq Ft</p>
                          <h4 className="text-19 font-medium">
                            {properties?.living_sqft}
                          </h4>
                        </div>
                      )))}{" "}
                  {properties?.acreage && (
                    <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                      <p className="mb-[1px] text-sm">Lot Sq Ft</p>
                      <h4 className="text-19 font-medium">
                        {addCommasToPrice(
                          Math.round(Number(properties?.acreage) * 43560),
                        ) || "?"}
                      </h4>
                    </div>
                  )}
                  {properties?.vacant_land === "No" &&
                    properties?.garage_spaces !== "0" &&
                    properties?.garage_spaces && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">Garage Spaces</p>
                        <h4 className="text-19 font-medium">
                          {properties?.garage_spaces || "?"}
                        </h4>
                      </div>
                    )}
                  {properties?.vacant_land === "No" &&
                    properties?.carport_spaces !== "0" &&
                    properties?.carport_spaces && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">Carport Spaces</p>
                        <h4 className="text-19 font-medium">
                          {properties?.carport_spaces || "?"}
                        </h4>
                      </div>
                    )}
                  {(properties?.vacant_land === "Yes" ||
                    properties?.for === "Sale") &&
                    properties?.poa === "Yes" && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">POA</p>
                        <h4 className="text-19 font-medium">
                          {properties?.poa || "?"}
                        </h4>
                      </div>
                    )}
                  {properties?.ada_accessible === "Yes" && (
                    <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                      <p className="mb-[1px]">ADA Accessible</p>
                      <h4 className="text-19 font-medium">Yes</h4>
                    </div>
                  )}
                  {properties?.subdivision && (
                    <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                      <p className="mb-[1px]">Subdivision</p>
                      <h4 className="text-19 font-medium">
                        {properties?.subdivision}
                      </h4>
                    </div>
                  )}
                  {properties?.stories && (
                    <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                      <p className="mb-[1px]">Stories</p>
                      <h4 className="text-19 font-medium">
                        {properties?.stories}
                      </h4>
                    </div>
                  )}
                </div>
              </div>
              {(properties?.school_district ||
                properties?.elementary_school ||
                properties?.middle_school ||
                properties?.high_school) && (
                <div className="p-4 space-y-2">
                  <h5>School</h5>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
                    {properties?.school_district && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">School District</p>
                        <h4 className="text-19 font-medium">
                          {properties?.school_district}
                        </h4>
                      </div>
                    )}
                    {properties?.elementary_school && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Elementary School</p>
                        <h4 className="text-19 font-medium">
                          {properties?.elementary_school}
                        </h4>
                      </div>
                    )}
                    {properties?.middle_school && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Middle School</p>
                        <h4 className="text-19 font-medium">
                          {properties?.middle_school}
                        </h4>
                      </div>
                    )}
                    {properties?.high_school && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">High School</p>
                        <h4 className="text-19 font-medium">
                          {properties?.high_school}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {properties?.for === "Lease" && (
                <div className="p-4 space-y-2">
                  <h5>Lease</h5>
                  <div className="grid grid-cols-2 md:grid-cols-2 gap-5">
                    {properties?.application_fee && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Application Fee</p>
                        <h4 className="text-19 font-medium">
                          {properties?.application_fee}
                        </h4>
                      </div>
                    )}
                    {properties?.contact_phone && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">
                          Contact Landlord / PM
                        </p>
                        <h4 className="text-19 font-medium">
                          {properties?.contact_phone}
                        </h4>
                      </div>
                    )}
                    {properties?.landlord_property_management && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Landlord / PM</p>
                        <h4 className="text-19 font-medium">
                          {properties?.landlord_property_management}
                        </h4>
                      </div>
                    )}
                    {properties?.security_deposit && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Security Deposit</p>
                        <h4 className="text-19 font-medium">
                          {properties?.security_deposit}
                        </h4>
                      </div>
                    )}
                    {properties?.pets_allowed && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Pets Allowed</p>
                        <h4 className="text-19 font-medium">
                          {properties?.pets_allowed}
                        </h4>
                      </div>
                    )}

                    {properties?.termLength && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Term Length</p>
                        <h4 className="text-19 font-medium">
                          {properties?.termLength.join()}
                        </h4>
                      </div>
                    )}
                    {properties?.tenantPay &&
                      properties?.tenantPay.length > 0 && (
                        <div className="space-y-2 col-span-2 mt-0 overflow-x-auto w-full">
                          <h3 className="text-14 font-medium">
                            Tenant Responsible For
                          </h3>
                          <hr />
                          <ul className="text-sm list-disc pl-4">
                            {properties?.tenantPay.map((feature) => (
                              <li>{feature}</li>
                            ))}
                          </ul>{" "}
                        </div>
                      )}
                  </div>
                </div>
              )}

              {(properties?.office_sqft ||
                properties?.warehouse_sqft ||
                properties?.retail_sqft ||
                properties?.other_bldg_sqft ||
                properties?.total_sqft) && (
                <div className="p-4 space-y-2">
                  <h5>Sq Ft Details</h5>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
                    {properties?.office_sqft && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Office Sq Ft</p>
                        <h4 className="text-19 font-medium">
                          {addCommasToPrice(properties?.office_sqft)}
                        </h4>
                      </div>
                    )}
                    {properties?.warehouse_sqft && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Warehouse Sq Ft</p>
                        <h4 className="text-19 font-medium">
                          {addCommasToPrice(properties?.warehouse_sqft)}
                        </h4>
                      </div>
                    )}
                    {properties?.retail_sqft && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Retail Sq Ft</p>
                        <h4 className="text-19 font-medium">
                          {addCommasToPrice(properties?.retail_sqft)}
                        </h4>
                      </div>
                    )}
                    {properties?.total_sqft && (
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px] text-sm">Total Sq Ft</p>
                        <h4 className="text-19 font-medium">
                          {addCommasToPrice(properties?.total_sqft)}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {properties?.unit_details &&
                (properties?.sub_type === "Duplex" ||
                  properties?.sub_type === "Fourplex") && (
                  <div className="p-4 space-y-2">
                    <h5>Unit Details</h5>

                    <div className="overflow-x-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Building #</th>
                            <th>Bedrooms</th>
                            <th>Bathrooms</th>
                            <th>Rented?</th>
                            <th>Rent</th>
                            <th>Living SqFt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {properties?.unit_details &&
                            properties?.unit_details.length > 0 &&
                            properties?.unit_details.map((unit, index) => (
                              <tr>
                                <th>{index + 1}</th>
                                <td>{unit.building_number}</td>
                                <td>{unit.bedrooms}</td>
                                <td>{unit.bathrooms}</td>
                                <td>{unit.rented}</td>
                                <td>{unit.rent}</td>
                                <td>{unit.living_sqft}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="grid grid-cols-4 gap-2 pt-5">
                      <div className="col-span-4 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">Total Gross Income</p>
                        <div className="flex gap-1 items-center">
                          <p>
                            {calculateGrossIncome(
                              properties?.unit_details,
                              true,
                            )}
                          </p>
                          <h4 className="text-19 font-medium">
                            {calculateGrossIncome(
                              properties?.unit_details,
                              false,
                            )}
                          </h4>
                        </div>
                      </div>
                      {properties?.property_management === "Yes" && (
                        <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                          <p className="mb-[1px]">Property Management Fee</p>
                          <h4 className="text-19 font-medium">
                            {calculatePropertyManagementFee(
                              properties?.property_management_fee,
                            )}
                          </h4>
                        </div>
                      )}
                      {properties?.property_maintenance && (
                        <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                          <p className="mb-[1px]">Yearly Maintenance</p>
                          <h4 className="text-19 font-medium">
                            {`$${properties?.property_maintenance && addCommasToPrice(properties?.property_maintenance)}`}
                          </h4>
                        </div>
                      )}
                      {properties?.taxes_no_exemption && (
                        <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                          <p className="mb-[1px]">Yearly Property Taxes</p>
                          <h4 className="text-19 font-medium">{`$${addCommasToPrice(properties?.taxes_no_exemption)}`}</h4>
                        </div>
                      )}
                      <div className="col-span-1 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">Yearly Property Insurance</p>
                        <h4 className="text-19 font-medium">
                          {`$${properties?.property_insurance && addCommasToPrice(properties?.property_insurance)}`}
                        </h4>
                      </div>
                      <div className="col-span-4 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                        <p className="mb-[1px]">Total Net Income</p>
                        <div className="flex gap-1 items-center">
                          <p>
                            {calculateTotalNetIncome(
                              properties?.unit_details,
                              properties?.property_management
                                ? properties?.property_managent_fee
                                : null,
                              properties?.property_maintenance,
                              properties?.taxes_no_exemption,
                              properties?.property_insurance,
                              true,
                            )}
                          </p>
                          <h4 className="text-19 font-medium">
                            {calculateTotalNetIncome(
                              properties?.unit_details,
                              properties?.property_management
                                ? properties?.property_managent_fee
                                : null,
                              properties?.property_maintenance,
                              properties?.taxes_no_exemption,
                              properties?.property_insurance,
                              false,
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 border shadow-md shadow-black/5 border-neutral-content px-2 rounded-md">
                      <p className="mb-[1px]">Current Cap Rate</p>
                      <div className="flex gap-1 items-center">
                        <p>
                          {calculateCurrentCapRate(
                            properties?.list_price,
                            properties?.unit_details,
                            properties?.property_management === "Yes"
                              ? properties?.management_fee
                              : null,
                            properties?.property_maintenance,
                            properties?.taxes_no_exemption,
                            properties?.property_insurance,
                            true,
                          )}
                        </p>
                        <h4 className="text-19 font-medium">
                          {calculateCurrentCapRate(
                            properties?.list_price,
                            properties?.unit_details,
                            properties?.property_management === "Yes"
                              ? properties?.management_fee
                              : null,
                            properties?.property_maintenance,
                            properties?.taxes_no_exemption,
                            properties?.property_insurance,
                            false,
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              {properties?.unit_details &&
                properties?.type === "Commercial" && (
                  <div className="p-4 space-y-2">
                    <h5>Unit Details</h5>
                    <div className="overflow-auto max-h-[400px]">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Unit Number</th>
                            <th>Floor Number</th>
                            <th>Entire Floor</th>
                            <th>Divisible</th>
                            <th>Date Avail</th>
                            <th>Lease Exp Date</th>
                            <th>Sq Ft Total Avail</th>
                            <th>Sq Ft Min Avail</th>
                            <th>Sq Ft Floor Contiguous</th>
                            <th>Space Type</th>
                            <th>Rent Per Sq Ft</th>
                            <th>Rent Services</th>
                            <th>Transaction Type</th>
                            <th>Occupancy</th>
                          </tr>
                        </thead>
                        <tbody>
                          {properties?.unit_details &&
                            properties?.unit_details.length > 0 &&
                            properties?.unit_details.map((unit, index) => (
                              <tr>
                                <th>{index + 1}</th>
                                <td>{unit.unit_number}</td>
                                <td>{unit.floor_number}</td>
                                <td>{unit.entire_floor}</td>
                                <td>{unit.divisible}</td>
                                <td>{unit.date_available}</td>
                                <td>{unit.lease_expiration_date}</td>
                                <td>{unit.sqft_total_available}</td>
                                <td>{unit.sqft_min_available}</td>
                                <td>{unit.sqft_floor_contiguous}</td>
                                <td>{unit.space_type}</td>
                                <td>${addCommasToPrice(unit.rent_per_sqft)}</td>
                                <td>{unit.rent_services}</td>
                                <td>{unit.transaction_type}</td>
                                <td>{unit.occupancy}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
            {promoted && promoted.length > 0 && (
              <div classsName="">
                <h3 className="text-xl mb-4 font-medium">
                  Promoted Professionals
                </h3>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-5">
                  {promoted.map((profile, index) => (
                    <ProfessionalPreview
                      key={index}
                      professional={profile}
                      full={false}
                    />
                  ))}
                </div>
              </div>
            )}

            <div
              className="p-4 shadow-md shadow-black/5 border border-neutral-content/50 gap-5 relative  space-y-2 rounded-lg"
              id="features"
            >
              <h4 className="font-medium">Features</h4>
              <Features properties={properties} />
            </div>
            <div
              className="p-4 shadow-md shadow-black/5 border border-neutral-content/50 gap-5 relative  space-y-2 rounded-lg"
              id="contract"
            >
              <h4 className="font-medium">Contract Information</h4>
              <Contract properties={properties} />
            </div>
          </div>
          <div className="flex-[0.4] w-full lg:block hidden">
            <div className="p-4 shadow-md shadow-black/5 border border-neutral-content/50 gap-5 relative  space-y-2 rounded-lg">
              <div
                className="flex gap-2 cursor-pointer p-2 hover:bg-base-200 rounded-lg border-transparent border hover:border-base-300"
                onClick={() => nav(`/user/${poster?.user_id}`)}
              >
                <div className="w-[65px] h-[65px] relative">
                  <img
                    className="rounded-full absolute object-cover h-full"
                    src={
                      poster?.picture_url
                        ? `https://res.cloudinary.com/diur0igkv/image/upload/${poster?.picture_url}`
                        : "/error.png"
                    }
                    alt="property poster"
                  />
                </div>
                <div className="">
                  <div>
                    {poster?.legal_first_name} {poster?.legal_last_name}
                  </div>
                  <div className="text-sm">
                    {poster?.class_type !== "Consumer" ? (
                      poster?.class_type === "REALTOR" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{poster?.class_type} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {poster?.brokerage_name}
                            </a>
                          </div>
                        </div>
                      ) : poster?.class_type === "Broker / Owner" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{poster?.class_type} of </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {poster?.brokerage_name}
                            </a>
                          </div>
                        </div>
                      ) : poster?.class_type === "Broker / Associate" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{poster?.class_type} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {poster?.brokerage_name}
                            </a>
                          </div>
                        </div>
                      ) : poster?.class_type === "Builder" ? (
                        <div className="flex flex-wrap">
                          <div className="space-x-1 flex">
                            <p>{poster?.position} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {poster?.builder_company_name}
                            </a>
                          </div>
                        </div>
                      ) : // Continue checking for other class_types
                      poster?.class_type === "Lender" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{poster?.class_type} at </p>
                            <a
                              href=""
                              className="link link-primary link-hover ml-1"
                            >
                              {poster?.lending_company_name}
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      // If user is a consumer (reg account)
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h4 className="">Schedule A Showing</h4>
                <div className="flex gap-5">
                  <a
                    href={`mailto:${poster?.email_address}?subject=I would like to schedule a showing for ${properties?.street_number || ""} ${properties?.dir_prefix || ""} ${properties?.street_name || ""}, ${properties?.city || ""}, TX ${properties?.zip || ""}`}
                    className="btn btn-outline btn-sm flex-[1]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Mail />
                    Message
                  </a>
                  {/* <button className="btn btn-outline btn-sm flex-[0.5]"> */}
                  {/*   <Phone /> */}
                  {/*   Call */}
                  {/* </button> */}
                </div>
              </div>
              <div className="flex flex-wrap gap-2">
                {properties?.lender_used && lenderFound && (
                  <div className="space-y-1 flex-[0.5] w-fit">
                    <h3 className="text-sm">Preferred Lender</h3>
                    <Preferred
                      preferred={properties?.lender_used}
                      type={"Lender"}
                      setLenderFound={setLenderFound}
                      setTitleCloserFound={null}
                    />
                  </div>
                )}
                {properties?.title_closer_used && titleCloserFound && (
                  <div className="space-y-1 flex-[0.5] w-fit">
                    <h3 className="text-sm">Preferred Escrow Officer</h3>
                    <Preferred
                      preferred={properties?.title_closer_used}
                      type={"Title Closer"}
                      setLenderFound={null}
                      setTitleCloserFound={setTitleCloserFound}
                    />
                  </div>
                )}
              </div>
            </div>
            {advertisement && (
              <div className="pt-3">
                <a
                  href={
                    advertisement?.redirect_type === "External"
                      ? advertisement?.redirect
                      : `/user/${advertisement?.user_id}`
                  }
                  target="_blank"
                  className="w-full"
                  rel="noreferrer"
                >
                  <img
                    className="w-full"
                    src={`https://res.cloudinary.com/diur0igkv/image/upload${advertisement?.advertisement_url}`}
                    alt="horizontal ad placement"
                  />
                </a>
              </div>
            )}

            {properties?.embeded_url && (
              <div className="mx-auto ">
                <VideoPlayer urls={properties?.embeded_url} />
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Property;
