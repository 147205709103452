import { Filter } from "lucide-react";
import { useEffect, useState } from "react";
import { advertisementByUser } from "../../actions/advertisements/advertisementByUser";
const AdvertisementTab = ({ user, setUser }) => {
  const [advertisements, setAdvertisements] = useState(null);
  const [filter, setFilter] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  localStorage.removeItem("tab");

  useEffect(() => {
    reloadAdvertisements();
  }, []);

  const reloadAdvertisements = async () => {
    setLoading(true);
    await advertisementByUser({ setAdvertisements }, user?.user_id);
    setLoading(false);
  };

  const handlePreview = (advertisement_type, advertisement_url) => {
    if (advertisement_type === "Global") {
      console.log();
      setPreviewUrl(advertisement_url);
      document.getElementById("my_modal_8").showModal();
    }
  };

  return (
    <>
      <dialog id="my_modal_8" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Global Advertisement Preview</h3>
          <p>This is what users on MyLLave will be viewing</p>
          <img
            className="mx-auto mt-2 border border-base-300 rounded-md"
            src={`https://res.cloudinary.com/diur0igkv/image/upload${previewUrl}`}
            alt="advertisement"
          />
          <form method="dialog" className="py-4 space-y-3">
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_8").close();
                  setPreviewUrl(null);
                }}
              >
                Close
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="border-t border-base-300 flex md:flex-row flex-col-reverse px-[24px] space-y-5">
        <div className="my-5 w-full space-y-5">
          <h3 className="text-3xl font-medium">My Advertisements</h3>
          {/* <div className="flex w-full justify-between items-center"> */}
          {/*   <div className="dropdown dropdown-right block xl:hidden"> */}
          {/*     <div tabIndex={0} role="button" className="btn"> */}
          {/*       <button className="block xl:hidden"> */}
          {/*         <Filter /> */}
          {/*       </button> */}
          {/*     </div> */}
          {/*     <ul */}
          {/*       tabIndex={0} */}
          {/*       className="mt-3 z-[1] p-2 space-y-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52" */}
          {/*     > */}
          {/*       <li */}
          {/*         className={`btn btn-sm ${filter === null ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*         onClick={() => setFilter(null)} */}
          {/*       > */}
          {/*         All */}
          {/*       </li> */}
          {/*       <li */}
          {/*         className={`btn btn-sm ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*         onClick={() => setFilter("Broker / Owner")} */}
          {/*       > */}
          {/*         Global Advertisement */}
          {/*       </li> */}
          {/*       <button */}
          {/*         className={`btn btn-sm ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*         onClick={() => setFilter("Broker / Associate")} */}
          {/*       > */}
          {/*         Property Promotion */}
          {/*       </button> */}
          {/**/}
          {/*       <button */}
          {/*         className={`btn btn-sm ${filter === "REALTOR" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*         onClick={() => setFilter("REALTOR")} */}
          {/*       > */}
          {/*         Profile Promotion */}
          {/*       </button> */}
          {/*     </ul> */}
          {/*   </div> */}
          {/*   <div className="flex-wrap gap-5 w-fit rounded-md xl:flex hidden"> */}
          {/*     <button */}
          {/*       className={`btn btn-sm ${filter === null ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*       onClick={() => setFilter(null)} */}
          {/*     > */}
          {/*       All */}
          {/*     </button> */}
          {/**/}
          {/*     <button */}
          {/*       className={`btn btn-sm ${filter === "Global Advertisement" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*       onClick={() => setFilter(null)} */}
          {/*     > */}
          {/*       Global Advertisement */}
          {/*     </button> */}
          {/*     <button */}
          {/*       className={`btn btn-sm ${filter === "Broker / Owner" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*       onClick={() => setFilter("Broker / Owner")} */}
          {/*     > */}
          {/*       Property Promotion */}
          {/*     </button> */}
          {/*     <button */}
          {/*       className={`btn btn-sm ${filter === "Broker / Associate" ? "btn-primary" : "hover:!btn-primary"}`} */}
          {/*       onClick={() => setFilter("Broker / Associate")} */}
          {/*     > */}
          {/*       Profile Promotion */}
          {/*     </button> */}
          {/*   </div> */}
          {/*   <div> */}
          {/*     <input */}
          {/*       type="text" */}
          {/*       placeholder="Search by name" */}
          {/*       className="input input-bordered w-full max-w-md" */}
          {/*     /> */}
          {/*   </div> */}
          {/* </div> */}
          {advertisements && advertisements.length > 0 ? (
            <div className="overflow-auto">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Status</th>
                    <th>Advertisment Id</th>
                    <th>Type</th>
                    <th>Orientation</th>
                    <th>Views</th>
                    <th>Views Limit</th>
                    <th>Created</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-visible">
                  {advertisements.map((advertisement, index) => (
                    <tr key={index} className="overflow-y-visible items-center">
                      <th>{index + 1}</th>
                      <td>{advertisement.advertisement_status}</td>
                      <td>{advertisement.advertisement_id}</td>
                      <td>{advertisement.advertisement_type}</td>
                      <td>{advertisement.alignment}</td>
                      <td>{advertisement.impressions}</td>
                      <td>{advertisement.impression_limit}</td>
                      <td>{advertisement.created || "Not Set"}</td>
                      <td>
                        <button
                          className="btn btn-xs mt-2"
                          onClick={() => {
                            handlePreview(
                              advertisement.advertisement_type,
                              advertisement.advertisement_url,
                            );
                          }}
                        >
                          View Advertisement
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : loading ? (
            <div className="w-full min-h-screen ">
              <div className="h-[250px] flex justify-center items-center gap-4">
                <h3>Looking for advertisements</h3>
                <span className="loading loading-dots min-h-screen text-neutral loading-md"></span>
              </div>
            </div>
          ) : (
            <p className="mt-5 text-neutral text-center">
              No advertisements found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default AdvertisementTab;
