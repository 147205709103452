import { useState, useEffect } from "react";
import { Mail, Ellipsis, Pencil, ExternalLink, LogOut } from "lucide-react";
import Footer from "../components/Footer";

import { PatternFormat } from "react-number-format";

import UploadWidgetEdit from "../components/UploadWidgetEdit";
import ProfileTab from "./tabs/ProfileTab";
import { userUpdateProfile } from "../actions/userUpdateProfile";
import { logoutUser } from "../actions/userLogout";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../actions/userUpdate";

import toast from "react-hot-toast";
import PropertiesTab from "./tabs/PropertiesTab";
import AdvertisementTab from "./tabs/Advertisment";
import Admin from "../containers/Admin";
import TransactionTab from "./tabs/TransactionTab";
import KeyHistoryTab from "./tabs/KeyHistoryTab";

const ProfileView = ({ user, setUser, preview }) => {
  const [editUser, setEditUser] = useState(user);
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();
  const [active, setActive] = useState(
    localStorage.getItem("tab") === "Properties" && !preview
      ? "Properties"
      : localStorage.getItem("tab") === "Advertisements" && !preview
        ? "Advertisements"
        : localStorage.getItem("tab") === "Transactions" && !preview
          ? "Transactions"
          : localStorage.getItem("tab") === "Keys History" && !preview
            ? "Keys History"
            : localStorage.getItem("tab") === "Admin"
              ? "Admin"
              : "Profile",
  );
  const [uploadedItems, setUploadedItems] = useState([]);
  const [phoneNumber, setPhone_Number] = useState(null);
  useEffect(() => {
    userUpdateProfile(user, uploadedItems, setUser);
  }, [uploadedItems]);

  const handleClick = () => {
    logoutUser({ setUser });
  };

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [year, month, day] = dateString.split("-");
    const monthName = months[parseInt(month, 10) - 1];
    return `Joined ${monthName} ${year}`;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...user, [name]: value });
  };

  const submitChanges = async () => {
    await toast.promise(userUpdate(editUser, setUser), {
      loading: "Logging you in...",
      success: (data) => {
        setLoading(false);

        document.getElementById("my_modal_2").close();
        return "Update Successful!";
      },
      error: (err) => {
        setLoading(false);
        return err.message;
      },
    });

    userUpdate(editUser, setUser);
  };

  function handleCopy() {
    navigator.clipboard.writeText(
      `https://myllave.com/register?referrer=${user?.user_id}`,
    );
    toast.success("Copied URL to clipboard");
    document.getElementById("my_modal_20").close();
  }

  return (
    <>
      {user ? (
        <main className="my-12 max-w-[1440px] mx-auto">
          <dialog id="my_modal_20" className="modal !z-[20]">
            <div className="modal-box space-y-3">
              <h3 className="font-bold text-lg">Share Referral Link</h3>
              <hr />
              <form method="dialog" className="flex gap-5">
                <a
                  className="btn btn-default"
                  // href={`mailto:?subject=Check out this property at MyLlave! ${properties?.street_number} ${properties?.dir_prefix ? properties?.dir_prefix + " " : ""}${properties?.street_name} ${properties?.street_type ? properties?.street_type + " " : ""}${properties?.unit_number ? ", Unit # " + properties?.unit_number : ""}, ${properties?.city}, ${properties?.state ? extractStateAbbreviation(properties?.state) : ""}, ${properties?.zip}`}
                >
                  <Mail />
                </a>
              </form>
              <div className="relative w-full ">
                <div className="input input-bordered pt-[8px]">
                  https://myllave.com/register?referrer={user?.user_id}
                </div>
                <button
                  className="btn btn-primary absolute right-2 bottom-2 btn-sm"
                  onClick={handleCopy}
                >
                  Copy
                </button>
              </div>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>

          {/* Modal For Edit Profile */}
          <dialog id="my_modal_2" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Edit Profile</h3>
              <p>Changes will be made after submission</p>
              <form method="dialog" className="py-4 space-y-3">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">About</span>
                  </div>
                  <textarea
                    type="textarea"
                    className="input pt-3 input-bordered w-full"
                    name="about"
                    placeholder={user?.about}
                    onChange={(e) => handleChange(e)}
                  />
                </label>
                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text flex gap-2">
                      Media URL -
                      <p className="text-gray-500 text-[14px]">
                        Video URL's only
                      </p>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    name="video"
                    placeholder={user?.video}
                    onChange={(e) => handleChange(e)}
                  />
                </label>

                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text">Phone Number</span>
                  </div>
                  <PatternFormat
                    className="input input-bordered w-full"
                    format="+1 (###) ### ####"
                    name="phone_number"
                    value={user?.phone_number}
                    onChange={(e) => handleChange(e)}
                    allowEmptyFormatting
                    mask="_"
                  />
                </label>
                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text">Facebook</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    name="facebook"
                    placeholder={user?.facebook}
                    onChange={(e) => handleChange(e)}
                  />
                </label>

                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text">Twitter</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    name="twitter"
                    placeholder={user?.twitter}
                    onChange={(e) => handleChange(e)}
                  />
                </label>

                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text">Instagram</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    name="instagram"
                    placeholder={user?.instagram}
                    onChange={(e) => handleChange(e)}
                  />
                </label>

                <label className="form-control flex-[0.5]">
                  <div className="label">
                    <span className="label-text">Tiktok</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    name="tiktok"
                    placeholder={user?.tiktok}
                    onChange={(e) => handleChange(e)}
                  />
                </label>

                <div className="flex gap-2 pt-4">
                  <button
                    className="btn btn-outline btn-error"
                    type="button"
                    onClick={() => {
                      setEditUser(user);
                      document.getElementById("my_modal_2").close();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary flex-[1]"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      submitChanges();
                    }}
                  >
                    Submit Changes
                  </button>
                </div>
              </form>
            </div>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
          {/* End Modal For Edit Profile */}

          <div className="w-full h-full border-b border-base-300 pb-5">
            <div className="mx-auto w-fit space-y-3">
              <div className="w-[150px] h-[150px] relative mx-auto ">
                <img
                  className="rounded-full w-full absolute object-cover h-full"
                  src={
                    user?.picture_url
                      ? `https://res.cloudinary.com/diur0igkv/image/upload/${user?.picture_url}`
                      : "/error.png"
                  }
                  alt="User"
                />
                {!preview && (
                  <UploadWidgetEdit setUploadedItems={setUploadedItems} />
                )}
              </div>
              <div className="space-y-1">
                <h2 className="text-[19px] font-medium text-center">
                  {user?.legal_first_name && user?.legal_last_name
                    ? `${user?.legal_first_name} ${user?.legal_last_name}`
                    : "No Name Found"}
                </h2>
                <div className="text-center">
                  <div>
                    {user?.class_type !== "Consumer" ? (
                      user?.class_type === "REALTOR" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.class_type} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {user?.brokerage_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>License ID #: {user?.profession_id}</p>

                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>
                            {user?.listed_date
                              ? formatDate(user?.listed_date)
                              : ""}
                          </p>
                        </div>
                      ) : user?.class_type === "Broker / Owner" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.class_type} of </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {user?.brokerage_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>License ID #: {user?.profession_id}</p>

                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : user?.class_type === "Broker / Associate" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.class_type} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {user?.brokerage_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>License ID #: {user?.profession_id}</p>

                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : user?.class_type === "Builder" ? (
                        <div className="flex flex-wrap">
                          <div className="space-x-1 flex">
                            <p>{user?.position} at </p>
                            <a
                              href=""
                              className="link link-hover link-primary ml-1"
                            >
                              {user?.builder_company_name}
                            </a>
                          </div>

                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : // Continue checking for other class_types
                      user?.class_type === "Lender" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.class_type} at </p>
                            <a
                              href=""
                              className="link link-primary link-hover ml-1"
                            >
                              {user?.lending_company_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>NMLS ID #: {user?.nmls_number}</p>

                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : user?.class_type === "Investor" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.position} at </p>
                            <a
                              href=""
                              className="link link-primary link-hover ml-1"
                            >
                              {user?.entity_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : user?.class_type === "Title Closer" ? (
                        <div className="flex md:flex-row flex-col">
                          <div className="space-x-1 flex">
                            <p>{user?.position} at </p>
                            <a
                              href=""
                              className="link link-primary link-hover ml-1"
                            >
                              {user?.title_company_name}
                            </a>
                          </div>
                          <div className="divider divider-horizontal md:visible invisible" />
                          <p>{formatDate(user?.listed_date)}</p>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      // If user is a consumer (reg account)
                      <p>{formatDate(user?.listed_date)}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col w-full gap-2">
                <a
                  href={`mailto:${user?.email_address}`}
                  className={`btn ${preview ? "flex-[1]" : "flex-[0.7]"} w-full mt-3`}
                >
                  <Mail />
                  Message
                </a>
                {!preview && (
                  <div className="dropdown flex-[0.3] dropdown-end mt-3">
                    <div
                      tabIndex={0}
                      role="button"
                      className="btn w-full btn-outline "
                    >
                      Profile Actions
                    </div>
                    <ul
                      tabIndex={0}
                      className="z-[1] shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
                    >
                      <li>
                        <button
                          className="space-x-2"
                          onClick={() =>
                            document.getElementById("my_modal_20").showModal()
                          }
                        >
                          <ExternalLink />
                          Share Referral Link
                        </button>
                      </li>
                      <li>
                        <button
                          className="space-x-2"
                          onClick={() =>
                            document.getElementById("my_modal_2").showModal()
                          }
                        >
                          <Pencil />
                          Edit Profile
                        </button>
                      </li>
                      <li>
                        <button
                          className="space-x-2 text-error"
                          onClick={() => handleClick()}
                        >
                          <LogOut />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-full py-5">
            <div
              role="tablist"
              className="tabs max-w-[850px] mx-auto flex flex-wrap justify-center"
            >
              <button
                role="tab"
                className={`tab ${active === "Profile" ? "tab-active text-primary" : ""}`}
                onClick={() => setActive("Profile")}
              >
                Profile
              </button>
              <button
                role="tab"
                className={`tab ${active === "Properties" ? "tab-active text-primary" : ""}`}
                onClick={() => setActive("Properties")}
              >
                Properties
              </button>
              {!preview && (
                <>
                  <button
                    role="tab"
                    className={`tab ${active === "Advertisements" ? "tab-active text-primary" : ""}`}
                    onClick={() => setActive("Advertisements")}
                  >
                    Advertisements
                  </button>

                  <button
                    role="tab"
                    className={`tab ${active === "Transactions" ? "tab-active text-primary" : ""}`}
                    onClick={() => setActive("Transactions")}
                  >
                    Transactions
                  </button>
                  <button
                    role="tab"
                    className={`tab ${active === "Keys History" ? "tab-active text-primary" : ""}`}
                    onClick={() => setActive("Keys History")}
                  >
                    Keys History
                  </button>

                  {user?.user_id === 236 && (
                    <button
                      role="tab"
                      className={`tab ${active === "Admin" ? "tab-active text-primary" : ""}`}
                      onClick={() => setActive("Admin")}
                    >
                      Admin Dashboard
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {active === "Profile" && <ProfileTab user={user} setUser={setUser} />}
          {active === "Properties" && (
            <PropertiesTab user={user} setUser={setUser} preview={preview} />
          )}
          {active === "Advertisements" && (
            <AdvertisementTab user={user} setUser={setUser} />
          )}
          {active === "Transactions" && (
            <TransactionTab user={user} setUser={setUser} />
          )}
          {active === "Keys History" && (
            <KeyHistoryTab user={user} setUser={setUser} />
          )}

          {active === "Admin" && <Admin />}
        </main>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileView;
