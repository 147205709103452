import { HOST } from "../../constants/HostConstants";

export const stakeWithdraw = async (
  user_id,
  stake_id,
  earned,
  taxed,
  initial_amount,
  { setUser },
) => {
  const response = await fetch(`${HOST}/api/transaction/stakeWithdraw`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      stake_id,
      earned,
      taxed,
      initial_amount,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  console.log(response);
  const data = response;
  if (!data.success) {
    throw new Error(`Stake request not sent`);
  } else if (data.user) {
    localStorage.setItem("token", data.token);
    setUser(data.user);
  }
  return data;
};
