import "./App.css";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HorizontalAdd from "./components/HorizontalAdd";
import { PropertyContext, UserContext } from "./context/MainContext";
import { currentUser } from "./actions/userCurrent";
import { useLocation } from "react-router-dom";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Register from "./containers/Register";
import Nav from "./components/Nav";
import Profile from "./containers/Profile";
import User from "./containers/User";
import Store from "./containers/Store";
import ProtectedRoute from "./containers/ProtectedRoute";
import Professionals from "./containers/Professionals";
import Search from "./containers/Search";
import PropertyAdd from "./containers/PropertyAdd";
import PropertyEdit from "./containers/PropertyEdit";
import Properties from "./containers/Properties";
import KeyInformation from "./containers/KeyInformation";

import { Toaster } from "react-hot-toast";
import Property from "./containers/Property";
import Welcome from "./containers/welcome";
import Verify from "./containers/Verify";

function App() {
  const [propertyClicked, setPropertyClicked] = useState(null);
  const [user, setUser] = useState(null);
  const [universalAd, setUniversalAd] = useState(true);
  const [location, setLocation] = useState(useLocation());

  console.log(location);
  useEffect(() => {
    currentUser({ setUser });
  }, [setUser]);
  return (
    <div className="flex flex-col relative items-center justify-center">
      {universalAd &&
        location.pathname !== "/welcome" &&
        location.pathname !== "/verify" && (
          <div className="fixed bottom-0 z-50 border-2">
            <HorizontalAdd setUniversalAd={setUniversalAd} />
          </div>
        )}
      <header className="max-w-[2200px] tablet:px-16 homebreak:px-32 w-full">
        <UserContext.Provider value={{ user, setUser }}>
          <Nav />
          <Toaster />
          <PropertyContext.Provider
            value={{ propertyClicked, setPropertyClicked }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/properties" element={<Properties />} />
              <Route path="/property-add" element={<PropertyAdd />} />
              <Route path="/property-edit/:id" element={<PropertyEdit />} />
              <Route path="/property/:id" element={<Property />} />
              <Route path="/key-information" element={<KeyInformation />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/user/:id" element={<User />} />
              <Route path="/admin-dashboard" element={<ProtectedRoute />} />
              <Route path="/store" element={<Store />} />
              <Route path="/professionals" element={<Professionals />} />
              <Route path="/search/:id" element={<Search />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/verify" element={<Verify />} />
            </Routes>
          </PropertyContext.Provider>
        </UserContext.Provider>
      </header>
    </div>
  );
}

export default App;
