import { HOST } from "../../constants/HostConstants";
export const getCities = async ({ setCities }) => {
  fetch(`${HOST}/api/property/getCities`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      data.cities.sort((a, b) => {
        const cityA = a.city_name.toUpperCase(); // ignore upper and lowercase
        const cityB = b.city_name.toUpperCase(); // ignore upper and lowercase

        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setCities(data.cities);
    });
};
