import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { currentUser } from "../actions/userCurrent";
import { UserContext } from "../context/MainContext";
import { Menu } from "lucide-react";
import { logoutUser } from "../actions/userLogout";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Nav = () => {
  const { user, setUser } = useContext(UserContext);

  const propertyRef = useRef(null);
  const menuRef = useRef(null);
  const profileRef = useRef(null);
  const nav = useNavigate();
  useEffect(() => {
    currentUser({ setUser });
  }, []);
  const handleClick = () => {
    const detailElement = propertyRef.current;
    const menuElement = menuRef.current;
    const profileElement = profileRef.current;
    if (detailElement) {
      detailElement.removeAttribute("open");
    }
    if (profileElement) {
      profileElement.removeAttribute("open");
    }
    if (menuElement) {
      menuElement.removeAttribute("open");
    }
  };

  const handleProfileClick = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };
  const handlePropertyClick = (property_status) => {
    const elem = document.activeElement;
    if (property_status) {
      localStorage.setItem("property_status", property_status);
    }
    nav("/properties");
    if (elem) {
      elem?.blur();
    }
  };
  return (
    <>
      <nav className="navbar items-center bg-base-100 max-w-[1440px] mx-auto">
        <div className="flex-1 space-x-5">
          <Link
            to="/"
            className="btn btn-ghost !flex !items-center text-xl !gap-2"
          >
            <img
              src={"/LlaveIcon_deliverable.png"}
              className="w-[50px] h-[45px]"
              alt="logo"
            />
            <span className="font-semibold text-3xl">MyLlave</span>
          </Link>
          {/* <input */}
          {/*   type="text" */}
          {/*   placeholder="Search for properties by KEY #" */}
          {/*   className="input input-bordered w-full max-w-xs sm:block hidden" */}
          {/* /> */}
        </div>
        <div className="flex-none xl:block hidden">
          <ul className="menu items-center menu-horizontal px-1 space-x-2">
            {user &&
            (user.class_type === "Realtor" ||
              user.class_type === "REALTOR" ||
              user.class_type === "Broker / Owner" ||
              user.class_type === "Broker / Associate" ||
              user.class_type === "Builder" ||
              user.class_type === "Investor") ? (
              <li>
                <Link
                  to="/property-add"
                  className="btn btn-primary btn-sm ml-[12px]"
                >
                  List A Property
                </Link>
              </li>
            ) : (
              ""
            )}
            <div className="dropdown dropdown-end dropdown-hover">
              <div
                tabIndex={0}
                className="btn btn-sm btn-ghost !font-normal "
                onClick={() => handlePropertyClick(null)}
              >
                Search Properties
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu shadow bg-base-100 rounded-box w-52"
              >
                <li onClick={() => handlePropertyClick("Active-Sale")}>
                  <button>Active - For Sale</button>
                </li>
                <li onClick={() => handlePropertyClick("Active-Lease")}>
                  <button>Active - For Lease</button>
                </li>
                <li onClick={() => handlePropertyClick("Option")}>
                  <button>Option</button>
                </li>
                <li onClick={() => handlePropertyClick("Pending")}>
                  <button>Pending</button>
                </li>
                <li onClick={() => handlePropertyClick("Sold")}>
                  <button>Sold</button>
                </li>
                <li onClick={() => handlePropertyClick("Leased")}>
                  <button>Leased</button>
                </li>
                <li onClick={() => handlePropertyClick("Withdrawn")}>
                  <button>Withdrawn</button>
                </li>
                <li onClick={() => handlePropertyClick("Expired")}>
                  <button>Expired</button>
                </li>
              </ul>
            </div>
            <li>
              <Link
                to="/professionals"
                className="btn btn-sm btn-ghost !font-normal"
              >
                Professional Directory
              </Link>
            </li>
            <li>
              <Link
                to="/key-information"
                className="btn btn-sm btn-ghost !font-normal"
              >
                Keys & More
              </Link>
            </li>

            {user ? (
              <>
                <div className="dropdown dropdown-end dropdown-hover z-[20]">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-ghost font-normal"
                  >
                    Control Panel
                  </div>
                  <ul
                    tabIndex={0}
                    className="z-[1] p-2 shadow menu menu-sm space-y-3 dropdown-content bg-base-100 rounded-box w-52"
                  >
                    <li onClick={() => handleProfileClick()}>
                      {user.user_id === 2 ? (
                        <Link to="/admin-dashboard" className="justify-between">
                          Admin Dashboard
                        </Link>
                      ) : (
                        <Link
                          to="/profile"
                          className="jusitify-between"
                          onClick={() => localStorage.setItem("tab", "Profile")}
                        >
                          Profile
                        </Link>
                      )}
                    </li>
                    <li
                      onClick={() => {
                        handleProfileClick();
                        localStorage.setItem("tab", "Properties");
                      }}
                    >
                      <Link to="/profile">My Properties</Link>
                    </li>
                    <li onClick={() => handleProfileClick()}>
                      <Link
                        to="/profile"
                        className="jusitify-between"
                        onClick={() =>
                          localStorage.setItem("tab", "Advertisements")
                        }
                      >
                        My Advertisements
                      </Link>
                    </li>
                    <li onClick={() => handleProfileClick()}>
                      <Link
                        to="/profile"
                        className="jusitify-between"
                        onClick={() =>
                          localStorage.setItem("tab", "Transactions")
                        }
                      >
                        My Transactions
                      </Link>
                    </li>
                    <li onClick={() => handleProfileClick()}>
                      <Link
                        to="/profile"
                        className="jusitify-between"
                        onClick={() =>
                          localStorage.setItem("tab", "Keys History")
                        }
                      >
                        My Key History
                      </Link>
                    </li>

                    {user?.user_id === 236 && (
                      <li onClick={() => handleProfileClick()}>
                        <Link
                          to="/profile"
                          className="jusitify-between"
                          onClick={() => localStorage.setItem("tab", "Admin")}
                        >
                          Admin Dashboard
                        </Link>
                      </li>
                    )}
                    <li onClick={() => handleProfileClick()}>
                      <Link to="/store">Store</Link>
                    </li>
                    <button
                      className="btn btn-error btn-sm my-2"
                      onClick={() => {
                        logoutUser({ setUser });
                        toast.success("Log Out Successful");
                      }}
                    >
                      Log Out
                    </button>
                  </ul>
                </div>
                <div className="w-[35px]  h-[35px] relative ">
                  {user.picture_url !== null ? (
                    <img
                      alt="Profile"
                      className="rounded-full absolute object-cover h-full"
                      src={`https://res.cloudinary.com/diur0igkv/image/upload/${user?.picture_url}`}
                    />
                  ) : (
                    <img
                      alt="Profile"
                      className="rounded-full"
                      src={"/error.png"}
                    />
                  )}
                </div>
              </>
            ) : (
              <ul className="flex gap-2 ml-5">
                <li>
                  <Link to="/login" className="btn">
                    Log In
                  </Link>
                </li>
                <li>
                  <Link to="/register" className="btn btn-primary">
                    Register
                  </Link>
                </li>
              </ul>
            )}
          </ul>
        </div>
        <details
          ref={profileRef}
          className="dropdown dropdown-end xl:hidden z-[20] block mr-5"
        >
          <summary className="m-1 btn btn-ghost">
            <Menu />
          </summary>
          <ul className="p-2 shadow menu dropdown-content z-[1] !space-y-0 bg-base-100 rounded-box w-52">
            {user?.class_type !== "Consumer" &&
              user?.class_type !== "Advertiser" &&
              user?.class_type !== "title_closer" &&
              user?.class_type !== "lender" && (
                <li onClick={() => handleClick()}>
                  <Link className="" to="/property-add">
                    List A Property
                  </Link>
                </li>
              )}
            <li onClick={() => handleClick()}>
              <Link to="/properties">Search Properties</Link>
            </li>
            <li onClick={() => handleClick()}>
              <Link to="/professionals">Professionals Directory</Link>
            </li>
            <li onClick={() => handleClick()}>
              <Link to="/key-information">Keys & More</Link>
            </li>

            <div className="divider"></div>

            {user ? (
              <div className="space-y-2">
                <li onClick={() => handleClick()}>
                  {user.user_id === 2 ? (
                    <Link to="/admin-dashboard" className="justify-between">
                      Admin Dashboard
                    </Link>
                  ) : (
                    <Link
                      to="/profile"
                      className="justify-between"
                      onClick={() => localStorage.setItem("tab", "Profile")}
                    >
                      Profile
                    </Link>
                  )}
                </li>
                <li onClick={() => handleClick()}>
                  {user.user_id === 2 ? (
                    ""
                  ) : (
                    <Link
                      to="/profile"
                      className="justify-between"
                      onClick={() => localStorage.setItem("tab", "Properties")}
                    >
                      My Properties
                    </Link>
                  )}
                </li>
                <li onClick={() => handleClick()}>
                  <Link
                    to="/profile"
                    className="jusitify-between"
                    onClick={() =>
                      localStorage.setItem("tab", "Advertisements")
                    }
                  >
                    My Advertisements
                  </Link>
                </li>
                <li onClick={() => handleClick()}>
                  <Link
                    to="/profile"
                    className="jusitify-between"
                    onClick={() => localStorage.setItem("tab", "Transactions")}
                  >
                    My Transactions
                  </Link>
                </li>
                <li onClick={() => handleClick()}>
                  <Link
                    to="/profile"
                    className="jusitify-between"
                    onClick={() => localStorage.setItem("tab", "Keys History")}
                  >
                    My Key History
                  </Link>
                </li>

                {user?.user_id === 236 && (
                  <li onClick={() => handleClick()}>
                    <Link
                      to="/profile"
                      className="jusitify-between"
                      onClick={() => localStorage.setItem("tab", "Admin")}
                    >
                      Admin Dashboard
                    </Link>
                  </li>
                )}
                {user?.class_type !== "Consumer" && (
                  <li onClick={() => handleClick()}>
                    <Link to="/store">Store</Link>
                  </li>
                )}
                <li onClick={() => handleClick()}>
                  <button
                    className="btn btn-error btn-sm"
                    onClick={() => {
                      logoutUser({ setUser });
                      toast.success("Log Out Successful");
                    }}
                  >
                    Log Out
                  </button>
                </li>
              </div>
            ) : (
              <div className="space-y-2">
                <li onClick={() => handleClick()}>
                  <Link to="/login" className="btn btn-sm">
                    Log In
                  </Link>
                </li>
                <li onClick={() => handleClick()}>
                  <Link to="/register" className="btn btn-primary btn-sm">
                    Register
                  </Link>
                </li>
              </div>
            )}
          </ul>
        </details>
      </nav>
      {/* <div className="px-2 sm:hidden block"> */}
      {/*   <input */}
      {/*     type="text" */}
      {/*     placeholder="Search for properties or professionals" */}
      {/*     className="input input-bordered w-full" */}
      {/*   /> */}
      {/* </div> */}
    </>
  );
};

export default Nav;
