import { HOST } from "../constants/HostConstants";
export const propertyByUser = async (
  filter,
  page,
  limit,
  user_id,
  token,
  { setResults },
) => {
  const response = await fetch(`${HOST}/api/property/propertyByUser`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      user_id,
      filter,
      page,
      limit,
      token,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    });
  const data = response;
  console.log(data);
  if (data.properties) {
    setResults(data.properties);
  }

  return;
};
