import { HOST } from "../constants/HostConstants";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
export const userStats = async ({ setStats }) => {
  fetch(`${HOST}/api/user/totalUsers`, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
      } else {
        setStats(data);
      }
    });
};

