import { HOST } from "../../constants/HostConstants";

export const stakingPending = async ({ setPending }) => {
  console.log("ini staking");
  fetch(`${HOST}/api/transaction/stakingPending`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.success) {
        setPending(data.results);
      }
    });
};
