import { HOST } from "../../constants/HostConstants";
export const keyInformation = async ({
  setDataValues,
  setTopTen,
  setTotalKeys,
  setOtherKeys,
  setStaking,
  setStakingEarned,
}) => {
  fetch(`${HOST}/api/user/keyDistribution`, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      let ordered_data = [
        data.key_holding,
        data.operating,
        data.highest,
        data.other,
      ];
      setDataValues(ordered_data);
      setTopTen(data.top_ten);
      setTotalKeys(data.total_keys);
      setOtherKeys(data.other_keys);
      setStaking(data.staking);
      setStakingEarned(data.stakingEarned);
    });
};
