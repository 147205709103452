import { useEffect } from "react";
import { MoneyFormat } from "../helpers/format";
import { keysToDollars } from "../helpers/conversions";

const WebStats = ({ user_stats, property_stats, key_stats }) => {
  useEffect(() => {}, []);
  return (
    <div className="border border-base-200  rounded-md shadow-sm p-4 space-y-2 ">
      <span className="font-medium">Website Stats</span>
      <hr />
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Users</h3>
        {user_stats ? (
          <p className="font-semibold text-[12px]">{user_stats.total}</p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Listed Properties</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {property_stats.total_properties}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Listed Property Volume</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {"$" + MoneyFormat(property_stats.total_properties_volume)}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Sold Properties</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {property_stats.total_sold}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Sold Properties Volume</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {"$" + MoneyFormat(property_stats.total_sold_volume)}
          </p>
        ) : (
          ""
        )}
      </span>

      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Leased Properties</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {MoneyFormat(property_stats.total_leased)}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Leased Properties Volume</h3>
        {property_stats ? (
          <p className="font-semibold text-[12px]">
            {"$" + MoneyFormat(property_stats.total_leased_volume * 12)}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Keys Earned</h3>
        {key_stats ? (
          <p className="font-semibold text-[12px]">
            {MoneyFormat(key_stats) +
              " | $" +
              MoneyFormat(keysToDollars(key_stats))}
          </p>
        ) : (
          ""
        )}
      </span>
      <span className="flex justify-between gap-5">
        <h3 className="text-[12px]">Liquidity</h3>
        {user_stats ? (
          <p className="font-semibold text-[12px]">
            {"$" + MoneyFormat(user_stats.liquidity)}
          </p>
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default WebStats;
