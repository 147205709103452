import React, { useEffect, useState } from "react";
import {
  Search,
  ChevronsRight,
  ChevronsLeft,
  FilePlus,
  Filter,
  X,
  LayoutGrid,
  Table,
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { properties } from "../actions/discover/properties";
import Preview from "../components/properties/preview";
import Footer from "../components/Footer";
import {
  addCommasToPrice,
  calculateDaysSince,
  extractStateAbbreviation,
} from "../helpers/conversions";
import {
  addBathrooms,
  addBedrooms,
  addLivingSqFt,
  roundToTwoDecimals,
} from "../helpers/math";
import { getMaxPrice } from "../actions/home/getMaxPrice";
import { getCities } from "../actions/home/getCities";

const statuses = [
  "Active - For Sale",
  "Active - For Lease",
  "Option",
  "Pending",
  "Sold",
  "Leased",
  "Withdrawn",
  "Expired",
];

const types = ["Commercial", "Residential", "Vacant Land"];

const FilterBadge = ({ title }) => {
  return (
    <div className="badge badge-outline !border-[color:#D3D4D7] flex gap-2 !p-4">
      {title}
      <button className="hover:scale-[1.1]">
        <X size={16} />
      </button>
    </div>
  );
};

const Properties = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [limit, setLimit] = useState(16);
  let timeoutId = null;

  const [results, setResults] = useState(null);
  const [sortedResults, setSortedResults] = useState(null);
  const [view, setView] = useState("Grid View");
  const [cities, setCities] = useState(null);
  const [filter, setFilter] = useState({
    types: [],
    status: queryParams.get("category")
      ? queryParams.get("category") === "sold-and-leased"
        ? ["Sold", "Leased"]
        : queryParams.get("category") === "withdrawn-and-expired"
          ? ["Withdrawn", "Expired"]
          : []
      : ["Active-Sale", "Active-Lease"],
    key_number: null,
    search: null,
    price: null,
    beds: null,
    baths: null,
    cities: [],
  });
  console.log(filter);
  const [maxPrice, setMaxPrice] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const [category, setCategory] = useState(
    queryParams.get("category") ? queryParams.get("category") : null,
  );

  console.log(filter);
  useEffect(() => {
    getCities({ setCities });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("property_status")) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        status: [localStorage.getItem("property_status")],
      }));
    }
  }, [localStorage.getItem("property_status")]);

  useEffect(() => {
    handleSearch();
  }, [limit]);

  console.log(filter);
  useEffect(() => {
    console.log(filter.status[0], localStorage.getItem("property_status"));
    if (filter.status[0] === localStorage.getItem("property_status")) {
      handleSearch();
    }
  }, [filter.status]);

  console.log("sorted results:", sortedResults);
  // console.log(cities);
  useEffect(() => {
    handleSearch();
  }, [page]);

  // useEffect(() => {
  //   if (filter.status.length > 0) {
  //     setFilter((prevFilter) => ({ ...prevFilter, price: null }));
  //     getMaxPrice(filter.status, { setMaxPrice });
  //   }
  // }, [filter.status]);

  useEffect(() => {
    if (results && sort) {
      let sorted;
      if (sort === "Price - Ascending") {
        sorted = [...results].sort((a, b) => a.list_price - b.list_price);
      } else if (sort === "Price - Descending") {
        sorted = [...results].sort((a, b) => b.list_price - a.list_price);
      } else if (sort === "Days On Market - Ascending") {
        sorted = [...results].sort((a, b) => {
          const aDate = new Date(a.listed_date);
          const bDate = new Date(b.listed_date);
          return bDate - aDate;
        });
      } else if (sort === "Days On Market - Descending") {
        sorted = [...results].sort((a, b) => {
          const aDate = new Date(a.listed_date);
          const bDate = new Date(b.listed_date);
          return aDate - bDate;
        });
      }
      setSortedResults(sorted);
    } else {
      console.log(results);
      setSortedResults(results);
    }
  }, [results, sort]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setFilter((prevFilter) => ({ ...prevFilter, search: value }));

    // Clear any existing timeout
    clearTimeout(timeoutId);

    // Set a new timeout for 3000ms (3 seconds)
    timeoutId = setTimeout(() => {
      if (value.trim() !== "") {
        handleSearch();
      }
    }, 3000);
  };

  const handleMultiFilter = (e) => {
    const { name, value } = e.target;

    // Create a copy of filter[name] array
    let updatedFilter = [...filter[name]];

    if (updatedFilter.includes(value)) {
      // Remove value if it exists
      updatedFilter = updatedFilter.filter((item) => item !== value);
    } else {
      // Add value if it doesn't exist
      updatedFilter.push(value);
    }

    // Update the filter state
    setFilter({ ...filter, [name]: updatedFilter });
  };

  async function handleSearch() {
    setSortedResults(null);
    console.log(filter);
    await properties(filter, page, limit, category, { setResults });

    localStorage.removeItem("property_status");
  }

  async function handleSort(e) {
    const sortBy = e.target.value;
    setResults(null);
    // Set sort state
    setSort(sortBy);
    if (page === 1) {
      // Call handleSearch to perform search and sorting operations
      await handleSearch();
    } else {
      // Reset page to 1
      setPage(1);
    }
  }

  function MoneyFormat(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }) + "B"
      : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }) + "M"
        : // Three Zeroes for Thousands
          Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            }) + "K"
          : Math.abs(Number(labelValue)).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            });
  }

  return (
    <>
      <dialog id="my_modal_30" className="modal">
        <div className="modal-box !overflow-y-auto space-y-5">
          <div>
            <h3 className="font-bold text-lg">Apply Filters</h3>
            <p>Select filters that best match what you&apos;re looking for</p>
          </div>
          <form className="space-y-5">
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">Status</label>
              <ul className="max-h-[122px] overflow-y-scroll mt-2 border px-4">
                {statuses.map((status, key) =>
                  queryParams.get("category") === "promoted" ? (
                    status === "Active - For Sale" ||
                    status === "Active - For Lease" ? (
                      <div className="form-control" key={key}>
                        <label className="label cursor-pointer">
                          <span className="label-text">{status}</span>
                          <input
                            type="checkbox"
                            name="status"
                            value={
                              status === "Active - For Sale"
                                ? "Active-Sale"
                                : status === "Active - For Lease"
                                  ? "Active-Lease"
                                  : status
                            }
                            className="checkbox checkbox-primary"
                            checked={filter.status.includes(
                              status === "Active - For Sale"
                                ? "Active-Sale"
                                : status === "Active - For Lease"
                                  ? "Active-Lease"
                                  : status,
                            )}
                            onChange={handleMultiFilter}
                          />
                        </label>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="form-control" key={key}>
                      <label className="label cursor-pointer">
                        <span className="label-text">{status}</span>
                        <input
                          type="checkbox"
                          name="status"
                          value={
                            status === "Active - For Sale"
                              ? "Active-Sale"
                              : status === "Active - For Lease"
                                ? "Active-Lease"
                                : status
                          }
                          className="checkbox checkbox-primary"
                          checked={filter.status.includes(
                            status === "Active - For Sale"
                              ? "Active-Sale"
                              : status === "Active - For Lease"
                                ? "Active-Lease"
                                : status,
                          )}
                          onChange={handleMultiFilter}
                        />
                      </label>
                    </div>
                  ),
                )}
              </ul>
            </div>
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">Type</label>
              <ul className="mt-2 border px-4">
                {types.map((types, key) => (
                  <div className="form-control" key={key}>
                    <label className="label cursor-pointer">
                      <span className="label-text">{types}</span>
                      <input
                        type="checkbox"
                        name="types"
                        value={types}
                        className="checkbox checkbox-primary"
                        checked={filter.types.includes(types)}
                        onChange={handleMultiFilter}
                      />
                    </label>
                  </div>
                ))}
              </ul>
            </div>
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">City</label>
              <ul className="max-h-[122px] overflow-y-scroll mt-2 border px-4">
                {cities &&
                  cities.map((city, key) => (
                    <div className="form-control" key={key}>
                      <label className="label cursor-pointer">
                        <span className="label-text">{city.city_name}</span>
                        <input
                          type="checkbox"
                          name="cities"
                          value={city.city_name}
                          className="checkbox checkbox-primary"
                          checked={filter.cities.includes(city.city_name)}
                          onChange={handleMultiFilter}
                        />
                      </label>
                    </div>
                  ))}
              </ul>
            </div>
            <div className="flex justify-between gap-5">
              <div className="">
                <div className="flex gap-2 items-center">
                  <label className="text-[16px] font-medium">Price</label>
                  <p className="text-gray-500 text-[12px]">
                    No commas or symbols
                  </p>
                </div>
                <input
                  type="text"
                  className="input input-bordered"
                  placeholder="Example: 100000"
                  onChange={(e) =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      price: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="w-full">
              <label className="form-control font-medium">Number of Beds</label>
              <select
                className="select select-bordered !placeholder-primary !placeholder-opacity-50 text-[12px] w-full"
                placeholder="Who shot first?"
                value={filter.beds ? filter.beds : ""}
                onChange={(e) =>
                  setFilter((prevFilters) => ({
                    ...prevFilters,
                    beds: e.target.value,
                  }))
                }
              >
                <option disabled value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
              </select>
            </div>
            <div className="w-full">
              <label className="form-control font-medium">
                Number of Baths
              </label>
              <select
                className="select select-bordered !placeholder-primary !placeholder-opacity-50 text-[12px] w-full"
                placeholder="Who shot first?"
                value={filter.baths ? filter.baths : ""}
                onChange={(e) =>
                  setFilter((prevFilters) => ({
                    ...prevFilters,
                    baths: e.target.value,
                  }))
                }
              >
                <option disabled value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
              </select>
            </div>
          </form>
          <div className="flex gap-2 pt-4">
            <button
              className="btn btn-outline btn-error"
              type="button"
              onClick={() => {
                document.getElementById("my_modal_30").close();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary flex-[1]"
              type="button"
              onClick={() => {
                handleSearch();
                setPage(1);
                document.getElementById("my_modal_30").close();
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </dialog>

      <div className="mx-auto my-12 space-y-5 max-w-[1440px] px-[24px] mb-64">
        <div className="space-y-[13px]">
          <h1 className="text-[30px] font-semibold text-center">
            Search For Your <span className="relative">Dream Property</span>
          </h1>
          <p className="max-w-[661px] text-center text-[14px] mx-auto">
            Set precise filters to receive tailored results that match your
            preferences perfectly.{" "}
          </p>
        </div>
        <div className="space-y-5 pt-6">
          <div className="flex gap-5 justify-between">
            <div className="flex gap-5 w-full md:flex-row flex-col">
              <button
                className="btn btn-outline !border-[color:#D3D4D7] "
                onClick={() =>
                  document.getElementById("my_modal_30").showModal()
                }
              >
                <Filter />
                Filters
              </button>

              <div className="relative flex-[1] ">
                <input
                  className="input input-bordered !pl-12 w-full"
                  placeholder="Search properties by address or key number"
                  onChange={(e) =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      search: e.target.value,
                    }))
                  }
                />
                <Search className="absolute left-3 top-3" />
                <button
                  className="btn btn-primary btn-sm absolute right-3 top-2"
                  onClick={() => handleSearch()}
                >
                  Search
                </button>
              </div>
            </div>

            {/* <div className="flex gap-5"> */}
            {/*   <button */}
            {/*     className="btn btn-outline !border-[color:#D3D4D7] " */}
            {/*     disabled */}
            {/*   > */}
            {/*     <FilePlus /> */}
            {/*     Generate CMA */}
            {/*   </button> */}
            {/* </div> */}
          </div>
        </div>
        <div className="justify-between flex-wrap flex pt-5 gap-5 md:items-end md:flex-row flex-col-reverse">
          <div className="flex  flex-col gap-5">
            <div className="flex gap-2">
              {sortedResults && sortedResults.length > 0 ? (
                <p>
                  Displaying properties:{" "}
                  {page === 1
                    ? `1-${Math.min(limit, sortedResults.length)}`
                    : `${(page - 1) * limit + 1}-${Math.min(page * limit + 2, (page - 1) * limit + sortedResults.length)}`}
                </p>
              ) : (
                <p>Displaying properties: </p>
              )}
            </div>

            <div className="join">
              <button
                className="join-item btn btn-outline !border-base-300"
                disabled={page !== 1 ? false : true}
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <ChevronsLeft size={16} />
              </button>
              <button className="join-item btn btn-outline !border-base-300">
                Page {page}
              </button>
              <button
                disabled={
                  sortedResults && sortedResults.length >= 16 ? false : true
                }
                className="join-item btn btn-outline !border-base-300"
                onClick={() => setPage(page + 1)}
              >
                <ChevronsRight size={16} />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-5 w-full md:w-fit items-center justify-between">
            <div className="flex gap-5 flex-wrap md:flex-row flex-col md:items-center px-4 rounded-md">
              <p className="md:block hidden">Show As</p>
              <div className="flex gap-5">
                <button
                  className={`btn btn-default !btn-base-300 btn-sm ${view === "Grid View" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => setView("Grid View")}
                >
                  <LayoutGrid />
                  Grid
                </button>
                <button
                  className={`btn btn-default !btn-base-300 btn-sm ${view === "Table View" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={() => setView("Table View")}
                >
                  <Table />
                  Table
                </button>
              </div>
            </div>
            <div className="flex flex-wrap gap-5">
              <label className="form-control w-fit max-w-xs">
                <select
                  className="select select-bordered w-fit max-w-xs"
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                  }}
                >
                  <option disabled selected>
                    Page Limit
                  </option>
                  <option>16</option>
                  <option>32</option>
                  <option>48</option>
                  <option>64</option>
                </select>
              </label>
              <label className="form-control flex-[1] w-fit">
                <select
                  className="select select-bordered"
                  onChange={(e) => handleSort(e)}
                >
                  <option disabled selected>
                    Sort By
                  </option>
                  <option>Price - Ascending</option>
                  <option>Price - Descending</option>
                  <option>Days On Market - Ascending</option>
                  <option>Days On Market - Descending</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        {!sortedResults ? (
          <div className="w-full min-h-screen ">
            <div className="h-[250px] flex justify-center items-center gap-4">
              <h3>Looking for properties</h3>
              <span className="loading loading-dots min-h-screen text-neutral loading-md"></span>
            </div>
          </div>
        ) : view === "Grid View" ? (
          <div className="min-h-screen w-full ">
            <div className="mx-auto gap-5 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  ">
              {sortedResults && sortedResults.length > 0 ? (
                sortedResults.map((property, index) => (
                  <Preview
                    key={index}
                    property={property}
                    user_data_embeded_further={true}
                  />
                ))
              ) : (
                <div className="min-h-screen col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4  mt-5 text-center text-gray-500">
                  No Matching Properties Found
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="min-h-screen">
            <div className="overflow-auto">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Status</th>
                    <th>For</th>
                    <th>Price</th>
                    <th>Property Id</th>
                    <th>Property Type</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Beds #</th>
                    <th>Baths #</th>
                    <th>Acres</th>
                    <th>Living Sq. Ft.</th>
                    <th>Days On Market</th>
                    <th>Listing Agent</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-visible">
                  {/* rows */}
                  {sortedResults && sortedResults.length > 0 ? (
                    sortedResults.map((property, index) => (
                      <tr key={index} className="overflow-y-visible">
                        <th>
                          {page === 1
                            ? index + 1
                            : index + 1 + (page - 1) * limit}
                        </th>
                        <td>
                          <div
                            className={`p-2 text-center rounded-md ${property.property_status === "Active" ? "bg-green-500" : property?.property_status === "Sold" || property?.property_status === "Leased" ? "bg-red-500" : property?.property_status === "Option" || property?.property_status === "Pending" ? "bg-orange-500" : property?.property_status === "Withdrawn" || property?.property_status === "Expired" ? "bg-purple-400" : ""}`}
                          >
                            {property.property_status}
                          </div>
                        </td>
                        <td>{property.for}</td>
                        <td>
                          $
                          {property.list_price
                            ? addCommasToPrice(property.list_price)
                            : "?"}
                          {property?.for === "Lease" && "/month"}
                        </td>

                        <td>
                          <Link
                            to={`/property/${property.property_id}`}
                            className="link link-primary"
                          >
                            {property.property_id}
                          </Link>
                        </td>
                        <td>
                          {property.vacant_land === "Yes"
                            ? `${property.type} - Vacant Land`
                            : property.type}
                        </td>
                        <td>
                          {property?.street_number} {property?.dir_prefix}{" "}
                          {property?.street_name}{" "}
                          {property?.street_type
                            ? " " + property?.street_type
                            : ""}{" "}
                          {property?.unit_number
                            ? ", Unit # " + property?.unit_number
                            : ""}
                        </td>
                        <td>{property?.city}</td>
                        <td>
                          {property?.state
                            ? extractStateAbbreviation(property?.state)
                            : ""}
                        </td>
                        <td>
                          {property?.vacant_land === "No" &&
                          property?.type === "Residential"
                            ? ((property?.sub_type === "Duplex" ||
                                property?.sub_type === "Fourplex") &&
                                property?.unit_details && (
                                  <h4>{addBedrooms(property?.unit_details)}</h4>
                                )) ||
                              (property?.beds_total && (
                                <h4>{property?.beds_total}</h4>
                              ))
                            : "0"}
                        </td>
                        <td>
                          {property?.vacant_land === "No" &&
                          property?.type === "Residential"
                            ? ((property?.sub_type === "Duplex" ||
                                property?.sub_type === "Fourplex") &&
                                property?.unit_details && (
                                  <h4 className="">
                                    {addBathrooms(property?.unit_details)}
                                  </h4>
                                )) ||
                              (property?.baths_full && (
                                <h4 className="">{property?.baths_full}</h4>
                              ))
                            : "0"}
                        </td>
                        <td>{property.acreage || "?"}</td>
                        <td>
                          {property?.vacant_land === "No" &&
                          property?.type === "Residential"
                            ? ((property?.sub_type === "Duplex" ||
                                property?.sub_type === "Fourplex") &&
                                property?.unit_details && (
                                  <h4 className="">
                                    {addLivingSqFt(property?.unit_details)}
                                  </h4>
                                )) ||
                              (property?.living_sqft && (
                                <h4 className="">
                                  {addCommasToPrice(property?.living_sqft)}
                                </h4>
                              ))
                            : "0"}
                        </td>
                        <td>
                          {property.listed_date
                            ? calculateDaysSince(property?.listed_date)
                            : "?"}
                        </td>
                        <td>
                          <Link
                            to={`/user/${property.user_data[0].user_id}`}
                            className="link link-primary"
                          >
                            {property.user_data[0].legal_first_name || "?"}{" "}
                            {property.user_data[0].legal_last_name || "?"}
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="text-center w-full text-gray-500"
                        colSpan="10"
                      >
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>{" "}
              </table>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Properties;
