import React from "react";
import { Copyright } from "lucide-react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="footer text-base-content max-w-[1440px] mx-auto  px-[24px] space-y-[24px] block mb-[16px]">
        <div className="grid grid-cols-3 w-full">
          <div className="space-y-[24px] col-span-1">
            <div>
              <h5 className="text-[14px] font-semibold">Contact</h5>
              <p className="text-[12px] text-[color:#3E4F68]">
                admin@myllave.com
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <nav className="space-y-[14px]">
              <h5 className="text-[14px] font-semibold">Navigation</h5>
              <ul className="space-y-[10px]">
                <li>
                  <Link
                    to="/properties"
                    className="text-[12px] text-[color:#3E5F68]"
                  >
                    Search Properties
                  </Link>
                </li>
                <li>
                  <Link
                    to="/professionals"
                    className="text-[12px] text-[color:#3E5F68]"
                  >
                    Professional Directory
                  </Link>
                </li>
                {/* <li> */}
                {/*   <Link */}
                {/*     to="/about" */}
                {/*     className="text-[12px] text-[color:#3E5F68]" */}
                {/*   > */}
                {/*     About */}
                {/*   </Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*   <Link */}
                {/*     to="/services" */}
                {/*     className="text-[12px] text-[color:#3E5F68]" */}
                {/*   > */}
                {/*     Services */}
                {/*   </Link> */}
                {/* </li> */}
              </ul>
            </nav>
          </div>
          <div className="col-span-1">
            <nav className="space-y-[14px]">
              <h5 className="text-[14px] font-semibold">Legal</h5>
              <ul className="space-y-[10px]">
                {/* <li> */}
                {/*   <Link */}
                {/*     to="/terms-of-use" */}
                {/*     className="text-[12px] text-[color:#3E5F68]" */}
                {/*   > */}
                {/*     Terms of Use */}
                {/*   </Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*   <Link */}
                {/*     to="/privacy-policy" */}
                {/*     className="text-[12px] text-[color:#3E5F68]" */}
                {/*   > */}
                {/*     Privacy Policy */}
                {/*   </Link> */}
                {/* </li> */}
                {/* <li> */}
                {/*   <Link */}
                {/*     to="/cookie-policy" */}
                {/*     className="text-[12px] text-[color:#3E5F68]" */}
                {/*   > */}
                {/*     Cookie Policy */}
                {/*   </Link> */}
                {/* </li> */}
              </ul>
            </nav>
          </div>
        </div>
        <aside className="leading-tight">
          <div className="flex gap-2 items-center">
            <img
              src={"/LlaveIcon_deliverable.png"}
              className="w-[17px] h-[15px]"
              alt="MyLlave"
            />
            <h6 className="text-[14px] font-semibold">MyLlave</h6>
            <Copyright size={15} />
          </div>
          <p className="text-[12px] text-[color:#3E4F68]">
            Any info on this site is not guaranteed to be accurate. Use this
            info at your own risk.
          </p>
        </aside>
      </footer>
    </>
  );
};

export default Footer;
