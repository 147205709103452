import { addCommasToPrice } from "./conversions";

export function calculateGrossIncome(unit_details, string) {
  if (!unit_details) return;
  let totalRent = 0;
  unit_details.forEach((unit) => {
    totalRent += Number(unit.rent) || 0;
  });
  const gross_income = addCommasToPrice(totalRent * 12);

  if (!string) {
    return `$${addCommasToPrice(gross_income)}`;
  }
  return `$${addCommasToPrice(totalRent)} / Month  * 12 =`;
}

export function calculateTotalNetIncome(
  unit_details,
  management_fee,
  maintenance,
  taxes,
  insurance,
  string,
) {
  if (!unit_details) return;
  let totalRent = 0;
  unit_details.forEach((unit) => {
    totalRent += Number(unit.rent) || 0;
  });
  const net_income =
    totalRent * 12 -
    (management_fee || 0) -
    (maintenance || 0) -
    (taxes || 0) -
    (insurance || 0);
  if (management_fee) {
    if (string) {
      return `$${addCommasToPrice(net_income)}`;
    }
    return `$${addCommasToPrice(totalRent * 12)} - $${addCommasToPrice(management_fee)} - $${addCommasToPrice(maintenance)} - $${addCommasToPrice(taxes)} - $${addCommasToPrice(insurance)} =`;
  }
  if (string) {
    return `$${addCommasToPrice(totalRent * 12)} - $${addCommasToPrice(maintenance)} - $${addCommasToPrice(taxes)} - $${addCommasToPrice(insurance)} =`;
  }
  return `$${addCommasToPrice(net_income)}`;
}

export function calculateCurrentCapRate(
  list_price,
  unit_details,
  management_fee,
  maintenance,
  taxes,
  insurance,
  string,
) {
  if (!unit_details) return;
  let totalRent = 0;
  unit_details.forEach((unit) => {
    totalRent += Number(unit.rent) || 0;
  });
  const net_income =
    totalRent * 12 -
    (management_fee || 0) -
    (maintenance || 0) -
    (taxes || 0) -
    (insurance || 0);
  const cap_rate = (net_income / list_price) * 100;
  if (string) {
    return `($${addCommasToPrice(net_income)} / $${addCommasToPrice(list_price)}) * 100% =`;
  }
  return ` ${addCommasToPrice(Number(Math.round(cap_rate + "e2") + "e-2").toFixed(2))}%`;
}
export function calculatePropertyManagementFee(fee) {
  return `$${addCommasToPrice(fee * 12)}`;
}

export function addBedrooms(units) {
  if (!units) return;
  const totalBedrooms = units.reduce(
    (acc, unit) => acc + (Number(unit.bedrooms) || 0),
    0,
  );
  return totalBedrooms;
}

export function addBathrooms(units) {
  if (!units) return;
  const totalBathrooms = units.reduce(
    (acc, unit) => acc + (Number(unit.bathrooms) || 0),
    0,
  );
  return totalBathrooms;
}

export function addLivingSqFt(units) {
  if (!units) return;
  const totalLivingSqFt = units.reduce(
    (acc, unit) => acc + (Number(unit.living_sqft) || 0),
    0,
  );
  return totalLivingSqFt;
}
export function addCommercialSqFt(
  office_sqft,
  other_sqft,
  other_bldg_sqft,
  retail_sqft,
  warehouse_sqft,
) {
  const totalLivingSqFt =
    (Number(office_sqft) || 0) +
    (Number(other_sqft) || 0) +
    (Number(other_bldg_sqft) || 0) +
    (Number(retail_sqft) || 0) +
    (Number(warehouse_sqft) || 0);

  return addCommasToPrice(totalLivingSqFt);
}

export function roundToTwoDecimals(
  list_price,
  living_sqft,
  unit_1_living_sq_ft,
  unit_2_living_sq_ft,
  unit_3_living_sq_ft,
  unit_4_living_sq_ft,
) {
  const total_sqft = living_sqft
    ? living_sqft
    : (Number(unit_1_living_sq_ft) || 0) +
      (Number(unit_2_living_sq_ft) || 0) +
      (Number(unit_3_living_sq_ft) || 0) +
      (Number(unit_4_living_sq_ft) || 0);

  const total = list_price / total_sqft;
  return Number(Math.round(total + "e2") + "e-2").toFixed(2);
}
