export function calculateDaysSince(dateString) {
  // Parse the input date string
  const date = new Date(dateString);

  const today = new Date();
  const referenceDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  // Calculate the difference in milliseconds
  const differenceMs = date - new Date(referenceDate);

  // Convert milliseconds to days
  const daysSince = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  return Math.abs(daysSince);
}

export function keysToDollars(keys) {
  // Conversion rate: 1000 keys = $5
  const conversionRate = 1 / 0.0052; // Keys per dollar

  // Calculate the dollar amount
  const dollars = keys / conversionRate;

  // Return the result
  return dollars.toFixed(2); // Rounds to 2 decimal places
}

export function addCommasToPrice(price) {
  if (!price) return;
  // Convert the price to a string
  price = price.toString();

  // Split the price into integer and decimal parts (if any)
  const parts = price.split(".");
  let integerPart = parts[0];
  const decimalPart = parts[1] ? "." + parts[1] : "";

  // Add commas to the integer part every three digits from the end
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer and decimal parts
  const formattedPrice = integerPart + decimalPart;

  return formattedPrice;
}

export function extractStateAbbreviation(input) {
  // Regular expression to match the abbreviation within parentheses
  const abbreviationRegex = /\((.*?)\)/;
  // Match the abbreviation using the regex
  const abbreviationMatch = input.match(abbreviationRegex);
  // If a match is found, return the abbreviation, else return null
  return abbreviationMatch ? abbreviationMatch[1] : null;
}
