import { HOST } from "../../../constants/HostConstants";

export const verifyEmail = async (
  token,
  { setVerified },
  { setAlreadyVerified },
  { setProfession },
) => {
  const response = await fetch(`${HOST}/api/user/verifyEmail`, {
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("error occurred", error);
    });

  console.log(response);
  if (response.statusText === "Email Address Already Verified") {
    console.log("in here");
    setVerified(true);
    return setAlreadyVerified(true);
  }
  if (response.decoded.profession) {
    setVerified(true);
    return setProfession(true);
  }

  console.log("outside");
  return setVerified(true);
};
