import { HOST } from "../constants/HostConstants";
export const currentUser = async ({ setUser }) => {
  fetch(`${HOST}/api/user/userCurrent`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: localStorage.getItem("token"),
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      setUser(data);
    });
};
