import { useEffect } from "react";
import { useStepperContext } from "../../context/MainContext";
const landFeatures = [
  "Commercial",
  "Improved",
  "Mobile Home Park",
  "Native Brush",
  "Other",
  "Residential Multi-Family",
  "Single Family",
  "Unimproved",
];

const communityAmenities = [
  "Certified 55+ Comunity",
  "Country Club",
  "Curb & Gutter",
  "Gated Entrance",
  "Golf Available",
  "Guarded Entrance",
  "Horse/Livestock Allowed",
  "Hunting Privileges",
  "None",
  "Neighborhood Watch",
  "Other",
  "Planned Unit Development",
  "Pool Privileges",
  "Property Owners' Association",
  "Sidewalks",
  "Street Lights",
  "Tennis Available",
  "Tennis Privileges",
];

const fenceType = [
  "Barbed Wire",
  "Chain Link",
  "Cross Fence",
  "Decorative Metal",
  "Electric Fence",
  "Irrigation",
  "Landscape",
  "Masonry",
  "Non Privacy",
  "None",
  "Other",
  "Partial",
  "Privacy",
  "Smooth Wire",
  "Vinyl",
  "Wood",
];

const locationFeatures = [
  "Alley",
  "Bayside",
  "Boat Dock",
  "Corner Lot",
  "Cul-de-Sac Lot",
  "Curb and Gutters",
  "Fishing Pier",
  "Flood Irrigation",
  "Gulfside",
  "Irregular Lot",
  "Mature Trees",
  "None",
  "On Golf Course",
  "On Waterfront",
  "Other",
  "Professional Landscaping",
  "Resaca",
  "Sidewalks",
  "Sprinkler System",
];

const streetSurface = ["Caliche", "Dirt", "Gravel", "Other", "Paved"];

const waterSewer = [
  "Assessment",
  "City Sewer",
  "MUD",
  "No Sewer",
  "Other",
  "Private Well",
  "Public Water",
  "Public Water Available",
  "Septic",
  "Sewer Available Not Connected",
  "Shared Well",
];

const access = [
  "City Street",
  "Country Road",
  "Dirt Road",
  "Easement Road",
  "FM Road",
  "Gravel Road",
  "Interstate Highway",
  "Navigable Waterway",
  "None",
  "Other",
  "Paved Road",
  "Private Road",
  "Railroad Siding",
  "State Highway",
  "US Highway",
];

const docsOnFile = [
  "Aerial Survey",
  "Appraisal",
  "Boundary Survey",
  "Building Restrictions",
  "Cash Flow",
  "Deed",
  "Deed Restrictions",
  "Demographics",
  "EPA Documents",
  "Feasibility Study",
  "Feasibility",
  "Floor Plan",
  "Inventory List",
  "Lease Documents",
  "Legal Description",
  "Loan Documents",
  "New Survey Lines Marked",
  "None",
  "Other",
  "Perc Test",
  "Profit and Loss",
  "Site Plan",
  "Soil Survey",
  "Survey",
  "Survey/Plat",
  "Topography Map",
  "Traffic Flow",
];

const crops = [
  "Aloe Vera",
  "Citrus",
  "Corn",
  "Cotton",
  "Grain",
  "Grass Pasture",
  "Hemp",
  "Natural Pasture",
  "None",
  "Other",
  "Row Crops",
  "Sugar Cane",
  "Vegetables",
];

const improvements = [
  "Air Strip",
  "Barn/Shed/Stable",
  "Drain Tile",
  "Garage",
  "Greenhouse",
  "House",
  "House As Is",
  "Irrigation System",
  "None",
  "Other",
  "Packing Shed",
  "Pond(s)",
  "Smoke House",
  "Tractor Shed",
  "Workshop",
];

const suitableUse = [
  "Beef Cattle",
  "Commercial",
  "Dairy",
  "Farmland",
  "Grazing",
  "Horse Farm",
  "Hunting",
  "Orchard",
  "Other",
  "Poultry",
  "Residential",
  "Subdevelopment",
  "Truck",
];

const topographyDescription = [
  "Bench Leveled",
  "Exceptional View",
  "Flood Fringe Area",
  "Flood Plain",
  "Gently Rolling",
  "Lake/Pond",
  "Level",
  "Other",
  "Partially Wooded",
  "River",
  "Rough Rolling",
  "Skipping",
  "Steep",
  "Stream/Creek",
  "Terraced",
];

const utilities = [
  "110VAC",
  "220VAC",
  "440VAC",
  "Cable TV",
  "City Garbage",
  "Electric",
  "Gas",
  "None",
  "Other",
  "Private Garbage",
  "Private Water",
  "Propane",
  "Public Water",
  "Septic",
  "Sewer",
  "Telephone",
  "Well",
  "Well Water",
];

const energyFeatures = [
  "Attic Fans",
  "Double Pane Windows",
  "Electric Water Heater",
  "Energy Star",
  "Good Cents",
  "Green Built",
  "None",
  "Other",
  "Programmable Thermostat",
  "Solar Panels (Leased Not Paid)",
  "Solar Panels (Owned)",
  "Solar Screen",
  "Storm Doors",
  "Storm Windows",
];

const laundry = [
  "Area",
  "Closet",
  "Garage",
  "Room",
  "None",
  "Outside Laundry Room",
];

const exteriorFeatures = [
  "Balcony",
  "BBQ Pit/Grill",
  "Boat Dock",
  "Boat House",
  "Boat Lift",
  "Covered Patio",
  "Deck",
  "Deck/Balcony/Porch",
  "Detatched Quarters",
  "Exercise  Room",
  "Gazebo",
  "Gutters/Spouting",
  "Horse Stables/Barn",
  "Hot Tub",
  "Hurricane Shutters",
  "Manual Gate",
  "Mature Trees",
  "Motorized Gate",
  "None",
  "Other",
  "Outdoor Kitchen",
  "Patio",
  "Patio Slab",
  "Pool",
  "Private Tennis",
  "Rock Yard",
  "Sauna",
  "Screened Patio",
  "Sprinkler System",
  "Steam Room",
  "Storage Building",
  "Workshop",
];

const exteriorSiding = [
  "Adobe",
  "Aluminum Siding",
  "Asbestos Siding",
  "Brick",
  "Concrete Block",
  "Frame/Wood",
  "Hardy Plank",
  "Masonite Siding",
  "None",
  "Other",
  "Siding",
  "Solid Masonry",
  "Stone",
  "Stucco",
  "Tilt Wall Conrete",
  "Vinyl Siding",
  "Wood",
];

const flooringType = [
  "Carpet",
  "Concrete",
  "Granite",
  "Hardwood",
  "Laminate",
  "Marble",
  "None",
  "Other",
  "Parquet",
  "Porcelain Tile",
  "Saltillo Tile",
  "Slate",
  "Stained Concrete",
  "Terrazzo Tile",
  "Tile",
  "Travertine",
  "Vinyl",
];

const garageCarport = [
  "Attached",
  "Detached",
  "Double Garage",
  "Front Entry",
  "Front / Back Entry",
  "Golf Cart",
  "No Carport",
  "No Garage",
  "Opener/Control",
  "Other",
  "Rear Entry",
  "Remote Entry",
  "Side Entry",
  "Touchpad Entry",
];

const hvac = [
  "Central",
  "Central Zoned",
  "Electric",
  "Electric Heat Pump",
  "Floor",
  "Mini-Split",
  "Natural Gas",
  "No Cooling",
  "No Heat",
  "None",
  "Oil",
  "Other",
  "Propane/Butane",
  "Radiant",
  "Solar",
  "Space",
  "Wall",
  "Window",
];

const interiorFeatures = [
  "Bay Windows",
  "Bonus Room",
  "Built-ins",
  "Cable TV",
  "CAT5 Installed",
  "Ceiling Fans",
  "Central Vacuum",
  "Crown/Cove Molding",
  "Decorative/High Ceilings",
  "Fireplace",
  "Firewall",
  "Intercom",
  "None",
  "Office/Study",
  "Other",
  "Popcorn Ceiling",
  "Skylight",
  "Smoke Alarm(s)",
  "Smooth Ceiling",
  "Split Bedrooms",
  "Walk-in Closet",
  "Washer/Dryer Connection",
  "Wet/Dry Bar",
];

const roof = [
  "Built-up/Gravel",
  "Clay Tile",
  "Composition Shingle",
  "Concrete Tile",
  "Double Composition Shingle Roof",
  "Double Roof-Mobile Home",
  "Fiberglass Tile",
  "Flat",
  "Metal",
  "None",
  "Other",
  "Shingle",
  "Slate/Tile",
];

const specialFeatures = [
  "1 Year Warranty",
  "2-10 Year Warranty",
  "Audio/Video Wiring",
  "Automated Lighting",
  "Carbon Monoxide Detectors",
  "Controlled Access",
  "Corporate Rental",
  "Furnished",
  "Home Warranty",
  "In-Wall Pest Control System",
  "Internet Access",
  "New Construction",
  "None",
  "Other",
  "Satellite System",
  "Security System",
  "Special Insulation",
  "Underslab Termite System",
  "Unfurnished",
  "Video Surveillance",
];

const waterHeater = [
  "Electric",
  "Electric Heat Pump",
  "Good Cents",
  "Natural Gas",
  "None",
  "Other",
  "Programmable Thermostat",
  "Propane/Butane",
  "Solar-Combination",
  "Tankless",
  "WH-Attic",
  "WH-Garage",
  "WH-Laundry",
  "WH-Other Location",
];

const conveyingAppliances = [
  "Cooktop-Electric Coil",
  "Cooktop-Electric Smooth",
  "Cooktop-Gas",
  "Dishwasher",
  "Dryer",
  "Garbage Disposal - Sink",
  "Ice Maker",
  "Microwave Oven",
  "No Conveying Appliances",
  "Other",
  "Oven-Convection",
  "Oven-Double",
  "Oven-Microwave",
  "Oven-Single",
  "Oven-Warning",
  "Refrigerator",
  "Refrigerator Built-in",
  "Stove/Range",
  "Stove/Range-Electric Coil",
  "Stove/Range-Electric Smooth",
  "Stove/Range-Gas",
  "Trash Compactor",
  "Washer",
  "Wine Cooler",
];

const countertops = [
  "Granite",
  "Laminate",
  "Other",
  "Other Natural Stone",
  "Quartz",
  "Solid Surface",
  "Stone",
  "Tile",
];

const foundationType = [
  "Basement",
  "Combination",
  "Crawl Space",
  "Other",
  "Pier & Bearn",
  "Pilings",
  "Slab",
];

const masterSuite = [
  "Bidet",
  "Double Vanity",
  "Downstairs Master",
  "Garden Tub",
  "His/Her Closest(s)",
  "None",
  "Other",
  "Outside Access",
  "Private Commode",
  "Separate Shower",
  "Shower Only",
  "Single Vanity",
  "Sitting Area",
  "Split Bedrooms",
  "Tub Only",
  "Tub/Shower Combo",
  "Upstairs Master",
  "Walk-in Closet(s)",
  "Whirlpool Jets",
];

const poolDetails = [
  "Above Ground",
  "Diving Board",
  "Heated",
  "In Ground",
  "Indoor Hot Tub",
  "Indoor Pool",
  "No Cleaning System",
  "Other Cleaning System",
  "Other Pool",
  "Outdoor Hot Tub",
  "Pool Area Fence",
  "Pool Area Fence",
  "Slide",
  "Valet/Caretaker",
];

const windowCoverings = [
  "Blackout Blinds",
  "Custom Drapes",
  "Drapes/Curtains",
  "Mini Blinds",
  "No Window Coverings",
  "Other",
  "Partial Window Coverings",
  "Plantation Shutters",
  "Vertical Blinds",
  "Wood/Faux Blinds",
];

const amenities = [
  "Conveyor",
  "Display Window",
  "None",
  "Other",
  "Overhead Lift",
  "Pole Sign",
  "Pool",
  "Railroad Access",
  "Roof Sign",
  "Seperate Electric Meters",
  "Seperate Water Meters",
  "Truck Scales",
];

const grounds = [
  "Decorative Lighting",
  "Irrigation",
  "Landscaped",
  "Mature Trees",
  "None",
  "Other",
  "Sidewalks",
  "Sprinkler System",
];

const location = [
  "Downtown",
  "Free Standing",
  "Industrial Park",
  "Major Shopping Mall",
  "Medical Complex",
  "Office Complex",
  "On Airport",
  "On Waterfront",
  "Other",
  "Strip Mall",
];

const parking = [
  "Blacktop",
  "Circle Drive",
  "Covered",
  "Dirt",
  "Fenced",
  "Garage",
  "Handicap",
  "None",
  "Off Street Parking",
  "Other",
  "Paved",
  "Security Guard",
  "Semi-Truck",
  "Shopping Mall",
  "Street Parking",
];

const presentUse = [
  "Auto Service",
  "Barn/Tavern",
  "Building Supplies",
  "Business Service",
  "Church",
  "Daycare",
  "Flea Market",
  "Franchise",
  "Hotel/Motel",
  "Manufacturing",
  "Medical/Dental",
  "MH/RV Park",
  "None",
  "Office",
  "Other",
  "Professional Service",
  "Restaurant",
  "Retail",
  "Warehouse",
  "Wholesale",
];

const siteFeatures = [
  "1-5 Loading Docks",
  "6-15 Loading Docks",
  "Elevator",
  "Fenced Storage",
  "Fire Plug",
  "Fire Sprinkler",
  "Handicap Access",
  "Inside Storage",
  "None",
  "Other",
  "Outside Storage",
  "Over 15 Loading Docks",
  "Restrooms-Private",
  "Restrooms-Public",
  "Security Lighting",
  "Security System",
];

const Features = ({ verifyNextStep, setVerifyNextStep }) => {
  const { propertyData, setPropertyData } = useStepperContext();

  console.log(propertyData);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      !propertyData["irrigation_water_rights"] ||
      propertyData["irrigation_water_rights"] === "" ||
      propertyData["irrigation_water_rights"] === undefined
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        irrigation_water_rights: "No",
      }));
    }
    if (
      !propertyData["golf_cart"] ||
      propertyData["golf_cart"] === "" ||
      propertyData["golf_cart"] === undefined
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        golf_cart: "No",
      }));
    }
    if (
      !propertyData["ada_accessible"] ||
      propertyData["ada_accessible"] === "" ||
      propertyData["ada_accessible"] === undefined
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        ada_accessible: "No",
      }));
    }

    if (
      !propertyData["pool"] ||
      propertyData["pool"] === "" ||
      propertyData["pool"] === undefined
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        pool: "No",
      }));
    }
    if (
      !propertyData["furnished"] ||
      propertyData["furnished"] === "" ||
      propertyData["furnished"] === undefined
    ) {
      setPropertyData((prevData) => ({
        ...prevData,
        furnished: "No",
      }));
    }

    if (!Array.isArray(propertyData["landFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, landFeatures: [] }));
    }
    if (!Array.isArray(propertyData["communityAmenities"])) {
      setPropertyData((prevData) => ({ ...prevData, communityAmenities: [] }));
    }
    if (!Array.isArray(propertyData["fenceType"])) {
      setPropertyData((prevData) => ({ ...prevData, fenceType: [] }));
    }
    if (!Array.isArray(propertyData["locationFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, locationFeatures: [] }));
    }
    if (!Array.isArray(propertyData["streetSurface"])) {
      setPropertyData((prevData) => ({ ...prevData, streetSurface: [] }));
    }
    if (!Array.isArray(propertyData["waterSewer"])) {
      setPropertyData((prevData) => ({ ...prevData, waterSewer: [] }));
    }
    if (!Array.isArray(propertyData["access"])) {
      setPropertyData((prevData) => ({ ...prevData, access: [] }));
    }
    if (!Array.isArray(propertyData["docsOnFile"])) {
      setPropertyData((prevData) => ({ ...prevData, docsOnFile: [] }));
    }
    if (!Array.isArray(propertyData["crops"])) {
      setPropertyData((prevData) => ({ ...prevData, crops: [] }));
    }
    if (!Array.isArray(propertyData["improvements"])) {
      setPropertyData((prevData) => ({ ...prevData, improvements: [] }));
    }
    if (!Array.isArray(propertyData["suitableUse"])) {
      setPropertyData((prevData) => ({ ...prevData, suitableUse: [] }));
    }
    if (!Array.isArray(propertyData["topographyDescription"])) {
      setPropertyData((prevData) => ({
        ...prevData,
        topographyDescription: [],
      }));
    }
    if (!Array.isArray(propertyData["utilities"])) {
      setPropertyData((prevData) => ({ ...prevData, utilities: [] }));
    }
    if (!Array.isArray(propertyData["energyFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, energyFeatures: [] }));
    }
    if (!Array.isArray(propertyData["laundry"])) {
      setPropertyData((prevData) => ({ ...prevData, laundry: [] }));
    }
    if (!Array.isArray(propertyData["exteriorFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, exteriorFeatures: [] }));
    }
    if (!Array.isArray(propertyData["exteriorSiding"])) {
      setPropertyData((prevData) => ({ ...prevData, exteriorSiding: [] }));
    }
    if (!Array.isArray(propertyData["flooringType"])) {
      setPropertyData((prevData) => ({ ...prevData, flooringType: [] }));
    }
    if (!Array.isArray(propertyData["garageCarport"])) {
      setPropertyData((prevData) => ({ ...prevData, garageCarport: [] }));
    }
    if (!Array.isArray(propertyData["hvac"])) {
      setPropertyData((prevData) => ({ ...prevData, hvac: [] }));
    }
    if (!Array.isArray(propertyData["interiorFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, interiorFeatures: [] }));
    }
    if (!Array.isArray(propertyData["roof"])) {
      setPropertyData((prevData) => ({ ...prevData, roof: [] }));
    }
    if (!Array.isArray(propertyData["specialFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, specialFeatures: [] }));
    }
    if (!Array.isArray(propertyData["waterHeater"])) {
      setPropertyData((prevData) => ({ ...prevData, waterHeater: [] }));
    }
    if (!Array.isArray(propertyData["conveyingAppliances"])) {
      setPropertyData((prevData) => ({ ...prevData, conveyingAppliances: [] }));
    }
    if (!Array.isArray(propertyData["countertops"])) {
      setPropertyData((prevData) => ({ ...prevData, countertops: [] }));
    }
    if (!Array.isArray(propertyData["foundationType"])) {
      setPropertyData((prevData) => ({ ...prevData, foundationType: [] }));
    }
    if (!Array.isArray(propertyData["masterSuite"])) {
      setPropertyData((prevData) => ({ ...prevData, masterSuite: [] }));
    }
    if (!Array.isArray(propertyData["poolDetails"])) {
      setPropertyData((prevData) => ({ ...prevData, poolDetails: [] }));
    }
    if (!Array.isArray(propertyData["windowCoverings"])) {
      setPropertyData((prevData) => ({ ...prevData, windowCoverings: [] }));
    }
    if (!Array.isArray(propertyData["amenities"])) {
      setPropertyData((prevData) => ({ ...prevData, amenities: [] }));
    }
    if (!Array.isArray(propertyData["grounds"])) {
      setPropertyData((prevData) => ({ ...prevData, grounds: [] }));
    }
    if (!Array.isArray(propertyData["location"])) {
      setPropertyData((prevData) => ({ ...prevData, location: [] }));
    }
    if (!Array.isArray(propertyData["parking"])) {
      setPropertyData((prevData) => ({ ...prevData, parking: [] }));
    }
    if (!Array.isArray(propertyData["presentUse"])) {
      setPropertyData((prevData) => ({ ...prevData, presentUse: [] }));
    }
    if (!Array.isArray(propertyData["siteFeatures"])) {
      setPropertyData((prevData) => ({ ...prevData, siteFeatures: [] }));
    }
  }, []);
  console.log(propertyData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPropertyData({ ...propertyData, [name]: value });
  };

  // Special case handler for updating fields for property data
  // These fields should only require Yes/No options for field
  // Requires name and value to be set for element used for
  const handleChangeCheckSpecial = (e) => {
    const { name, value } = e.target;

    if (propertyData[name] === "Yes") {
      setPropertyData({ ...propertyData, [name]: "No" });
    } else if (propertyData[name] === "No") {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    } else if (!value) {
      setPropertyData({ ...propertyData, [name]: "Yes" });
    }
    // Typically handles the data that requires text whether than checkboxes.
    else {
      setPropertyData({ ...propertyData, [name]: value });
    }
  };

  const handleChangeCheck = (e, featureCategory) => {
    const { name, value } = e.target;

    setPropertyData((prevData) => ({
      ...prevData,
      [featureCategory]: prevData[featureCategory].includes(name)
        ? prevData[featureCategory].filter((item) => item !== name)
        : [...prevData[featureCategory], name],
    }));
  };

  return (
    <div className="my-12 space-y-10">
      {/* If vacant land is selected */}
      {propertyData["vacant_land"] === "Yes" && (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit h-fit">
            <span className="label-text">Irrigation Water Rights</span>
            <input
              type="checkbox"
              className={`checkbox ${propertyData["irrigation_water_rights"] === "Yes" ? "checkbox-primary" : ""}`}
              name="irrigation_water_rights"
              checked={propertyData["irrigation_water_rights"] === "Yes"}
              onChange={handleChangeCheckSpecial}
            />
          </label>

          <label className="form-control col-span-2">
            <div className="label">
              <span className="label-text">Irrigation District</span>
            </div>
            <select
              className="select select-bordered"
              name="irrigation_district"
              value={propertyData["irrigation_district"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>CMC District #2</option>
              <option>Delta</option>
              <option>Entaglement ID</option>
              <option>H CCWC ID#9</option>
              <option>HCID#1</option>
              <option>HCID#2</option>
              <option>HCID#6</option>
              <option>HCID#13</option>
              <option>HCID#16</option>
              <option>HCMUD#1</option>
              <option>HCWC ID #18</option>
              <option>HCWC ID #19</option>
              <option>HCWID #3</option>
              <option>HCWID #5</option>
              <option>La Feria District #3</option>
              <option>Other</option>
              <option>San Benito</option>
              <option>Santa Cruz</option>
              <option>Santa Maria IDCC #4</option>
              <option>United ID</option>
              <option>Valley Acres ID</option>
            </select>
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Water Supplier</span>
            </div>
            <select
              className="select select-bordered"
              name="water_supplier"
              value={propertyData["water_supplier"] || ""}
              onChange={handleChange}
            >
              <option disabled value="default" hidden></option>
              <option></option>
              <option>Agua SUD</option>
              <option>City</option>
              <option>Delta Lake WTR District</option>
              <option>El Sauz WSC</option>
              <option>El Tanque WSC</option>
              <option>Falcon Rural WSC</option>
              <option>Hidalgo MUD #1</option>
              <option>La Feria #3</option>
              <option>Laguna Madre</option>
              <option>La Joya WSC</option>
              <option>Military Hiway WSC</option>
              <option>North Alamo WSC</option>
              <option>Other</option>
              <option>Rio WSC</option>
              <option>Sharyland WSC</option>
              <option>Starr City WCID #2</option>
              <option>Union Water Supply</option>
            </select>
          </label>

          <label className="form-control w-full col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
            <div className="label">
              <span className={`label_text`}>Water Comments</span>
            </div>
            <textarea
              className="textarea textarea-bordered w-full "
              name="water_comments"
              value={propertyData["water_comments"] || ""}
              onChange={handleChange}
            />
          </label>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Land Features</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["landFeatures"]) &&
                landFeatures.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["landFeatures"].includes(feature)}
                      className={`checkbox ${propertyData["landFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "landFeatures")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Community Amenities</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["communityAmenities"]) &&
                communityAmenities.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["communityAmenities"].includes(
                        feature,
                      )}
                      className={`checkbox ${propertyData["communityAmenities"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) =>
                        handleChangeCheck(e, "communityAmenities")
                      }
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Fence Type</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["fenceType"]) &&
                fenceType.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["fenceType"].includes(feature)}
                      className={`checkbox ${propertyData["fenceType"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "fenceType")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>

          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Location Features</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["locationFeatures"]) &&
                locationFeatures.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["locationFeatures"].includes(
                        feature,
                      )}
                      className={`checkbox ${propertyData["locationFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "locationFeatures")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Street Surface</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["streetSurface"]) &&
                streetSurface.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["streetSurface"].includes(feature)}
                      className={`checkbox ${propertyData["streetSurface"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "streetSurface")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Water Sewer</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["waterSewer"]) &&
                waterSewer.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["waterSewer"].includes(feature)}
                      className={`checkbox ${propertyData["waterSewer"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "waterSewer")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Access</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["access"]) &&
                access.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["access"].includes(feature)}
                      className={`checkbox ${propertyData["access"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "access")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Docs On File</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["docsOnFile"]) &&
                docsOnFile.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["docsOnFile"].includes(feature)}
                      className={`checkbox ${propertyData["docsOnFile"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "docsOnFile")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Crops</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["crops"]) &&
                crops.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["crops"].includes(feature)}
                      className={`checkbox ${propertyData["crops"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "crops")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Improvements</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["improvements"]) &&
                improvements.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["improvements"].includes(feature)}
                      className={`checkbox ${propertyData["improvements"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "improvements")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Suitable Use</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["suitableUse"]) &&
                suitableUse.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["suitableUse"].includes(feature)}
                      className={`checkbox ${propertyData["suitableUse"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "suitableUse")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Topograhy Description</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["topographyDescription"]) &&
                topographyDescription.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["topographyDescription"].includes(
                        feature,
                      )}
                      className={`checkbox ${propertyData["topographyDescription"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) =>
                        handleChangeCheck(e, "topographyDescription")
                      }
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
          <div className="col-span-1 space-y-5">
            <h3 className="text-19 font-medium">Utilities</h3>
            <hr />
            <div>
              {Array.isArray(propertyData["utilities"]) &&
                utilities.map((feature, index) => (
                  <label
                    key={index}
                    className="label cursor-pointer !justify-start w-fit !gap-2"
                  >
                    <input
                      type="checkbox"
                      checked={propertyData["utilities"].includes(feature)}
                      className={`checkbox ${propertyData["utilities"].includes(feature) ? "checkbox-primary" : ""}`}
                      name={feature}
                      onChange={(e) => handleChangeCheck(e, "utilities")}
                    />

                    <span className="label-text">{feature}</span>
                  </label>
                ))}
            </div>
          </div>
        </div>
      )}
      {/* If Property Is Only Matching Sale (Residential), Not Vacant */}
      {propertyData["for"] === "Sale" &&
        propertyData["type"] === "Residential" &&
        propertyData["vacant_land"] !== "Yes" && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">ADA Accessible</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["ada_accessible"] === "Yes" ? "checkbox-primary" : ""}`}
                name="ada_accessible"
                checked={propertyData["ada_accessible"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>

            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">Golf Cart</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["golf_cart"] === "Yes" ? "checkbox-primary" : ""}`}
                name="golf_cart"
                checked={propertyData["golf_cart"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>
            <label className="form-control w-full col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="label">
                <span className={`label_text`}>Non Conveyances</span>
              </div>
              <textarea
                className="textarea textarea-bordered w-full "
                name="non_conveyances"
                value={propertyData["non_conveyances"] || ""}
                onChange={handleChange}
              />
            </label>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Community Amenities</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["communityAmenities"]) &&
                  communityAmenities.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["communityAmenities"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["communityAmenities"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "communityAmenities")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Energy Features</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["energyFeatures"]) &&
                    energyFeatures.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["energyFeatures"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["energyFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "energyFeatures")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Laundry</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["laundry"]) &&
                    laundry.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["laundry"].includes(feature)}
                          className={`checkbox ${propertyData["laundry"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) => handleChangeCheck(e, "laundry")}
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Exterior Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["exteriorFeatures"]) &&
                  exteriorFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["exteriorFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["exteriorFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "exteriorFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Exterior Siding</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["exteriorSiding"]) &&
                  exteriorSiding.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["exteriorSiding"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["exteriorSiding"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "exteriorSiding")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Fence Type</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["fenceType"]) &&
                  fenceType.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["fenceType"].includes(feature)}
                        className={`checkbox ${propertyData["fenceType"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "fenceType")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Flooring Type</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["flooringType"]) &&
                  flooringType.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["flooringType"].includes(feature)}
                        className={`checkbox ${propertyData["flooringType"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "flooringType")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Garage/Carport</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["garageCarport"]) &&
                    garageCarport.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["garageCarport"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["garageCarport"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "garageCarport")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>

              <div className="space-y-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label_text`}>Garage Spaces</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="garage_spaces"
                    value={propertyData["garage_spaces"] || ""}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="space-y-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label_text`}>Carport Spaces</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="carport_spaces"
                    value={propertyData["carport_spaces"] || ""}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">HVAC</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["hvac"]) &&
                  hvac.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["hvac"].includes(feature)}
                        className={`checkbox ${propertyData["hvac"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "hvac")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Interior Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["interiorFeatures"]) &&
                  interiorFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["interiorFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["interiorFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "interiorFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Location Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["interiorFeatures"]) &&
                  locationFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["locationFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["locationFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "locationFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Roof</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["roof"]) &&
                    roof.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["roof"].includes(feature)}
                          className={`checkbox ${propertyData["roof"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) => handleChangeCheck(e, "roof")}
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
                <div className="space-y-5">
                  <h3 className="text-19 font-medium">Street Surface</h3>
                  <hr />
                  <div>
                    {Array.isArray(propertyData["streetSurface"]) &&
                      streetSurface.map((feature, index) => (
                        <label
                          key={index}
                          className="label cursor-pointer !justify-start w-fit !gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={propertyData["streetSurface"].includes(
                              feature,
                            )}
                            className={`checkbox ${propertyData["streetSurface"].includes(feature) ? "checkbox-primary" : ""}`}
                            name={feature}
                            onChange={(e) =>
                              handleChangeCheck(e, "streetSurface")
                            }
                          />

                          <span className="label-text">{feature}</span>
                        </label>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Special Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["specialFeatures"]) &&
                  specialFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["specialFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["specialFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "specialFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Water Heater</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["waterHeater"]) &&
                  waterHeater.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["waterHeater"].includes(feature)}
                        className={`checkbox ${propertyData["waterHeater"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "waterHeater")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Water Sewer</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["waterSewer"]) &&
                  waterSewer.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["waterSewer"].includes(feature)}
                        className={`checkbox ${propertyData["waterSewer"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "waterSewer")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Conveying Appliances</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["conveyingAppliances"]) &&
                  conveyingAppliances.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["conveyingAppliances"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["conveyingAppliances"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "conveyingAppliances")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Countertops</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["countertops"]) &&
                    countertops.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["countertops"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["countertops"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) => handleChangeCheck(e, "countertops")}
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Foundation Type</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["foundationType"]) &&
                    foundationType.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["foundationType"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["foundationType"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "foundationType")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Master Suite</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["masterSuite"]) &&
                  masterSuite.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["masterSuite"].includes(feature)}
                        className={`checkbox ${propertyData["masterSuite"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "masterSuite")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Pool Details</h3>
                <hr />
                {propertyData["pool"] === "Yes" && (
                  <div>
                    {Array.isArray(propertyData["poolDetails"]) &&
                      poolDetails.map((feature, index) => (
                        <label
                          key={index}
                          className="label cursor-pointer !justify-start w-fit !gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={propertyData["poolDetails"].includes(
                              feature,
                            )}
                            className={`checkbox ${propertyData["poolDetails"].includes(feature) ? "checkbox-primary" : ""}`}
                            name={feature}
                            onChange={(e) =>
                              handleChangeCheck(e, "poolDetails")
                            }
                          />

                          <span className="label-text">{feature}</span>
                        </label>
                      ))}
                  </div>
                )}
              </div>
              <div className="space-y-5">
                <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
                  <span className="label-text">
                    Click if property has a pool
                  </span>
                  <input
                    type="checkbox"
                    className={`checkbox ${propertyData["pool"] === "Yes" ? "checkbox-primary" : ""}`}
                    name="pool"
                    checked={propertyData["pool"] === "Yes"}
                    onChange={handleChangeCheckSpecial}
                  />
                </label>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Window Coverings</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["windowCoverings"]) &&
                  windowCoverings.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["windowCoverings"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["windowCoverings"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "windowCoverings")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Docs On File</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["docsOnFile"]) &&
                  docsOnFile.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["docsOnFile"].includes(feature)}
                        className={`checkbox ${propertyData["docsOnFile"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "docsOnFile")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
          </div>
        )}
      {propertyData["for"] === "Sale" &&
        propertyData["type"] === "Commercial" &&
        propertyData["vacant_land"] !== "Yes" && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">ADA Accessible</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["ada_accessible"] === "Yes" ? "checkbox-primary" : ""}`}
                name="ada_accessible"
                checked={propertyData["ada_accessible"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>
            <div className="space-y-5">
              <h3 className="text-19 font-medium">Energy Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["energyFeatures"]) &&
                  energyFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["energyFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["energyFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "energyFeatures")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Exterior Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["exteriorFeatures"]) &&
                  exteriorFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["exteriorFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["exteriorFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "exteriorFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Exterior Siding</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["exteriorSiding"]) &&
                  exteriorSiding.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["exteriorSiding"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["exteriorSiding"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "exteriorSiding")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Flooring Type</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["flooringType"]) &&
                  flooringType.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["flooringType"].includes(feature)}
                        className={`checkbox ${propertyData["flooringType"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "flooringType")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">HVAC</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["hvac"]) &&
                  hvac.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["hvac"].includes(feature)}
                        className={`checkbox ${propertyData["hvac"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "hvac")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Roof</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["roof"]) &&
                  roof.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["roof"].includes(feature)}
                        className={`checkbox ${propertyData["roof"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "roof")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Water Heater</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["waterHeater"]) &&
                  waterHeater.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["waterHeater"].includes(feature)}
                        className={`checkbox ${propertyData["waterHeater"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "waterHeater")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Access</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["access"]) &&
                  access.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["access"].includes(feature)}
                        className={`checkbox ${propertyData["access"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "access")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Docs On File</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["docsOnFile"]) &&
                  docsOnFile.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["docsOnFile"].includes(feature)}
                        className={`checkbox ${propertyData["docsOnFile"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "docsOnFile")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Amenities</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["amenities"]) &&
                  amenities.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["amenities"].includes(feature)}
                        className={`checkbox ${propertyData["amenities"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "amenities")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Grounds</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["grounds"]) &&
                  grounds.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["grounds"].includes(feature)}
                        className={`checkbox ${propertyData["grounds"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "grounds")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Location</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["location"]) &&
                  location.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["location"].includes(feature)}
                        className={`checkbox ${propertyData["location"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "location")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Parking</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["parking"]) &&
                  parking.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["parking"].includes(feature)}
                        className={`checkbox ${propertyData["parking"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "parking")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Present Use</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["presentUse"]) &&
                  presentUse.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["presentUse"].includes(feature)}
                        className={`checkbox ${propertyData["presentUse"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "presentUse")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Site Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["siteFeatures"]) &&
                  siteFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["siteFeatures"].includes(feature)}
                        className={`checkbox ${propertyData["siteFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "siteFeatures")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
          </div>
        )}
      {/* If Property Is Only Matching Sale (Residential), Not Vacant */}
      {propertyData["for"] === "Lease" &&
        propertyData["vacant_land"] !== "Yes" && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">ADA Accessible</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["ada_accessible"] === "Yes" ? "checkbox-primary" : ""}`}
                name="ada_accessible"
                checked={propertyData["ada_accessible"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>
            <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
              <span className="label-text">Furnished</span>
              <input
                type="checkbox"
                className={`checkbox ${propertyData["furnished"] === "Yes" ? "checkbox-primary" : ""}`}
                name="furnished"
                checked={propertyData["furnished"] === "Yes"}
                onChange={handleChangeCheckSpecial}
              />
            </label>

            <label className="form-control w-full col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-5">
              <div className="label">
                <span className={`label_text`}>Non Conveyances</span>
              </div>
              <textarea
                className="textarea textarea-bordered w-full "
                name="non_conveyances"
                value={propertyData["non_conveyances"] || ""}
                onChange={handleChange}
              />
            </label>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Conveying Appliances</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["conveyingAppliances"]) &&
                  conveyingAppliances.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["conveyingAppliances"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["conveyingAppliances"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "conveyingAppliances")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Community Amenities</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["communityAmenities"]) &&
                    communityAmenities.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["communityAmenities"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["communityAmenities"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "communityAmenities")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Laundry</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["laundry"]) &&
                    laundry.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["laundry"].includes(feature)}
                          className={`checkbox ${propertyData["laundry"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) => handleChangeCheck(e, "laundry")}
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Exterior Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["exteriorFeatures"]) &&
                  exteriorFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["exteriorFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["exteriorFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "exteriorFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>

            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Garage/Carport</h3>
                <hr />
                <div>
                  {Array.isArray(propertyData["garageCarport"]) &&
                    garageCarport.map((feature, index) => (
                      <label
                        key={index}
                        className="label cursor-pointer !justify-start w-fit !gap-2"
                      >
                        <input
                          type="checkbox"
                          checked={propertyData["garageCarport"].includes(
                            feature,
                          )}
                          className={`checkbox ${propertyData["garageCarport"].includes(feature) ? "checkbox-primary" : ""}`}
                          name={feature}
                          onChange={(e) =>
                            handleChangeCheck(e, "garageCarport")
                          }
                        />

                        <span className="label-text">{feature}</span>
                      </label>
                    ))}
                </div>
              </div>

              <div className="space-y-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label_text`}>Garage Spaces</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="garage_spaces"
                    value={propertyData["garage_spaces"] || ""}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="space-y-5">
                <label className="form-control w-full col-span-1">
                  <div className="label">
                    <span className={`label_text`}>Carport Spaces</span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full "
                    name="carport_spaces"
                    value={propertyData["carport_spaces"] || ""}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">HVAC</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["hvac"]) &&
                  hvac.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["hvac"].includes(feature)}
                        className={`checkbox ${propertyData["hvac"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "hvac")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Interior Features</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["interiorFeatures"]) &&
                  interiorFeatures.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["interiorFeatures"].includes(
                          feature,
                        )}
                        className={`checkbox ${propertyData["interiorFeatures"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) =>
                          handleChangeCheck(e, "interiorFeatures")
                        }
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Master Suite</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["masterSuite"]) &&
                  masterSuite.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["masterSuite"].includes(feature)}
                        className={`checkbox ${propertyData["masterSuite"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "masterSuite")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <div className="space-y-5">
                <h3 className="text-19 font-medium">Pool Details</h3>
                <hr />
                {propertyData["pool"] === "Yes" && (
                  <div>
                    {Array.isArray(propertyData["poolDetails"]) &&
                      poolDetails.map((feature, index) => (
                        <label
                          key={index}
                          className="label cursor-pointer !justify-start w-fit !gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={propertyData["poolDetails"].includes(
                              feature,
                            )}
                            className={`checkbox ${propertyData["poolDetails"].includes(feature) ? "checkbox-primary" : ""}`}
                            name={feature}
                            onChange={(e) =>
                              handleChangeCheck(e, "poolDetails")
                            }
                          />

                          <span className="label-text">{feature}</span>
                        </label>
                      ))}
                  </div>
                )}
              </div>
              <div className="space-y-5">
                <label className="label col-span-1 md:col-span-3 lg:col-span-4 gap-5 justify-start cursor-pointer w-fit">
                  <span className="label-text">Click if property has pool</span>
                  <input
                    type="checkbox"
                    className={`checkbox ${propertyData["pool"] === "Yes" ? "checkbox-primary" : ""}`}
                    name="pool"
                    checked={propertyData["pool"] === "Yes"}
                    onChange={handleChangeCheckSpecial}
                  />
                </label>
              </div>
            </div>
            <div className="col-span-1 space-y-5">
              <h3 className="text-19 font-medium">Docs On File</h3>
              <hr />
              <div>
                {Array.isArray(propertyData["docsOnFile"]) &&
                  docsOnFile.map((feature, index) => (
                    <label
                      key={index}
                      className="label cursor-pointer !justify-start w-fit !gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={propertyData["docsOnFile"].includes(feature)}
                        className={`checkbox ${propertyData["docsOnFile"].includes(feature) ? "checkbox-primary" : ""}`}
                        name={feature}
                        onChange={(e) => handleChangeCheck(e, "docsOnFile")}
                      />

                      <span className="label-text">{feature}</span>
                    </label>
                  ))}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default Features;
