import React, { useEffect, useRef } from "react";
import { useStepperContext } from "../context/MainContext";
import toast from "react-hot-toast";

const UploadWidget = ({ uploadedItems, setUploadedItems }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const dragPicture = useRef(0);
  const draggedOverPicture = useRef(0);

  function handleSort() {
    const uploadedItemsClone = [...uploadedItems];
    const temp = uploadedItems[dragPicture.current];
    uploadedItemsClone[dragPicture.current] =
      uploadedItemsClone[draggedOverPicture.current];
    uploadedItemsClone[draggedOverPicture.current] = temp;
    setUploadedItems(uploadedItemsClone);
  }

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "diur0igkv",
        uploadPreset: "thv0xa9w",
      },
      function (error, result) {
        console.log(result);
        if (result["event"] === "success") {
          setUploadedItems((current) => [...current, result["info"]["path"]]);
        }
      },
    );
    if (widgetRef.current.event === "success") {
      toast.success("Your images were uploaded");
    }
  }, []);

  return (
    <div className="space-y-5 w-full">
      <label className="form-control w-full max-w-xs">
        <div className="label">
          <span className="label-text">Add Images</span>
          <button
            className="label-text text-error underline"
            onClick={() => setUploadedItems([])}
          >
            Clear Images
          </button>
        </div>

        <button
          className="btn btn-outline"
          onClick={() => widgetRef.current.open()}
        >
          Choose Files
        </button>
      </label>
      <label className="form-control w-full ">
        <div className="label">
          <span className="label-text">
            Order Of Images (Drag & Drop to re-organize)
          </span>
        </div>

        {uploadedItems.length > 0 ? (
          <div className="flex gap-5 w-full flex-wrap">
            {uploadedItems.map((image_url, index) => (
              <div
                className="relative"
                key={index}
                draggable
                onDragStart={() => (dragPicture.current = index)}
                onDragEnter={() => (draggedOverPicture.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <img
                  className="h-[150px] w-[150px] object-cover"
                  src={`https://res.cloudinary.com/diur0igkv/image/upload/${image_url}`}
                  alt="property"
                />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-sm ml-1">No Images Uploaded</p>
        )}
      </label>
    </div>
  );
};

export default UploadWidget;
