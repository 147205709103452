import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/MainContext";
import { transactionRequest } from "../actions/transaction/transactionRequest";
import UploadAdvertisement from "../components/UploadAdvertisement";
import { advertisementRequest } from "../actions/advertisementRequest";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import {
  KeyRound,
  ShoppingCart,
  DollarSign,
  Megaphone,
  LandPlot,
  UserRoundSearch,
  HandCoins,
  Vault,
} from "lucide-react";
import { propertyByUser } from "../actions/propertyByUser";
import { addCommasToPrice } from "../helpers/conversions";
import { transactionStakeRequest } from "../actions/transaction/transactionStakeRequest";
import { stakingsByUser } from "../actions/transaction/stakingsByUser";
import { stakeWithdraw } from "../actions/transaction/stakeWithdraw";

const Store = () => {
  const { user, setUser } = useContext(UserContext);
  const nav = useNavigate();

  const [loading, setLoading] = useState(false);
  const [uploadedItems, setUploadedItems] = useState([]);
  const [results, setResults] = useState(null);
  const [promoted, setPromoted] = useState(null);
  const [advertisementType, setAdvertisementType] = useState(null);
  const [purchaseAmount, setPurchaseAmount] = useState(null);
  const [sellAmount, setSellAmount] = useState(null);
  const [stakeAmount, setStakeAmount] = useState(null);
  const [total, setTotal] = useState(0);
  const [impressions, setImpressions] = useState(null);
  const [success, setSuccess] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [redirectType, setRedirectType] = useState(null);
  const [stakeAction, setStakeAction] = useState("Stake Keys");
  const [stakeDuration, setStakeDuration] = useState(null);
  const [stakePercentage, setStakePercentage] = useState(null);
  const [willEarn, setWillEarn] = useState(null);
  const [withdraws, setWithdraws] = useState(null);
  const [withdraw, setWithdraw] = useState(null);
  const [redeemable, setRedeemable] = useState(null);
  const [taxed, setTaxed] = useState(0);
  const [stake, setStake] = useState(0);

  console.log(results);
  useEffect(() => {
    if (user === false) {
      nav("/");
    } else if (user) {
      propertyByUser(null, null, null, user?.user_id, null, { setResults });
      stakingsByUser(user?.user_id, { setWithdraws });
    }
  }, [user]);

  useEffect(() => {
    if (success === true) {
      document.getElementById("my_modal_5").showModal();
    }
  }, [success]);

  function addMonths(date, months) {
    var newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate.toISOString().slice(0, 10); // Formats the date as YYYY-MM-DD
  }

  function calculateExpectedStakeReturn(
    principal,
    monthlyInterestRate,
    months,
  ) {
    /**
     * Calculate the total keys received from staking keys with compound interest compounded monthly.
     * Tax 20% if withdrawalDate is not equal to the current date.
     *
     * @param {number} principal - The initial amount of keys staked.
     * @param {number} monthlyInterestRate - The monthly interest rate in decimal form (e.g., 0.05 for 5%).
     * @param {number} months - The number of months the keys are staked.
     * @returns {number} - The total keys received after the specified time period, considering taxation.
     */
    console.log(principal, monthlyInterestRate, months);

    // Calculate future value using compound interest formula for monthly compounding
    let futureValue =
      principal * Math.pow(1 + monthlyInterestRate / 12, 12 * months);

    return Math.round(futureValue);
  }

  function compoundedMonthlyStake(
    principal,
    monthlyInterestRate,
    monthsStaked,
    createdAt,
  ) {
    /**
     * Calculate the total keys received from staking keys with compound interest compounded monthly.
     * Tax 20% if withdrawalDate is not equal to the current date.
     *
     * @param {number} principal - The initial amount of keys staked.
     * @param {number} monthlyInterestRate - The monthly interest rate in decimal form (e.g., 0.05 for 5%).
     * @param {number} monthsStaked - The total number of months the keys were supposed to be staked for.
     * @param {Date} createdAt - The date when the stake was created.
     * @returns {Object} - An object containing the total keys received, number of keys taxed, and number of keys untaxed.
     */

    // Calculate the withdrawal date based on monthsStaked and createdAt
    const withdrawalDate = new Date(createdAt);
    withdrawalDate.setMonth(withdrawalDate.getMonth() + monthsStaked);

    // Calculate the number of months that have actually passed
    const currentDate = new Date();
    const monthsPassed = Math.max(
      0,
      Math.floor(
        (currentDate - new Date(createdAt)) / (1000 * 60 * 60 * 24 * 30),
      ),
    );

    // Calculate the effective months staked (minimum of monthsStaked and monthsPassed)
    const effectiveMonthsStaked = Math.min(monthsStaked, monthsPassed);

    // Calculate future value using compound interest formula for monthly compounding
    let futureValue =
      principal *
      Math.pow(1 + monthlyInterestRate / 12, 12 * effectiveMonthsStaked);

    // Calculate tax rate
    const taxRate = currentDate < withdrawalDate ? 0.2 : 0; // Tax rate is 20% if withdrawalDate is not equal to current date
    // const taxRate = currentDate < new Date() + 1 ? 0.2 : 0; // Tax rate is 20% if withdrawalDate is not equal to current date

    // Calculate total keys received, considering taxation
    const totalKeys =
      taxRate === 0 ? futureValue : futureValue - futureValue * taxRate;
    const taxedKeys = taxRate === 0 ? 0 : futureValue * taxRate;
    const untaxedKeys =
      taxRate === 0 ? futureValue : futureValue * (1 - taxRate);

    return {
      totalKeys: Math.round(totalKeys), // Total keys rounded to nearest whole number
      taxedKeys: Math.round(taxedKeys), // Taxed keys rounded to nearest whole number
      untaxedKeys: Math.round(untaxedKeys), // Untaxed keys rounded to nearest whole number
    };
  }

  function keysToDollars(keys) {
    // Conversion rate: 1000 keys = $5
    const conversionRate = 1 / 0.0052; // Keys per dollar

    // Calculate the dollar amount
    const dollars = keys / conversionRate;

    // Return the result
    return dollars.toFixed(2); // Rounds to 2 decimal places
  }

  const impression_limit = impressions?.split("/")[0].split(" ")[0];
  const purchaseGlobal = async () => {
    const impression_limit = impressions?.split("/")[0].split(" ")[0];
    await toast.promise(
      advertisementRequest(
        user?.user_id,
        "/" + uploadedItems[uploadedItems.length - 1],
        advertisementType,
        user?.email_address,
        "Global",
        redirect,
        redirectType,
        null,
        impression_limit,
        total,
        { setUser },
      ),
      {
        loading: "Sending advertisement request...",
        success: (data) => {
          setLoading(false);
          document.getElementById("my_modal_5").close();
          setAdvertisementType(null);
          setTotal(0);
          setImpressions(null);
          setUploadedItems([]);
          return "Advertisement Sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const purchaseProperty = async () => {
    const impression_limit = impressions?.split("/")[0].split(" ")[0];
    await toast.promise(
      advertisementRequest(
        user?.user_id,
        null,
        null,
        user?.email_address,
        "Promote Property",
        null,
        null,
        promoted,
        impression_limit,
        total,
        { setUser },
      ),
      {
        loading: "Sending advertisement request...",
        success: (data) => {
          setLoading(false);
          setPromoted(null);
          document.getElementById("my_modal_9").close();
          setTotal(0);
          setImpressions(null);
          return "Advertisement Sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const purchaseAccount = async () => {
    const impression_limit = impressions?.split("/")[0].split(" ")[0];
    await toast.promise(
      advertisementRequest(
        user?.user_id,
        null,
        null,
        user?.email_address,
        "Promote Account",
        null,
        null,
        promoted,
        impression_limit,
        total,
        { setUser },
      ),
      {
        loading: "Sending advertisement request...",
        success: (data) => {
          setLoading(false);
          setPromoted(null);
          document.getElementById("my_modal_10").close();
          setTotal(0);
          setImpressions(null);
          return "Advertisement Sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleStake = async () => {
    if (!user) {
      toast.error("No User Found");
    }
    await toast.promise(
      transactionStakeRequest(
        user?.user_id,
        user?.legal_first_name,
        user?.legal_last_name,
        user?.email_address,
        stakeAmount,
        stakeDuration,
        stakePercentage,
        { setUser },
      ),
      {
        loading: "Sending stake keys request...",
        success: (data) => {
          setLoading(false);
          setStakeAmount(null);
          setStakePercentage(null);
          setStakeAction("Stake Keys");
          setStakeDuration(null);
          setWillEarn(null);
          document.getElementById("my_modal_18").close();
          return "Stake keys request sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleWithdraw = async () => {
    if (!user) {
      toast.error("No User Found");
    }
    console.log(stake);
    await toast.promise(
      stakeWithdraw(
        stake.user_id,
        stake.stake_id,
        willEarn,
        taxed,
        stake.amount,
        {
          setUser,
        },
      ),
      {
        loading: "Sending withdraw keys request...",
        success: (data) => {
          setLoading(false);
          setStakeAmount(null);
          setStakePercentage(null);
          setStakeAction("Stake Keys");
          setStakeDuration(null);
          setWillEarn(null);
          document.getElementById("my_modal_18").close();
          return "Withdraw keys successfull";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleBuy = async () => {
    if (!user) {
      toast.error("No User Found");
    }
    await toast.promise(
      transactionRequest(
        user?.user_id,
        user?.legal_first_name,
        user?.legal_last_name,
        user?.email_address,
        "Buy",
        purchaseAmount,
        { setUser },
      ),
      {
        loading: "Sending purchase keys request...",
        success: (data) => {
          setLoading(false);
          setPurchaseAmount(null);
          document.getElementById("my_modal_12").close();
          return "Purchase keys request sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleSell = async () => {
    if (!user) {
      toast.error("No User Found");
    }
    await toast.promise(
      transactionRequest(
        user?.user_id,
        user?.legal_first_name,
        user?.legal_last_name,
        user.email_address,
        "Sell",
        sellAmount,
        { setUser },
      ),
      {
        loading: "Sending sell keys request...",
        success: (data) => {
          setLoading(false);
          setSellAmount(null);
          document.getElementById("my_modal_13").close();
          return "Sell keys request sent";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleCart = (e) => {
    const { name, value } = e.target;

    if (name === "global_impressions") {
      if (value === "100 Impressions / 100 Keys") {
        setImpressions("100 Impressions / 100 Keys");
        setTotal(100);
      }
      if (value === "500 Impressions / 200 Keys") {
        setImpressions("500 Impressions / 200 Keys");
        setTotal(200);
      }
      if (value === "1000 Impressions / 350 Keys") {
        setImpressions("1000 Impressions / 350 Keys");
        setTotal(350);
      }
      if (value === "5000 Impressions / 1000 Keys") {
        setImpressions("5000 Impressions / 1000 Keys");
        setTotal(1000);
      }
    }

    if (name === "property_impressions") {
      if (value === "100 Impressions / 500 Keys") {
        setImpressions("100 Impressions / 500 Keys");
        setTotal(500);
      }
      if (value === "250 Impressions / 1000 Keys") {
        setImpressions("250 Impressions / 1000 Keys");
        setTotal(1000);
      }
      if (value === "500 Impressions / 1750 Keys") {
        setImpressions("500 Impressions / 1750 Keys");
        setTotal(1750);
      }
      if (value === "1000 Impressions / 3000 Keys") {
        setImpressions("1000 Impressions / 3000 Keys");
        setTotal(3000);
      }
    }

    if (name === "account_promotions") {
      if (value === "250 Impressions / 1000 Keys") {
        setImpressions("250 Impressions / 1000 Keys");
        setTotal(1000);
      }
      if (value === "500 Impressions / 1750 Keys") {
        setImpressions("500 Impressions / 1750 Keys");
        setTotal(1750);
      }
      if (value === "1000 Impressions / 3000 Keys") {
        setImpressions("1000 Impressions / 3000 Keys");
        setTotal(3000);
      }
    }

    if (name === "staking") {
      if (!stakeAmount || isNaN(stakeAmount)) return;
      if (value === "1 Month") {
        setStakeDuration("1 Month");
        setStakePercentage(0.03);
        setWillEarn(calculateExpectedStakeReturn(Number(stakeAmount), 0.03, 1));
      } else if (value === "3 Months") {
        setStakeDuration("3 Months");
        setStakePercentage(0.04);
        setWillEarn(calculateExpectedStakeReturn(Number(stakeAmount), 0.04, 3));
      } else if (value === "6 Months") {
        setStakeDuration("6 Months");
        setStakePercentage(0.05);
        setWillEarn(calculateExpectedStakeReturn(Number(stakeAmount), 0.05, 6));
      } else if (value === "9 Months") {
        setStakeDuration("9 Months");
        setStakePercentage(0.06);
        setWillEarn(calculateExpectedStakeReturn(Number(stakeAmount), 0.06, 9));
      } else if (value === "12 Months") {
        setStakeDuration("12 Months");
        setStakePercentage(0.065);
        setWillEarn(
          calculateExpectedStakeReturn(Number(stakeAmount), 0.065, 12),
        );
      }
    }
    if (name === "withdrawing") {
      console.log(value);
      const selected = withdraws[value];
      setStake(withdraws[value]);
      console.log(selected);
      console.log(selected.created_at);
      if (new Date(selected.created_at) >= new Date()) {
        setWithdraw(value);
        setRedeemable(true);
        const results = compoundedMonthlyStake(
          Number(selected.amount),
          selected.percentage,
          Number(selected.duration.split(" ")[0].trim()),
          selected.created_at,
        );

        setWillEarn(results.totalKeys);
        // setWillEarn(
        //   compoundedMonthlyStake(
        //     Number(selected.amount),
        //     selected.percentage,
        //     Number(selected.duration.split(" ")[0].trim()),
        //   ),
        // );
      } else {
        console.log("in here");
        setRedeemable(false);
        setWithdraw(value);
        const results = compoundedMonthlyStake(
          Number(selected.amount),
          selected.percentage,
          Number(selected.duration.split(" ")[0].trim()),
          selected.created_at,
        );
        console.log(results);
        setWillEarn(results.totalKeys);
        setTaxed(results.taxedKeys);

        // setWillEarn(
        //   compoundedMonthlyStake(
        //     Number(selected.amount),
        //     selected.percentage,
        //     Number(selected.duration.split(" ")[0].trim()),
        //     selected.created_at,
        //   ),
        // );
      }
    }
  };

  console.log(taxed);
  function calculateCost(numberOfKeys) {
    const costPerKey = 0.0052; // Cost per key in dollars
    const totalCost = numberOfKeys * costPerKey;
    return totalCost.toFixed(2); // Round to 2 decimal places
  }

  return (
    <>
      {/* Modal For Purchasing Global Advertisements */}
      <dialog id="my_modal_5" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Global Advertisement</h3>
          <p>Select preferences for global advertisement to display</p>
          <form method="dialog" className="py-4 space-y-3">
            <div className="">
              <div className="label">
                <span className="label-text">
                  Select style of advertisement
                </span>
              </div>

              <div className="flex gap-5 flex-wrap">
                <button
                  className={`btn btn-default flex-[0.5] !btn-base-300 !px-5 !py-[50px] !flex !flex-col !h-full ${advertisementType === "Horizontal" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setUploadedItems([]);
                    setAdvertisementType("Horizontal");
                  }}
                >
                  <div
                    className={`h-[50px] w-[100px] border ${advertisementType === "Horizontal" ? "border-base-300" : "border-primary"}`}
                  ></div>
                  Horizontal
                </button>
                <button
                  className={`btn btn-default flex-[0.5] !btn-base-300 !px-5 !py-6 !flex !flex-col !h-full ${advertisementType === "Vertical" ? "btn-primary" : "hover:!btn-primary"}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setUploadedItems([]);
                    setAdvertisementType("Vertical");
                  }}
                >
                  <div
                    className={`h-[100px] w-[50px] border ${advertisementType === "Vertical" ? "border-base-300" : "border-primary"}`}
                  ></div>
                  Vertical
                </button>
              </div>
              <div className="flex justify-between gap-5 flex-wrap mt-2">
                <p className="text-sm">793x150 (w x h)</p>
                <p className="text-sm">348x669 (w x h)</p>
              </div>
            </div>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How many impressions?</span>
              </div>
              <select
                className="select select-bordered"
                name="global_impressions"
                value={impressions || ""}
                onChange={handleCart}
              >
                <option disabled selected></option>
                <option>100 Impressions / 100 Keys</option>
                <option>500 Impressions / 200 Keys</option>
                <option>1000 Impressions / 350 Keys</option>
                <option>5000 Impressions / 1000 Keys</option>
              </select>
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <div className="label-text">Upload advertisement image</div>
              </div>
              <UploadAdvertisement
                setUploadedItems={setUploadedItems}
                advertisementType={advertisementType}
                setSuccess={setSuccess}
              />
            </label>
            <label className="form-control w-full max-w-xs">
              <div className="label">
                <div className="label-text">Your Uploaded Image</div>
              </div>
              {uploadedItems.length > 0 ? (
                <img
                  className={`border border-base-300 ${advertisementType === "Horizontal" ? "w-[396.5px] h-[75px]" : "w-[174px] h-[334px]"}`}
                  src={`https://res.cloudinary.com/diur0igkv/image/upload/${uploadedItems[0]}`}
                  alt="property"
                />
              ) : (
                <p className="text-xs pl-1">No image uploaded</p>
              )}
            </label>
            <div className="space-y-0 mt-2">
              <span className="label-text">
                Where should clicking this advertisement redirect to?
              </span>
              <div className="form-control !gap-0">
                <label className="label cursor-pointer">
                  <span className="label-text text-[12px]">
                    My Llave Profile
                  </span>
                  <input
                    type="radio"
                    name="radio-10"
                    className="radio checked:bg-primary"
                    checked={
                      redirectType === "My Llave" || redirectType === null
                        ? true
                        : false
                    }
                    onClick={() => setRedirectType("My Llave")}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text text-[12px]">
                    External Website
                  </span>
                  <input
                    type="radio"
                    name="radio-10"
                    className="radio checked:bg-primary"
                    checked={redirectType === "External" ? true : false}
                    onClick={() => setRedirectType("External")}
                  />
                </label>
              </div>
            </div>
            {redirectType === "External" && (
              <div>
                <label className="form-control w-full ">
                  <div className="label">
                    <span className="label-text">
                      What is website will users be redirected to?
                    </span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    onChange={(e) => setRedirect(e.target.value)}
                  />
                </label>
              </div>
            )}
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Your Key Balance</h4>
                <p>
                  {user?.key_balance ? addCommasToPrice(user?.key_balance) : ""}{" "}
                  Keys
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Total Cost</h4>
                <p className={`${total !== 0 ? "text-error" : ""}`}>
                  {total !== 0 ? "-" : ""}
                  {total} Keys
                </p>
              </div>
            </div>

            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_5").close();
                  setAdvertisementType(null);
                  setImpressions(null);
                  setRedirectType(null);
                  setTotal(0);
                  setUploadedItems([]);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                disabled={
                  loading === false
                    ? advertisementType &&
                      uploadedItems.length > 0 &&
                      user?.key_balance - total >= 0 &&
                      total
                      ? false
                      : true
                    : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  purchaseGlobal();
                }}
              >
                Purchase Global Advertisement
              </button>
            </div>
          </form>
        </div>
      </dialog>
      {/* End Modal For Global Advertisements */}

      <dialog id="my_modal_9" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Promote Property</h3>
          <p>Select desired impressions for your property to reach</p>
          <form method="dialog" className="py-4 space-y-3">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How many impressions?</span>
              </div>
              <select
                className="select select-bordered"
                name="property_impressions"
                value={impressions || ""}
                onChange={handleCart}
              >
                <option disabled selected></option>
                <option>100 Impressions / 500 Keys</option>
                <option>250 Impressions / 1000 Keys</option>
                <option>500 Impressions / 1750 Keys</option>
                <option>1000 Impressions / 3000 Keys</option>
              </select>
            </label>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Select property to promote</span>
              </div>
              <select
                className="select select-bordered"
                name="property"
                value={promoted || ""}
                onChange={(e) => setPromoted(e.target.value)}
              >
                <option disabled selected></option>
                {results &&
                  results?.map((property, index) => (
                    <option value={property?.property_id} key={index}>
                      {property?.property_id} {property?.street_number || ""}{" "}
                      {property?.dir_prefix || ""} {property?.street_name || ""}{" "}
                      {property?.city || ""}
                      {property?.state || ""}
                      {property?.zip || ""}
                    </option>
                  ))}
              </select>
            </label>

            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Your Key Balance</h4>
                {user?.key_balance
                  ? addCommasToPrice(user?.key_balance)
                  : ""}{" "}
                Keys
              </div>
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Total Cost</h4>
                <p className={`${total !== 0 ? "text-error" : ""}`}>
                  {total !== 0 ? "-" : ""}
                  {total} Keys
                </p>
              </div>
            </div>
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_9").close();
                  setPromoted(null);
                  setImpressions(null);
                  setTotal(0);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                disabled={
                  loading === false
                    ? promoted && total && user?.key_balance - total >= 0
                      ? false
                      : true
                    : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  purchaseProperty();
                }}
              >
                Purchase Promote Property
              </button>
            </div>
          </form>
        </div>
      </dialog>

      <dialog id="my_modal_10" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Promote Account</h3>
          <p>Select desired impressions for your account to reach</p>
          <form method="dialog" className="py-4 space-y-3">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How many impressions?</span>
              </div>
              <select
                className="select select-bordered"
                name="account_promotions"
                value={impressions || ""}
                onChange={handleCart}
              >
                <option disabled selected></option>
                <option>250 Impressions / 1000 Keys</option>
                <option>500 Impressions / 1750 Keys</option>
                <option>1000 Impressions / 3000 Keys</option>
              </select>
            </label>
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Your Key Balance</h4>
                {user?.key_balance
                  ? addCommasToPrice(user?.key_balance)
                  : ""}{" "}
                Keys
              </div>
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Total Cost</h4>
                <p className={`${total !== 0 ? "text-error" : ""}`}>
                  {total !== 0 ? "-" : ""}
                  {total} Keys
                </p>
              </div>
            </div>
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_10").close();
                  setPromoted(null);
                  setImpressions(null);
                  setTotal(0);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                disabled={
                  loading === false
                    ? total && user?.key_balance - total >= 0
                      ? false
                      : true
                    : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  purchaseAccount();
                }}
              >
                Purchase Promote Account
              </button>
            </div>
          </form>
        </div>
      </dialog>

      <dialog id="my_modal_12" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Purchase keys</h3>
          <p>Request how many keys you would like to purchase</p>
          <form method="dialog" className="py-4 space-y-3">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How many keys to purchase?</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full"
                value={purchaseAmount || ""}
                onChange={(e) => setPurchaseAmount(e.target.value)}
              />
            </label>
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Total Cost</h4>
                <p className={``}>${calculateCost(purchaseAmount)}</p>
              </div>
            </div>

            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_12").close();
                  setPurchaseAmount(null);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                disabled={
                  loading === false ? (purchaseAmount ? false : true) : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleBuy();
                }}
              >
                Purchase Keys
              </button>
            </div>
          </form>
        </div>
      </dialog>

      <dialog id="my_modal_13" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Sell keys</h3>
          <p>Request how many keys you would like to sell</p>
          <form method="dialog" className="py-4 space-y-3">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">How many keys to sell?</span>
              </div>
              <input
                type="text"
                className="input input-bordered w-full"
                value={sellAmount || ""}
                onChange={(e) => setSellAmount(e.target.value)}
              />
            </label>
            <div className="space-y-1">
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Your Key Balance</h4>
                <p>
                  {user?.key_balance ? addCommasToPrice(user?.key_balance) : ""}{" "}
                  Keys
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h4 className="text-19 font-medium">Total Earnings</h4>
                <p className={``}>${calculateCost(sellAmount)}</p>
              </div>
            </div>

            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_13").close();
                  setSellAmount(null);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                disabled={
                  loading === false
                    ? sellAmount && sellAmount <= user?.key_balance
                      ? false
                      : true
                    : true
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleSell();
                }}
              >
                Sell Keys
              </button>
            </div>
          </form>
        </div>
      </dialog>

      <dialog id="my_modal_18" className="modal ">
        <div className="modal-box !overflow-y-auto">
          <h3 className="font-bold text-lg">Staking keys</h3>
          <p>Select the action you wish to take</p>
          <div role="alert" className="alert mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="stroke-info shrink-0 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span>Compounded Monthly.</span>
          </div>
          <div role="tablist" className="tabs tabs-boxed mt-4">
            <button
              role="tab"
              className={`tab ${stakeAction === "Stake Keys" ? "tab-active" : ""}`}
              onClick={() => {
                setStakeAction("Stake Keys");
                setStakeAmount(null);
                setTaxed(0);
              }}
            >
              Stake Keys
            </button>
            <button
              role="tab"
              className={`tab ${stakeAction === "Withdraw Keys" ? "tab-active" : ""}`}
              onClick={() => {
                setStakeAction("Withdraw Keys");
                setStakeAmount(null);
                setWillEarn(null);
                setWithdraw(null);
                setTaxed(0);
              }}
            >
              Withdraw Keys
            </button>
          </div>

          <form method="dialog" className="py-4 space-y-3">
            {stakeAction === "Stake Keys" && (
              <>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">
                      How many keys do you want to stake?
                    </span>
                  </div>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={stakeAmount || ""}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setStakeAmount(e.target.value);
                        setStakeDuration(null);
                        setWillEarn(null);
                      }
                    }}
                  />
                </label>
                {stakeAmount && !isNaN(stakeAmount) && (
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        How long do you want to stake your keys?
                      </span>
                    </div>
                    <select
                      className="select select-bordered"
                      name="staking"
                      value={stakeDuration || ""}
                      onChange={handleCart}
                    >
                      <option disabled selected></option>
                      <option value="1 Month">1 Month - 3%</option>
                      <option value="3 Months">3 Months - 4%</option>
                      <option value="6 Months">6 Months - 5%</option>
                      <option value="9 Months">9 Months - 6%</option>
                      <option value="12 Months">12 Months - 6.5%</option>
                    </select>
                  </label>
                )}
                <div className="flex items-center justify-between">
                  <h4 className="text-19 font-medium">Total Cost</h4>
                  <p
                    className={`${stakeAmount && stakeAmount !== 0 ? "text-error" : ""}`}
                  >
                    {stakeAmount && stakeAmount !== 0 ? "-" : ""}
                    {stakeAmount ? stakeAmount : 0} Keys
                  </p>
                </div>
                {/* <div className="flex items-center justify-between"> */}
                {/*   <h4 className="text-19 font-medium">Earn</h4> */}
                {/*   <p className={""}>{willEarn ? willEarn : 0} Keys</p> */}
                {/* </div> */}
                <div className="flex items-center justify-between">
                  <h4 className="text-19 font-medium">Keys To Receive</h4>
                  <p className={""}>
                    {stakeAmount && !isNaN(stakeAmount) && willEarn
                      ? Number(willEarn)
                      : 0}{" "}
                    Keys
                  </p>
                </div>

                <div className="space-y-1"></div>
                <div className="flex gap-2 pt-4">
                  <button
                    className="btn btn-outline btn-error"
                    type="button"
                    onClick={() => {
                      setStakeAmount(null);
                      setStakePercentage(null);
                      setStakeAction("Stake Keys");
                      setStakeDuration(null);
                      setWillEarn(null);
                      document.getElementById("my_modal_18").close();
                      setSellAmount(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary flex-[1]"
                    type="button"
                    disabled={
                      loading === false
                        ? stakeAmount &&
                          !isNaN(stakeAmount) &&
                          stakeDuration &&
                          stakePercentage &&
                          stakeAmount <= user?.key_balance
                          ? false
                          : true
                        : true
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      handleStake();
                    }}
                  >
                    Stake Keys
                  </button>
                </div>
              </>
            )}
            {stakeAction === "Withdraw Keys" && (
              <>
                {/* <div */}
                {/*   style={{ */}
                {/*     backgroundColor: "#f0f0f0", */}
                {/*     padding: "20px", */}
                {/*     textAlign: "center", */}
                {/*   }} */}
                {/* > */}
                {/*   <h2 className="font-medium text-19">Under Construction</h2> */}
                {/*   <p>This Feature Is Currently Being Developed</p> */}
                {/* </div>{" "} */}
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text">
                      Which stake would you like to withdraw?
                    </span>
                  </div>
                  <select
                    className="select select-bordered"
                    name="withdrawing"
                    value={withdraw || ""}
                    onChange={handleCart}
                  >
                    <option disabled selected></option>
                    {withdraws &&
                      withdraws.length > 0 &&
                      withdraws.map((withdraw, index) => (
                        <option value={index} key={index}>
                          {withdraw.amount} Keys -{" "}
                          {withdraw.percentage * 100 + "%"} - Redeemable -{" "}
                          {addMonths(
                            withdraw.created_at,
                            Number(withdraw.duration.split(" ")[0].trim()),
                          )}
                        </option>
                      ))}
                  </select>
                </label>
                {taxed !== 0 && (
                  <div className="flex items-center justify-between">
                    <h4 className="text-19 font-medium">Keys Taxed</h4>
                    <p className={"text-error"}>-{taxed} Keys</p>
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <h4 className="text-19 font-medium">Keys To Receive</h4>
                  <p className={""}>
                    {withdraw && willEarn ? Number(willEarn) : 0} Keys
                  </p>
                </div>

                <div className="space-y-1"></div>
                <div className="flex gap-2 pt-4">
                  <button
                    className="btn btn-outline btn-error"
                    type="button"
                    onClick={() => {
                      setWithdraw(null);
                      setWillEarn(null);
                      setStakeAmount(null);
                      setStakeAction("Stake Keys");
                      setTaxed(0);
                      document.getElementById("my_modal_18").close();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary flex-[1]"
                    type="button"
                    disabled={
                      loading === false ? (willEarn ? false : true) : true
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      handleWithdraw();
                    }}
                  >
                    Withdraw Keys
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </dialog>

      <main className="mx-auto max-w-[1024px] px-[24px] my-12 space-y-10">
        <div className="space-y-5">
          <div>
            <h3 className="text-3xl font-medium text-center">Store</h3>
            <p className="text-center text-lg">
              Buy and sell keys, or redeem for advertisements on our platform.
            </p>
          </div>
          <div className="stats sm:stats-horizontal w-full stats-vertical shadow">
            <div className="stat">
              <div className="stat-figure">
                <KeyRound size={42} />
              </div>
              <div className="stat-title">Key Balance</div>
              <div className="stat-value">
                {user?.key_balance ? addCommasToPrice(user?.key_balance) : ""}
              </div>
              <div className="stat-desc">Non-Expiring</div>
            </div>
            <div className="stat">
              <div className="stat-figure ">
                <DollarSign size={42} />
              </div>
              <div className="stat-title">Keys Value</div>
              <div className="stat-value">
                {user?.key_balance
                  ? addCommasToPrice(keysToDollars(user?.key_balance))
                  : ""}
              </div>
              <div className="stat-desc"></div>
            </div>
          </div>
        </div>

        <p className="text-center">Currently 1 key is valued at $0.0052</p>
        <div className="space-y-5">
          <h4 className="text-19 font-medium">How do I earn keys?</h4>
          <ul className="list-disc">
            <li className="ml-4">
              Account creation will earn 500 keys, however these cannot be sold
              for 1 month.
            </li>
            <li className="ml-4">Uploading a property will earn 200 keys.</li>
            <li className="ml-4">
              Marking a property as sold or leased with the corresponding
              required data will earn 100 keys.
            </li>
            <li className="ml-4">
              If a property is marked as sold or leased with you listed as the
              selling individual, you will earn 50 keys.
            </li>

            <li className="ml-4">
              You will earn 1 key per 10 impressions for each property you've
              posted
            </li>
          </ul>
        </div>

        <div className="space-y-5">
          <h4 className="text-19 font-medium">Redeem your keys</h4>
          <div className="flex gap-5 flex-wrap">
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_5").showModal()}
            >
              <Megaphone size={42} />
              <p>
                Global <br />
                Advertisements
              </p>
            </button>
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_9").showModal()}
              disabled={results && results.length > 0 ? false : true}
            >
              <LandPlot size={42} />
              <p>Promote Property</p>
            </button>
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_10").showModal()}
            >
              <UserRoundSearch size={42} />
              <p>Promote Account</p>
            </button>
          </div>
        </div>

        {/* <div className="collapse bg-base-200"> */}
        {/*   <input type="checkbox" /> */}
        {/*   <div className="collapse-title text-xl font-medium"> */}
        {/*     Redeem Options */}
        {/*   </div> */}
        {/*   <div className="collapse-content"> */}
        {/*     <div className="overflow-x-auto"> */}
        {/*       <table className="table"> */}
        {/*         {/* head */}
        {/*         <thead> */}
        {/*           <tr> */}
        {/*             <th></th> */}
        {/*             <th>Redeem</th> */}
        {/*             <th>Impressions</th> */}
        {/*             <th>Keys</th> */}
        {/*           </tr> */}
        {/*         </thead> */}
        {/*         <tbody> */}
        {/*           {/* row 1 */}
        {/*           <tr> */}
        {/*             <th>1</th> */}
        {/*             <td>Global Advertisement</td> */}
        {/*             <td>100</td> */}
        {/*             <td>100</td> */}
        {/*           </tr> */}
        {/*           {/* row 2 */}
        {/*           <tr> */}
        {/*             <th>2</th> */}
        {/*             <td>Global Advertisement</td> */}
        {/*             <td>500</td> */}
        {/*             <td>200</td> */}
        {/*           </tr> */}
        {/*           {/* row 3 */}
        {/*           <tr> */}
        {/*             <th>3</th> */}
        {/*             <td>Global Advertisement</td> */}
        {/*             <td>1000</td> */}
        {/*             <td>350</td> */}
        {/*           </tr> */}
        {/*           {/* row 4 */}
        {/*           <tr> */}
        {/*             <th>4</th> */}
        {/*             <td>Global Advertisement</td> */}
        {/*             <td>5000</td> */}
        {/*             <td>1000</td> */}
        {/*           </tr> */}
        {/*           {/* row 5 */}
        {/*           <tr> */}
        {/*             <th>5</th> */}
        {/*             <td>Promote Property</td> */}
        {/*             <td>100</td> */}
        {/*             <td>500</td> */}
        {/*           </tr> */}
        {/*           {/* row 2 */}
        {/*           <tr> */}
        {/*             <th>6</th> */}
        {/*             <td>Promote Property</td> */}
        {/*             <td>250</td> */}
        {/*             <td>1000</td> */}
        {/*           </tr> */}
        {/*           {/* row 3 */}
        {/*           <tr> */}
        {/*             <th>7</th> */}
        {/*             <td>Promote Property</td> */}
        {/*             <td>500</td> */}
        {/*             <td>1750</td> */}
        {/*           </tr> */}
        {/*           {/* row 4 */}
        {/*           <tr> */}
        {/*             <th>8</th> */}
        {/*             <td>Promote Property</td> */}
        {/*             <td>1000</td> */}
        {/*             <td>3000</td> */}
        {/*           </tr> */}
        {/*           {/* row 2 */}
        {/*           <tr> */}
        {/*             <th>9</th> */}
        {/*             <td>Promote Account</td> */}
        {/*             <td>250</td> */}
        {/*             <td>1000</td> */}
        {/*           </tr> */}
        {/*           {/* row 3 */}
        {/*           <tr> */}
        {/*             <th>10</th> */}
        {/*             <td>Promote Account</td> */}
        {/*             <td>500</td> */}
        {/*             <td>1750</td> */}
        {/*           </tr> */}
        {/*           {/* row 4 */}
        {/*           <tr> */}
        {/*             <th>11</th> */}
        {/*             <td>Promote Account</td> */}
        {/*             <td>1000</td> */}
        {/*             <td>3000</td> */}
        {/*           </tr> */}
        {/*         </tbody> */}
        {/*       </table> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
        <div className="space-y-5">
          <h4 className="text-19 font-medium">Exchange Keys</h4>
          <div className="flex gap-5 flex-wrap">
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_12").showModal()}
            >
              <ShoppingCart size={42} />
              <p>Buy Keys </p>
            </button>
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_13").showModal()}
            >
              <HandCoins size={42} />
              <p>Sell Keys</p>
            </button>
            <button
              className={`btn  btn-default !btn-base-300 !px-5 !py-6 !flex !flex-col !w-[170px] !h-[150px] hover:!btn-primary`}
              onClick={() => document.getElementById("my_modal_18").showModal()}
            >
              <Vault size={42} />
              <p>Staking Keys</p>
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default Store;
