import { HOST } from "../constants/HostConstants";
export const userAccept = async (user_id, email_address, { setPending }) => {
  console.log("in user accept");
  const response = await fetch(`${HOST}/api/user/userAccept`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      email_address,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  console.log(response);
  if (!response.success) {
    throw new Error("User not accepted");
  } else if (response.results) {
    setPending(response.results);
  }
  return response;
};

