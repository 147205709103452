import { useContext, useEffect, useState } from "react";
import Details from "../components/step/details";
import Upload from "../components/step/upload";
import Features from "../components/step/features";
import Remarks from "../components/step/remarks";
import { UseContextProvider, UserContext } from "../context/MainContext";
import { propertyCreate } from "../actions/propertyCreate";
import { useNavigate, useParams } from "react-router-dom";
import { useStepperContext } from "../context/MainContext";

const steps = [
  "Property Details",
  "Features",
  "Remarks/Info",
  "Closing Info/Media",
];
const PropertyEdit = () => {
  const { id } = useParams();
  const [active, setActive] = useState("Property Details");

  const [count, setCount] = useState(0);
  const [verifyNextStep, setVerifyNextStep] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [uploadedItems, setUploadedItems] = useState([]);

  const nav = useNavigate();

  const updateCountBackward = () => {
    setVerifyNextStep(null);
    setActive(steps[count - 1]);
    setCount(count - 1);
    // setVerifyNextStep(false);
  };
  const updateCountForward = async () => {
    if (count === 3) return;
    // setActive(steps[count + 1]);
    // setCount(count + 1);

    // Check if step is features or remarks/info (these dont have any required fields)
    if (active === "Features" || active === "Remarks/Info") {
      console.log("Inside");
      setVerifyNextStep(true);
    } else {
      setVerifyNextStep(false);
    }
  };

  useEffect(() => {
    if (verifyNextStep) {
      setActive(steps[count + 1]);
      setCount(count + 1);
      setVerifyNextStep(null);
    }
  }, [verifyNextStep]);

  useEffect(() => {
    if (
      user === false &&
      user.class_type === "Consumer" &&
      user.class_type === "Advertiser"
    ) {
      nav("/");
    }
  }, [user]);
  return (
    <>
      <main className="mx-auto max-w-[1440px]">
        <div className="border boder-base-300 rounded-md my-12 mx-[24px] py-6 px-5">
          <ul className="steps select-none steps-vertical sm:steps-horizontal w-full">
            <li className={`step step-primary`}>Property Details</li>
            <li
              className={`step ${active === "Features" || active === "Remarks/Info" || active === "Closing Info/Media" ? "step-primary" : ""}`}
            >
              Features
            </li>
            <li
              className={`step ${active === "Remarks/Info" || active === "Closing Info/Media" ? "step-primary" : ""}`}
            >
              Remarks/Info
            </li>
            <li
              className={`step ${active === "Closing Info/Media" ? "step-primary" : ""}`}
            >
              Closing Info/Media
            </li>
          </ul>
          <UseContextProvider>
            {active === "Property Details" && (
              <Details
                verifyNextStep={verifyNextStep}
                setVerifyNextStep={setVerifyNextStep}
                id={id ? id : null}
              />
            )}
            {active === "Features" && <Features />}
            {active === "Remarks/Info" && <Remarks />}
            {active === "Closing Info/Media" && (
              <Upload
                uploadedItems={uploadedItems}
                setUploadedItems={setUploadedItems}
                loading={loading}
                setLoading={setLoading}
                id={id ? id : null}
              />
            )}
          </UseContextProvider>
          <div className="flex justify-between mt-5">
            <button
              className={`btn btn-outline btn-md ${active === "Property Details" ? "" : ""}`}
              disabled={active === "Property Details" ? true : false}
              onClick={() => {
                updateCountBackward();
              }}
            >
              Back
            </button>
            <button
              className="btn btn-primary"
              disabled={loading}
              onClick={() => {
                count !== 3
                  ? updateCountForward()
                  : document.getElementById("my_modal_2").showModal();
              }}
            >
              {count === 3 ? "Upload Changes" : "Next"}
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default PropertyEdit;
