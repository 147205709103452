import { useContext } from "react";
import { HOST } from "../constants/HostConstants";
import { AlertContext } from "../context/MainContext";

export const loginUser = async (email_address, password, setUser) => {
  const response = await fetch(`${HOST}/api/user/userAuth`, {
    method: "POST",
    body: JSON.stringify({
      emailAddress: email_address,
      password,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
      // setUser(data);
      // setSuccess(true);
    })
    .catch((error) => {
      console.log("error occurred", error);
    });

  if (!response.success) {
    throw new Error("Incorrect Credentials Submitted");
  }

  const data = response;
  if (data.user) {
    setUser(data.user);
  }
  console.log(data);
  if (data.token) {
    localStorage.setItem("token", data.token);
  }
  return data;
};
