import {
  Filter,
  ChevronsLeft,
  ChevronsRight,
  Search,
  LayoutGrid,
  Table,
  X,
} from "lucide-react";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { propertyByUser } from "../../actions/propertyByUser";
import { useNavigate } from "react-router-dom";
import { propertyStatusUpdate } from "../../actions/property/specific/propertyStatusUpdate";
import toast from "react-hot-toast";
import { propertyDelete } from "../../actions/propertyDelete";
import { markAsSold } from "../../actions/property/markAsSold";
import { markAsLeased } from "../../actions/property/markAsLeased";
import { addCommasToPrice } from "../../helpers/conversions";
import { Mail } from "lucide-react";
import { getCities } from "../../actions/home/getCities";
import { properties } from "../../actions/discover/properties";

const proposedFinancing = [
  "Cash",
  "Conventional",
  "FHA",
  "Lease Available",
  "Other",
  "Owner Financing",
  "Texas Veterans",
  "VA",
];

const statuses = [
  "Active - For Sale",
  "Active - For Lease",
  "Option",
  "Pending",
  "Sold",
  "Leased",
  "Withdrawn",
  "Expired",
];

const types = ["Commercial", "Residential", "Vacant Land"];

const FilterBadge = ({ title }) => {
  return (
    <div className="badge badge-outline !border-[color:#D3D4D7] flex gap-2 !p-4">
      {title}
      <button className="hover:scale-[1.1]">
        <X size={16} />
      </button>
    </div>
  );
};

const PropertiesTab = ({ user, setUser, preview }) => {
  const [status, setStatus] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closePrice, setClosePrice] = useState(null);
  const [closeDate, setCloseDate] = useState(null);
  const [pendingDate, setPendingDate] = useState(null);
  const [buyerCcConcessions, setBuyerCcConcessions] = useState(null);
  const [ccConcessionsAmount, setCcConcessionsAmount] = useState(null);
  const [financing, setFinancing] = useState(null);
  const [nonAllowables, setNonAllowables] = useState(null);
  const [nonRealtyItems, setNonRealtyItems] = useState(null);
  const [repairs, setRepairs] = useState(null);
  const [nonConveyances, setNonConveyances] = useState(null);
  const [sellingIndividual, setSellingIndividual] = useState(null);
  const [lenderEmail, setLenderEmail] = useState(null);
  const [titleEmail, setTitleEmail] = useState(null);
  const [limit, setLimit] = useState(16);
  const nav = useNavigate();
  localStorage.removeItem("tab");

  const [results, setResults] = useState(null);
  const [sortedResults, setSortedResults] = useState(null);
  const [view, setView] = useState("Table View");
  const [cities, setCities] = useState(null);
  const [filter, setFilter] = useState({
    types: [],
    status: [],
    key_number: null,
    search: null,
    price: null,
    beds: null,
    baths: null,
    cities: [],
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);

  console.log(filter);
  useEffect(() => {
    getCities({ setCities });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [limit]);

  console.log(sortedResults);
  console.log(results);
  useEffect(() => {
    handleSearch();
  }, [page]);

  // useEffect(() => {
  //   if (filter.status.length > 0) {
  //     setFilter((prevFilter) => ({ ...prevFilter, price: null }));
  //     getMaxPrice(filter.status, { setMaxPrice });
  //   }
  // }, [filter.status]);

  useEffect(() => {
    if (results && sort) {
      let sorted;
      if (sort === "Price - Ascending") {
        sorted = [...results].sort((a, b) => a.list_price - b.list_price);
      } else if (sort === "Price - Descending") {
        sorted = [...results].sort((a, b) => b.list_price - a.list_price);
      } else if (sort === "Days On Market - Ascending") {
        sorted = [...results].sort((a, b) => {
          const aDate = new Date(a.listed_date);
          const bDate = new Date(b.listed_date);
          return aDate - bDate;
        });
      } else if (sort === "Days On Market - Descending") {
        sorted = [...results].sort((a, b) => {
          const aDate = new Date(a.listed_date);
          const bDate = new Date(b.listed_date);
          return bDate - aDate;
        });
      }
      setSortedResults(sorted);
    } else {
      setSortedResults(results);
    }
  }, [results, sort]);

  const handleMultiFilter = (e) => {
    const { name, value } = e.target;

    // Create a copy of filter[name] array
    let updatedFilter = [...filter[name]];

    if (updatedFilter.includes(value)) {
      // Remove value if it exists
      updatedFilter = updatedFilter.filter((item) => item !== value);
    } else {
      // Add value if it doesn't exist
      updatedFilter.push(value);
    }

    // Update the filter state
    setFilter({ ...filter, [name]: updatedFilter });
  };

  async function handleSearch() {
    setSortedResults(null);
    setLoading(true);
    await propertyByUser(
      filter,
      page,
      limit,
      user?.user_id,
      localStorage.getItem("token"),
      { setResults },
    );
    setLoading(false);

    localStorage.removeItem("property_status");
  }

  async function handleSort(e) {
    const sortBy = e.target.value;
    setResults(null);
    // Set sort state
    setSort(sortBy);
    if (page === 1) {
      // Call handleSearch to perform search and sorting operations
      await handleSearch();
    } else {
      // Reset page to 1
      setPage(1);
    }
  }

  function formatExpirationDate(date) {
    // Input date string
    var inputDateStr = date;

    // Create a Date object from the input string
    var inputDate = new Date(inputDateStr);

    // Extract month, day, and year from the Date object
    var month = inputDate.getMonth() + 1; // getMonth() returns 0-based index
    var day = inputDate.getDate();
    var year = inputDate.getFullYear();

    // Format the date as required (month and day without leading zeros)
    return month + "-" + day + "-" + year;
  }

  const markPropertySold = async () => {
    await toast.promise(
      markAsSold(
        closePrice,
        closeDate,
        pendingDate,
        buyerCcConcessions,
        ccConcessionsAmount,
        financing,
        nonAllowables,
        nonRealtyItems,
        repairs,
        nonConveyances,
        sellingIndividual,
        propertyId,
        user?.user_id,
        { setResults },
      ),
      {
        loading: "Marking Property As Sold...",
        success: (data) => {
          setLoading(false);
          handleSearch();
          return "Property Marked As Sold";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };
  const markPropertyLeased = async () => {
    await toast.promise(
      markAsLeased(
        closePrice,
        closeDate,
        financing,
        nonAllowables,
        nonRealtyItems,
        repairs,
        nonConveyances,
        sellingIndividual,
        propertyId,
        user?.user_id,
        { setResults },
      ),
      {
        loading: "Marking Property As Leased...",
        success: (data) => {
          setLoading(false);
          handleSearch();
          return "Property Marked As Leased";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleDelete = async () => {
    if (user === false) return;
    await toast.promise(propertyDelete(propertyId, user.user_id), {
      loading: "Deleting Property...",
      success: (data) => {
        setLoading(false);
        handleSearch();
        return "Property Deleted";
      },
      error: (err) => {
        setLoading(false);
        return err.message;
      },
    });
  };

  const handleStatusUpdate = async () => {
    if (user === false) return;
    setLoading(true);
    await toast.promise(
      propertyStatusUpdate(propertyId, status, user.user_id),
      {
        loading: "Updating Property Status...",
        success: (data) => {
          setLoading(false);
          handleSearch();
          return "Property Status Updated";
        },
        error: (err) => {
          setLoading(false);
          return err.message;
        },
      },
    );
  };

  const handleEdit = async (property_id) => {
    if (user === false) return;
    if (property_id) {
      nav(`/property-edit/${property_id}`);
    }
  };

  return (
    <>
      <dialog id="my_modal_30" className="modal">
        <div className="modal-box !overflow-y-auto space-y-5">
          <div>
            <h3 className="font-bold text-lg">Apply Filters</h3>
            <p>Select filters that best match what you&apos;re looking for</p>
          </div>
          <form className="space-y-5">
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">Status</label>
              <ul className="max-h-[122px] overflow-y-scroll mt-2 border px-4">
                {statuses.map((status, key) => (
                  <div className="form-control" key={key}>
                    <label className="label cursor-pointer">
                      <span className="label-text">{status}</span>
                      <input
                        type="checkbox"
                        name="status"
                        value={
                          status === "Active - For Sale"
                            ? "Active-Sale"
                            : status === "Active - For Lease"
                              ? "Active-Lease"
                              : status
                        }
                        className="checkbox checkbox-primary"
                        checked={filter.status.includes(
                          status === "Active - For Sale"
                            ? "Active-Sale"
                            : status === "Active - For Lease"
                              ? "Active-Lease"
                              : status,
                        )}
                        onChange={handleMultiFilter}
                      />
                    </label>
                  </div>
                ))}
              </ul>
            </div>
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">Type</label>
              <ul className="mt-2 border px-4">
                {types.map((types, key) => (
                  <div className="form-control" key={key}>
                    <label className="label cursor-pointer">
                      <span className="label-text">{types}</span>
                      <input
                        type="checkbox"
                        name="types"
                        value={types}
                        className="checkbox checkbox-primary"
                        checked={filter.types.includes(types)}
                        onChange={handleMultiFilter}
                      />
                    </label>
                  </div>
                ))}
              </ul>
            </div>
            <div className="overflow-hidden">
              <label className="text-[16px] font-medium">City</label>
              <ul className="max-h-[122px] overflow-y-scroll mt-2 border px-4">
                {cities &&
                  cities.map((city, key) => (
                    <div className="form-control" key={key}>
                      <label className="label cursor-pointer">
                        <span className="label-text">{city.city_name}</span>
                        <input
                          type="checkbox"
                          name="cities"
                          value={city.city_name}
                          className="checkbox checkbox-primary"
                          checked={filter.cities.includes(city.city_name)}
                          onChange={handleMultiFilter}
                        />
                      </label>
                    </div>
                  ))}
              </ul>
            </div>
            <div className="flex justify-between gap-5">
              <div className="">
                <div className="flex gap-2 items-center">
                  <label className="text-[16px] font-medium">Price</label>
                  <p className="text-gray-500 text-[12px]">
                    No commas or symbols
                  </p>
                </div>
                <input
                  type="text"
                  className="input input-bordered"
                  placeholder="Example: 100000"
                  onChange={(e) =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      price: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="w-full">
              <label className="form-control font-medium">Number of Beds</label>
              <select
                className="select select-bordered !placeholder-primary !placeholder-opacity-50 text-[12px] w-full"
                placeholder="Who shot first?"
                value={filter.beds ? filter.beds : ""}
                onChange={(e) =>
                  setFilter((prevFilters) => ({
                    ...prevFilters,
                    beds: e.target.value,
                  }))
                }
              >
                <option disabled value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
              </select>
            </div>
            <div className="w-full">
              <label className="form-control font-medium">
                Number of Baths
              </label>
              <select
                className="select select-bordered !placeholder-primary !placeholder-opacity-50 text-[12px] w-full"
                placeholder="Who shot first?"
                value={filter.baths ? filter.baths : ""}
                onChange={(e) =>
                  setFilter((prevFilters) => ({
                    ...prevFilters,
                    baths: e.target.value,
                  }))
                }
              >
                <option disabled value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
              </select>
            </div>
          </form>
          <div className="flex gap-2 pt-4">
            <button
              className="btn btn-outline btn-error"
              type="button"
              onClick={() => {
                document.getElementById("my_modal_30").close();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary flex-[1]"
              type="button"
              onClick={() => {
                handleSearch();
                setPage(1);
                document.getElementById("my_modal_30").close();
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </dialog>

      <dialog id="my_modal_3" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            What would you like to do with this property
          </h3>
          <p>Click outside of this window to close</p>
          <form method="dialog" className="py-4 space-y-3">
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_3").close();
                  handleDelete();
                }}
              >
                Delete
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                onClick={(e) => {
                  e.preventDefault();

                  document.getElementById("my_modal_3").close();
                  handleEdit(propertyId);
                }}
              >
                Edit
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Change Property Status</h3>
          {(status === "Sold" || status === "Leased") && (
            <div role="alert" className="alert alert-error text-white mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="stroke-current shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>
                You will not be able to edit this property after submitting
                update.
              </span>
            </div>
          )}
          <form method="dialog" className="py-4 space-y-3">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Select Status</span>
              </div>
              <select
                className="select select-bordered"
                value={status || ""}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option disabled selected></option>
                <option>Active</option>
                <option>Option</option>
                <option>Pending</option>
                <option>Sold</option>
                <option>Leased</option>
                <option>Withdrawn</option>
                <option>Temp Off Market</option>
              </select>
            </label>
            <div className="space-y-5">
              {status === "Sold" && (
                <div className="gap-2 grid grid-cols-1 md:grid-cols-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text flex items-center gap-2">
                        Close Price
                        <p className="text-sm text-gray-500">No commas</p>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder=""
                      className="input input-bordered w-full max-w-xs"
                      onChange={(e) =>
                        setClosePrice(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text">Close Date</span>
                    </div>
                    <input
                      type="date"
                      placeholder=""
                      className="input input-bordered w-full max-w-xs"
                      onChange={(e) =>
                        setCloseDate(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Pending Date</span>
                    </div>
                    <input
                      type="date"
                      placeholder=""
                      className="input input-bordered w-full"
                      onChange={(e) =>
                        setPendingDate(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Buyer CC / Concessions</span>
                    </div>
                    <select
                      className="select select-bordered w-full"
                      onChange={(e) =>
                        setBuyerCcConcessions(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option default></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>
                  {buyerCcConcessions === "Yes" && (
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text">
                          CC Concessions Amount
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder=""
                        className="input input-bordered w-full"
                        onChange={(e) =>
                          setCcConcessionsAmount(
                            e.target.value !== "" ? e.target.value : null,
                          )
                        }
                      />
                    </label>
                  )}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Financing</span>
                    </div>
                    <select
                      placeholder=""
                      className="select select-bordered w-full"
                      onChange={(e) =>
                        setFinancing(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option default></option>
                      {proposedFinancing.map((finance) => (
                        <option value={finance}>{finance}</option>
                      ))}
                    </select>
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non-Allowables</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonAllowables(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non Realty Items</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonRealtyItems(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Repairs</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setRepairs(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non Conveyances</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonConveyances(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        Choose Selling Individual
                      </span>
                    </div>
                    <select
                      className="select select-bordered"
                      value={isNaN(sellingIndividual) ? "" : sellingIndividual}
                      onChange={(e) =>
                        setSellingIndividual(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      {properties?.users.map((user) => (
                        <option value={user.user_id}>
                          {user.legal_first_name} {user.legal_last_name} -{" "}
                          {user.class_type}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        Enter Selling Individual
                      </span>
                    </div>
                    <input
                      type="text"
                      value={!isNaN(sellingIndividual) ? "" : sellingIndividual}
                      placeholder=""
                      className="input input-bordered w-full"
                      onChange={(e) =>
                        setSellingIndividual(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                </div>
              )}
              {(status === "Pending" || status === "Option") && (
                <div className="gap-2 grid grid-cols-1 ">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        Alert Preferred Lender & Title Closer
                      </span>
                    </div>
                    <a
                      href={`mailto:${lenderEmail}${lenderEmail && titleEmail ? "," : ""}${titleEmail ? titleEmail : ""}?subject=Property ${propertyId} is being marked as ${status}`}
                      className="btn btn-outline btn-primary w-full"
                    >
                      <Mail />
                      Email Alert
                    </a>
                  </label>
                </div>
              )}
              {status === "Leased" && (
                <div className="gap-2 grid grid-cols-1 md:grid-cols-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text flex items-center gap-2">
                        Close Price
                        <p className="text-sm text-gray-500">No commas</p>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder=""
                      className="input input-bordered w-full max-w-xs"
                      onChange={(e) =>
                        setClosePrice(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text">Close Date</span>
                    </div>
                    <input
                      type="date"
                      placeholder=""
                      className="input input-bordered w-full max-w-xs"
                      onChange={(e) =>
                        setCloseDate(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non-Allowables</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonAllowables(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non Realty Items</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonRealtyItems(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Repairs</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setRepairs(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">Non Conveyances</span>
                    </div>
                    <select
                      className="select select-bordered"
                      onChange={(e) =>
                        setNonConveyances(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </label>{" "}
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        Choose Selling Individual
                      </span>
                    </div>
                    <select
                      className="select select-bordered"
                      value={isNaN(sellingIndividual) ? "" : sellingIndividual}
                      onChange={(e) =>
                        setSellingIndividual(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    >
                      <option disabled selected></option>
                      {properties?.users.map((user) => (
                        <option value={user.user_id}>
                          {user.legal_first_name} {user.legal_last_name} -{" "}
                          {user.class_type}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text">
                        Enter Selling Individual
                      </span>
                    </div>
                    <input
                      type="text"
                      value={!isNaN(sellingIndividual) ? "" : sellingIndividual}
                      placeholder=""
                      className="input input-bordered w-full"
                      onChange={(e) =>
                        setSellingIndividual(
                          e.target.value !== "" ? e.target.value : null,
                        )
                      }
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_4").close();
                  setClosePrice(null);
                  setCloseDate(null);
                  setPendingDate(null);
                  setBuyerCcConcessions(null);
                  setCcConcessionsAmount(null);
                  setFinancing(null);
                  setNonAllowables(null);
                  setNonRealtyItems(null);
                  setRepairs(null);
                  setNonConveyances(null);
                  setSellingIndividual(null);
                }}
              >
                Close
              </button>
              {status === "Sold" && (
                <button
                  className="btn btn-primary flex-[1]"
                  type="button"
                  disabled={
                    buyerCcConcessions === "Yes"
                      ? closePrice &&
                        closeDate &&
                        pendingDate &&
                        ccConcessionsAmount &&
                        financing &&
                        nonAllowables &&
                        nonRealtyItems &&
                        repairs &&
                        nonConveyances
                        ? false
                        : true
                      : (buyerCcConcessions === null ||
                            buyerCcConcessions === "No") &&
                          closePrice &&
                          closeDate &&
                          pendingDate &&
                          financing &&
                          nonAllowables &&
                          nonRealtyItems &&
                          repairs &&
                          nonConveyances
                        ? false
                        : true
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("my_modal_4").close();
                    markPropertySold();
                  }}
                >
                  Update Status
                </button>
              )}
              {status === "Leased" && (
                <button
                  className="btn btn-primary flex-[1]"
                  type="button"
                  disabled={
                    closePrice &&
                    closeDate &&
                    nonAllowables &&
                    nonRealtyItems &&
                    repairs &&
                    nonConveyances
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("my_modal_4").close();
                    markPropertyLeased();
                  }}
                >
                  Update Status Leased
                </button>
              )}

              {status !== "Sold" && status !== "Leased" && (
                <button
                  className="btn btn-primary flex-[1]"
                  type="button"
                  disabled={
                    status === "Sold"
                      ? !closePrice &&
                        !closeDate &&
                        !pendingDate &&
                        !buyerCcConcessions &&
                        !ccConcessionsAmount &&
                        !financing &&
                        !nonAllowables &&
                        !nonRealtyItems &&
                        !nonRealtyItems &&
                        !repairs &&
                        !nonConveyances &&
                        !sellingIndividual
                        ? false
                        : true
                      : false
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("my_modal_4").close();
                    handleStatusUpdate();
                  }}
                >
                  Update Status
                </button>
              )}
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="border-t border-base-300 px-[24px] space-y-5 mb-12">
        <div className="my-5 w-full space-y-5">
          <h3 className="text-3xl font-medium">
            {preview ? `${user.legal_first_name}'s` : "My"} Properties
          </h3>
        </div>
        <div className="space-y-5 pt-6">
          <div className="flex gap-5 justify-between">
            <div className="flex gap-5 w-full">
              <button
                className="btn btn-outline !border-[color:#D3D4D7] "
                onClick={() =>
                  document.getElementById("my_modal_30").showModal()
                }
              >
                <Filter />
                Filters
              </button>

              <div className="relative flex-[1]">
                <input
                  className="input input-bordered w-full !pl-12 "
                  placeholder="Search properties by address or key number"
                  onChange={(e) =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      search: e.target.value,
                    }))
                  }
                />
                <Search className="absolute left-3 top-3" />
                <button
                  className="btn btn-primary btn-sm absolute right-3 top-2"
                  onClick={() => handleSearch()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-wrap gap-2"> */}
        {/*   {filter && */}
        {/*     Object.keys(filter).map((key, index) => ( */}
        {/*       <React.Fragment key={index}> */}
        {/*         {key === "types" && */}
        {/*           filter.types.length > 0 && */}
        {/*           filter.types.map((type, idx) => ( */}
        {/*             <FilterBadge */}
        {/*               key={`types-${idx}`} */}
        {/*               name={"types"} */}
        {/*               title={`Type: ${type}`} */}
        {/*             /> */}
        {/*           ))} */}
        {/*         {key === "status" && */}
        {/*           filter.status.length > 0 && */}
        {/*           filter.status.map((status, idx) => ( */}
        {/*             <FilterBadge */}
        {/*               key={`status-${idx}`} */}
        {/*               name={"status"} */}
        {/*               title={`Status: ${status}`} */}
        {/*             /> */}
        {/*           ))} */}
        {/*         {key === "key_number" && filter.key_number !== null && ( */}
        {/*           <FilterBadge */}
        {/*             key="key_number" */}
        {/*             name={"key_number"} */}
        {/*             title={`Key Number: ${filter.key_number}`} */}
        {/*           /> */}
        {/*         )} */}
        {/*         {key === "address" && filter.address !== null && ( */}
        {/*           <FilterBadge */}
        {/*             key="address" */}
        {/*             name={"address"} */}
        {/*             title={`Address: ${filter.address}`} */}
        {/*           /> */}
        {/*         )} */}
        {/*         {key === "price" && filter.price !== null && ( */}
        {/*           <FilterBadge */}
        {/*             key="price" */}
        {/*             name={"price"} */}
        {/*             title={`Price: ${filter.price}`} */}
        {/*           /> */}
        {/*         )} */}
        {/*         {key === "beds" && filter.beds !== null && ( */}
        {/*           <FilterBadge */}
        {/*             key="beds" */}
        {/*             name={"beds"} */}
        {/*             title={`Beds: ${filter.beds}`} */}
        {/*           /> */}
        {/*         )} */}
        {/*         {key === "baths" && filter.baths !== null && ( */}
        {/*           <FilterBadge */}
        {/*             key="baths" */}
        {/*             name={"baths"} */}
        {/*             title={`Baths: ${filter.baths}`} */}
        {/*           /> */}
        {/*         )} */}
        {/*         {key === "cities" && */}
        {/*           filter.cities.length > 0 && */}
        {/*           filter.cities.map((city, idx) => ( */}
        {/*             <FilterBadge */}
        {/*               key={`cities-${idx}`} */}
        {/*               name={"cities"} */}
        {/*               title={`City: ${city}`} */}
        {/*             /> */}
        {/*           ))} */}
        {/*       </React.Fragment> */}
        {/*     ))} */}
        {/* </div> */}
        <div className="justify-between items-end flex pt-5 gap-5 md:flex-row flex-col-reverse">
          <div className="flex flex-col gap-5">
            <div className="flex gap-2">
              <p>
                Displaying properties:{" "}
                {sortedResults
                  ? page === 1
                    ? `1-${Math.min(limit, sortedResults.length)}`
                    : `${(page - 1) * limit + 1}-${Math.min(page * limit + 2, (page - 1) * limit + sortedResults.length)}`
                  : ""}
              </p>
            </div>

            <div className="join">
              <button
                className="join-item btn btn-outline !border-base-300"
                disabled={page !== 1 ? false : true}
                onClick={() => {
                  if (page !== 1) {
                    setPage(page - 1);
                  }
                }}
              >
                <ChevronsLeft size={16} />
              </button>
              <button className="join-item btn btn-outline !border-base-300">
                Page {page}
              </button>
              <button
                disabled={
                  sortedResults && sortedResults.length >= 16 ? false : true
                }
                className="join-item btn btn-outline !border-base-300"
                onClick={() => setPage(page + 1)}
              >
                <ChevronsRight size={16} />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap gap-5">
            {/* <div className="flex gap-5 flex-wrap items-center px-4 rounded-md"> */}
            {/*   <p>Show As</p> */}
            {/*   <button */}
            {/*     className={`btn btn-default !btn-base-300 btn-sm ${view === "Grid View" ? "btn-primary" : "hover:!btn-primary"}`} */}
            {/*     onClick={() => setView("Grid View")} */}
            {/*   > */}
            {/*     <LayoutGrid /> */}
            {/*     Grid */}
            {/*   </button> */}
            {/*   <button */}
            {/*     className={`btn btn-default !btn-base-300 btn-sm ${view === "Table View" ? "btn-primary" : "hover:!btn-primary"}`} */}
            {/*     onClick={() => setView("Table View")} */}
            {/*   > */}
            {/*     <Table /> */}
            {/*     Table */}
            {/*   </button> */}
            {/* </div> */}

            <div className="flex gap-5">
              <label className="form-control w-fit max-w-xs">
                <select
                  className="select select-bordered w-fit max-w-xs"
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                  }}
                >
                  <option disabled selected>
                    Page Limit
                  </option>
                  <option>16</option>
                  <option>32</option>
                  <option>48</option>
                  <option>64</option>
                </select>
              </label>
              <label className="form-control flex-[1] w-fit">
                <select
                  className="select select-bordered"
                  onChange={(e) => handleSort(e)}
                >
                  <option disabled selected>
                    Sort By
                  </option>
                  <option>Price - Ascending</option>
                  <option>Price - Descending</option>
                  <option>Days On Market - Ascending</option>
                  <option>Days On Market - Descending</option>
                </select>
              </label>
            </div>
          </div>
        </div>
        {sortedResults && sortedResults.length > 0 ? (
          <div className="overflow-auto">
            <table className="table">
              {/* table head */}
              <thead>
                <tr>
                  <th></th>
                  <th>Edit Status</th>
                  <th>Status</th>
                  <th>For</th>
                  <th>Type</th>
                  <th>Expiration</th>
                  <th>Property Id</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>Price</th>
                  <th>Views</th>
                  <th>Keys Generated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="overflow-y-visible">
                {/* table rows */}
                {sortedResults.map((property, index) => (
                  <tr key={index} className="overflow-y-visible">
                    <td>{index + 1}</td>
                    <td>
                      <button
                        className="btn btn-default btn-sm w-full"
                        disabled={
                          property.property_status === "Sold" ||
                          property.property_status === "Leased" ||
                          property.property_status === "Expired"
                            ? true
                            : false
                        }
                        onClick={() => {
                          setPropertyId(property.property_id);
                          setStatus(property.property_status);
                          if (property.lender_email) {
                            setLenderEmail(property.lender_email);
                          }
                          if (property.title_closer_email) {
                            setTitleEmail(property.title_closer_email);
                          }
                          document.getElementById("my_modal_4").showModal();
                        }}
                      >
                        {property?.property_status === "Sold"
                          ? "Sold"
                          : property?.property_status === "Leased"
                            ? "Leased"
                            : property?.property_status === "Expired"
                              ? "Expired"
                              : "Change Status"}
                      </button>
                    </td>
                    <td>
                      <div
                        className={`p-2 text-center rounded-md ${
                          property.property_status === "Active"
                            ? "bg-green-500"
                            : property?.property_status === "Sold" ||
                                property?.property_status === "Leased"
                              ? "bg-red-500"
                              : property?.property_status === "Option" ||
                                  property?.property_status === "Pending"
                                ? "bg-orange-500"
                                : property?.property_status === "Withdrawn" ||
                                    property?.property_status === "Expired"
                                  ? "bg-purple-400"
                                  : ""
                        }`}
                      >
                        {property.property_status}
                      </div>
                    </td>
                    <td>{property?.for}</td>
                    <td>
                      {property.vacant_land === "Yes"
                        ? `${property.type} - Vacant Land`
                        : property.type}
                    </td>
                    <td>
                      {property.expiration_date ? (
                        formatExpirationDate(property.expiration_date)
                      ) : (
                        <p className="text-error font-semibold">Missing</p>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/property/${property.property_id}`}
                        className="link link-primary"
                      >
                        {property.property_id}
                      </Link>
                    </td>
                    <td>
                      {property.street_number} {property.dir_prefix}{" "}
                      {property.street_name} {property.street_type}{" "}
                      {property.unit_number
                        ? "Unit # " + property.unit_number
                        : ""}{" "}
                      {property.dir_suffix}
                    </td>
                    <td>{property.city}</td>
                    <td>
                      $
                      {property.list_price
                        ? addCommasToPrice(property.list_price)
                        : "?"}
                    </td>
                    <td>{property.impressions}</td>
                    <td>{property.keys}</td>
                    <td className="overflow-y-visible">
                      <button
                        className="btn btn-default btn-sm"
                        disabled={
                          property.property_status === "Sold" ||
                          property.property_status === "Leased" ||
                          property.property_status === "Expired"
                            ? true
                            : false
                        }
                        onClick={() => {
                          setPropertyId(property.property_id);
                          document.getElementById("my_modal_3").showModal();
                        }}
                      >
                        Edit Property
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : loading ? (
          <div className="w-full min-h-screen">
            <div className="h-[250px] flex justify-center items-center gap-4">
              <h3>Looking for properties</h3>
              <span className="loading loading-dots min-h-screen text-neutral loading-md"></span>
            </div>
          </div>
        ) : (
          <p className="mt-5 text-neutral text-center">No properties found</p>
        )}
      </div>
    </>
  );
};

export default PropertiesTab;
