import React, { useEffect, useState } from "react";

import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";

export default function PieChartStaking({ dataValues }) {
  if (!dataValues) return;
  let alteredArary = [dataValues, 1000000000 - dataValues];

  const data = {
    labels: [`Keys Staked`, "Keys Unstaked"],
    datasets: [
      {
        label: "# of Votes",
        data: alteredArary,
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} options={{ maintainAspectRatio: false }} />;
}
