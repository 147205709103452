import { HOST } from "../../constants/HostConstants";

export const transactionRequest = async (
  user_id,
  first_name,
  last_name,
  email_address,
  option,
  amount,
  { setUser },
) => {
  const response = await fetch(`${HOST}/api/transaction/transactionRequest`, {
    method: "POST",
    body: JSON.stringify({
      user_id,
      first_name,
      last_name,
      email_address,
      option,
      amount,
    }),
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  const data = response;
  if (!data.success) {
    throw new Error(`${option} request not sent`);
  } else if (data.user) {
    if (option === "Sell") {
      setUser(data.user);
    }
  }
  return data;
};
