import { HOST } from "../../../constants/HostConstants";
export const propertyByIdByUser = async ({ setPropertyData }, id, user_id) => {
  console.log(user_id);
  const response = await fetch(`${HOST}/api/property/propertyByIdByUser`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      id: id,
      user_id: user_id,
    }),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    });

  if (!response.success) {
    setPropertyData(false);
  } else {
    setPropertyData(response.property);
    return response.property;
  }
};
