import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ urls }) => {
  const [urlPlay, setUrlPlay] = useState(urls);
  const [extraUrl, setExtraUrl] = useState(null);

  useEffect(() => {
    // Check if multiple URLs are provided
    if (urls && urls.includes(",")) {
      setUrlPlay(urls.split(",")[0].trim()); // Set the first URL to play initially
      setExtraUrl(urls); // Store the rest of the URLs for buttons
    } else {
      setUrlPlay(urls);
    }
  }, [urls]); // Update when the 'urls' prop changes
  console.log(extraUrl);
  console.log(urlPlay);

  const handleUrlChange = (new_url) => {
    setUrlPlay(new_url);
  };
  return (
    <div className="gap-3 grid grid-cols-4 w-full mx-auto relative mt-5">
      <div className="col-span-4 w-full relative">
        <div className="relative space-x-5 ">
          {extraUrl &&
            extraUrl.split(",").map((url, index) => (
              <button
                key={index}
                className={`btn ${urlPlay === url.trim() ? "btn-primary" : ""}`}
                onClick={() => handleUrlChange(url.trim())}
              >
                Video {index + 1}
              </button>
            ))}
        </div>

        {!urlPlay.includes(",") && (
          <div className="h-full">
            <ReactPlayer
              className="mt-4"
              width="100%"
              height="100%"
              url={urlPlay}
              controls
              config={{
                file: {
                  attributes: {
                    crossOrigin: "true",
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
