import React, { useEffect, useRef } from "react";
import { MdEdit } from "react-icons/md";
import toast from "react-hot-toast";

const UploadAdvertisement = ({
  setUploadedItems,
  setSuccess,
  advertisementType,
}) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "diur0igkv",
        uploadPreset: "thv0xa9w",
      },
      function (error, result) {
        console.log(result);

        if (result["event"] === "success") {
          console.log("closing");
          setUploadedItems((current) => [...current, result["info"]["path"]]);
          console.log("in the other success");
          toast.success("Your image was uploaded");
          widgetRef.current.close();
          document.getElementById("my_modal_5").showModal();
        }
      },
    );
    if (widgetRef.current.event === "success") {
      console.log("in the other success");
      toast.success("Your image was uploaded");
      widgetRef.current.close();
      document.getElementById("my_modal_5").showModal();
    }
  }, []);
  return (
    <button
      className="btn btn-outline"
      disabled={advertisementType ? false : true}
      onClick={(e) => {
        e.preventDefault();
        document.getElementById("my_modal_5").close();
        widgetRef.current.open();
      }}
    >
      Choose Files
    </button>
  );
};

export default UploadAdvertisement;
