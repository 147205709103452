import React, { useEffect, useState } from "react";
import { userPending } from "../actions/userPending";
import { FcCheckmark, FcEmptyTrash, FcFullTrash } from "react-icons/fc";
import { FcDeleteColumn } from "react-icons/fc";
import { userAccept } from "../actions/userAccept";
import { advertisementPending } from "../actions/advertisementPending";
import { advertisementAccept } from "../actions/advertisementAccept";
import { Link } from "react-router-dom";
import { BsTrashFill } from "react-icons/bs";
import { userDelete } from "../actions/userDelete";
import { advertisementDelete } from "../actions/advertisementDelete";
import { transactionGet } from "../actions/transactionGet";
import { transactionBuy } from "../actions/transactionBuy";
import { transactionSell } from "../actions/transactionSell";
import { transactionDelete } from "../actions/transactionDelete";
import { Ellipsis, Check, X } from "lucide-react";
import toast from "react-hot-toast";
import { stakingPending } from "../actions/transaction/stakingPending";
import { stakingAccept } from "../actions/transaction/stakingAccept";
import { transactionUpdate } from "../actions/transaction/transactionUpdate";

const Admin = () => {
  const [pending, setPending] = useState(null);
  const [pendingFilter, setPendingFilter] = useState("Users");
  const [amount, setAmount] = useState(null);
  const [wordFromCreator, setWordFromCreator] = useState(null);
  console.log(amount);

  localStorage.removeItem("tab");
  const [advertisements, setAdvertisements] = useState(null);

  useEffect(() => {
    if (pendingFilter === "Users") {
      userPending({ setPending });
    } else if (pendingFilter === "Advertisements") {
      advertisementPending({ setPending });
    } else if (pendingFilter === "Transactions") {
      transactionGet({ setPending });
    } else if (pendingFilter === "Staking") {
      stakingPending({ setPending });
    }
  }, [pendingFilter]);

  const handleUpdateLiquidity = async () => {
    await toast.promise(transactionUpdate(amount, { setPending }), {
      loading: "Updating Liquidity...",
      success: (data) => {
        return "Liquidity Updated";
      },
      error: (err) => {
        return err.message;
      },
    });
  };

  const handleAcceptUser = async (user_id, email_address) => {
    console.log("in accept user");
    await toast.promise(userAccept(user_id, email_address, { setPending }), {
      loading: "Accepting user...",
      success: (data) => {
        document.getElementById("my_modal_3").close();
        return "User accepted";
      },
      error: (err) => {
        return err.message;
      },
    });
  };

  const handleAcceptAdvertisement = async (advertisement_id, email_address) => {
    await toast.promise(
      advertisementAccept(advertisement_id, email_address, setPending),
      {
        loading: "Accepting Advertisement...",
        success: (data) => {
          document.getElementById("my_modal_3").close();
          return "Advertisement Accepted";
        },
        error: (err) => {
          return err.message;
        },
      },
    );
  };
  const handleDeleteUser = async (user_id, email_address) => {
    await toast.promise(userDelete(user_id, email_address, { setPending }), {
      loading: "Denying user...",
      success: (data) => {
        document.getElementById("my_modal_3").close();
        return "User denied";
      },
      error: (err) => {
        return err.message;
      },
    });
  };
  const handleDeleteAd = async (
    advertisement_id,
    user_id,
    email_address,
    cost,
  ) => {
    await toast.promise(
      advertisementDelete(
        advertisement_id,
        user_id,
        email_address,
        cost,
        setPending,
      ),
      {
        loading: "Denying advertisement...",
        success: (data) => {
          return "Advertisement denied";
        },
        error: (err) => {
          return err.message;
        },
      },
    );
  };

  const handleAcceptTransaction = async (
    transaction_id,
    user_id,
    email_address,
    cost,
    option,
  ) => {
    if (option === "Buy") {
      await toast.promise(
        transactionBuy(transaction_id, user_id, email_address, cost, {
          setPending,
        }),
        {
          loading: "Accepting transaction...",
          success: (data) => {
            return "Transaction accepted";
          },
          error: (err) => {
            return err.message;
          },
        },
      );
    } else {
      await toast.promise(
        transactionSell(transaction_id, user_id, email_address, cost, {
          setPending,
        }),
        {
          loading: "Accepting transaction...",
          success: (data) => {
            return "Transaction accepted";
          },
          error: (err) => {
            return err.message;
          },
        },
      );
    }
  };
  const handleDeleteTransaction = async (
    transaction_id,
    user_id,
    email_address,
    cost,
    option,
  ) => {
    await toast.promise(
      transactionDelete(transaction_id, user_id, email_address, cost, option, {
        setPending,
      }),
      {
        loading: "Denying transaction...",
        success: (data) => {
          document.getElementById("my_modal_3").close();
          return "Transaction denied";
        },
        error: (err) => {
          return err.message;
        },
      },
    );
  };

  return (
    <>
      <dialog id="my_modal_3" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            What would you like to do with this pending{" "}
            {pendingFilter.toLowerCase().substring(0, pendingFilter.length - 1)}
          </h3>
          <p>Click outside of this window to close</p>
          <form method="dialog" className="py-4 space-y-3">
            <div className="flex gap-2 pt-4">
              <button
                className="btn btn-outline btn-error"
                type="button"
                onClick={() => {
                  document.getElementById("my_modal_3").close();
                  if (pendingFilter === "Users") {
                    handleDeleteUser();
                  } else if (pendingFilter === "Advertisements") {
                    handleDeleteAd();
                  } else if (pendingFilter === "Transactions") {
                    handleDeleteTransaction();
                  }
                }}
              >
                Deny
              </button>
              <button
                className="btn btn-primary flex-[1]"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("my_modal_3").close();
                  if (pendingFilter === "Users") {
                    handleAcceptUser();
                  } else if (pendingFilter === "Advertisements") {
                    handleAcceptAdvertisement();
                  } else if (pendingFilter === "Transactions") {
                    handleAcceptTransaction();
                  }
                }}
              >
                Accept
              </button>
            </div>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <div className="mt-12 space-y-10 max-w-[1440px] mx-auto px-[24px]">
        {/* <div className="space-y-3"> */}
        {/*   <h3 className="text-3xl font-medium">Database</h3> */}
        {/*   <div className="hidden gap-5 flex-wrap md:flex"> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${databaseFilter === "Users" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setDatabaseFilter("Users")} */}
        {/*     > */}
        {/*       Users */}
        {/*     </button> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${databaseFilter === "Properties" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setDatabaseFilter("Properties")} */}
        {/*     > */}
        {/*       Properties */}
        {/*     </button> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${databaseFilter === "Advertisements" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setDatabaseFilter("Advertisements")} */}
        {/*     > */}
        {/*       Advertisements */}
        {/*     </button> */}
        {/*     <button */}
        {/*       className={`btn btn-sm ${databaseFilter === "Transactions" ? "btn-primary" : "hover:!btn-primary"}`} */}
        {/*       onClick={() => setDatabaseFilter("Transactions")} */}
        {/*     > */}
        {/*       Transactions */}
        {/*     </button> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <hr /> */}
        <div className="space-y-3">
          <h3 className="text-3xl font-medium">Pending</h3>
          <div className="gap-5 flex-wrap flex">
            <button
              className={`btn btn-sm ${pendingFilter === "Users" ? "btn-primary" : "hover:!btn-primary"}`}
              onClick={() => {
                setPendingFilter("Users");
                setPending(null);
              }}
            >
              Users
            </button>
            <button
              className={`btn btn-sm ${pendingFilter === "Advertisements" ? "btn-primary" : "hover:!btn-primary"}`}
              onClick={() => {
                setPendingFilter("Advertisements");
                setPending(null);
              }}
            >
              Advertisements
            </button>
            <button
              className={`btn btn-sm ${pendingFilter === "Transactions" ? "btn-primary" : "hover:!btn-primary"}`}
              onClick={() => {
                setPendingFilter("Transactions");
                setPending(null);
              }}
            >
              Transactions
            </button>
            {/* <button */}
            {/*   className={`btn btn-sm ${pendingFilter === "Staking" ? "btn-primary" : "hover:!btn-primary"}`} */}
            {/*   onClick={() => { */}
            {/*     setPendingFilter("Staking"); */}
            {/*     setPending(null); */}
            {/*   }} */}
            {/* > */}
            {/*   Staking */}
            {/* </button> */}
          </div>
          {pendingFilter === "Users" && (
            <div className="overflow-auto pt-5">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>User Id</th>
                    <th>Email</th>
                    <th>Class Type</th>
                    <th>Profession ID</th>
                    <th>Full Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-visible">
                  {/* row 1 */}
                  {pending && pending.length > 0 ? (
                    pending.map((user, index) => (
                      <tr key={index} className="overflow-y-visible">
                        <th>{index + 1}</th>
                        <td>
                          <Link to={`/user/${user.user_id}`}>
                            {user.user_id}
                          </Link>
                        </td>
                        <td>{user.email_address}</td>
                        <td>{user.class_type}</td>
                        <td>{user.profession_id || user.nmls_number}</td>
                        <td>
                          {user.legal_first_name} {user.legal_last_name}
                        </td>
                        <td className="overflow-y-visible">
                          {/* <button */}
                          {/*   className="" */}
                          {/*   onClick={() => { */}
                          {/*     setUserId(user.user_id); */}
                          {/*     setEmailAddress(user.email_address); */}
                          {/*     document.getElementById("my_modal_3").showModal(); */}
                          {/*   }} */}
                          {/* > */}
                          {/*   <Ellipsis /> */}
                          {/* </button> */}
                          <div className="flex gap-3">
                            <button
                              className="btn-xs btn rounded-full btn-success"
                              onClick={() =>
                                handleAcceptUser(
                                  user.user_id,
                                  user.email_address,
                                )
                              }
                            >
                              <Check size={16} />
                            </button>
                            <button
                              className="btn-xs btn rounded-full btn-error"
                              onClick={() =>
                                handleDeleteUser(
                                  user.user_id,
                                  user.email_address,
                                )
                              }
                            >
                              <X size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="text-center w-full text-gray-500"
                        colSpan="10"
                      >
                        No pending {pendingFilter.toLowerCase()} found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {pendingFilter === "Advertisements" && (
            <div className="overflow-auto pt-5">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Advertisement Id</th>
                    <th>User Id</th>
                    <th>Email Address</th>
                    <th>Advertisement</th>
                    <th>Type</th>
                    <th>Alignment</th>
                    <th>Cost</th>
                    <th>View Limit</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-visible">
                  {/* row 1 */}
                  {pending && pending.length > 0 ? (
                    pending.map((advertisement, index) => (
                      <tr key={index} className="overflow-y-visible">
                        <th>{index + 1}</th>
                        <td>
                          <Link to={`/user/${advertisement.user_id}`}>
                            {advertisement.advertisement_id}
                          </Link>
                        </td>

                        <td>
                          <Link
                            to={`/user/${advertisement.user_id}`}
                            className="link link-primary"
                          >
                            {advertisement.user_id}
                          </Link>
                        </td>
                        <td>{advertisement.email_address}</td>
                        <td>
                          {advertisement.advertisement_url ? (
                            <a
                              href={`https://res.cloudinary.com/diur0igkv/image/upload${advertisement.advertisement_url}`}
                              target="_blank"
                              className="link link-primary"
                              rel="noreferrer"
                            >
                              {advertisement.advertisement_url}
                            </a>
                          ) : (
                            "?"
                          )}
                        </td>
                        <td>{advertisement.advertisement_type}</td>
                        <td>{advertisement.alignment}</td>
                        <td>{advertisement.cost}</td>
                        <td>{advertisement.impression_limit}</td>
                        <td className="overflow-y-visible">
                          {/* <button */}
                          {/*   className="" */}
                          {/*   onClick={() => { */}
                          {/*     setUserId(advertisement.user_id); */}
                          {/*     setEmailAddress(advertisement.emailAddress); */}
                          {/*     setCost(advertisement.cost); */}
                          {/*     setAdvertismentId(advertisement.advertisement_id); */}
                          {/*     document.getElementById("my_modal_3").showModal(); */}
                          {/*   }} */}
                          {/* > */}
                          {/*   <Ellipsis /> */}
                          {/* </button> */}
                          <div className="flex gap-3">
                            <button
                              className="btn-xs btn rounded-full btn-success"
                              onClick={() =>
                                handleAcceptAdvertisement(
                                  advertisement.advertisement_id,
                                  advertisement.email_address,
                                )
                              }
                            >
                              <Check size={16} />
                            </button>
                            <button
                              className="btn-xs btn rounded-full btn-error"
                              onClick={() =>
                                handleDeleteAd(
                                  advertisement.advertisement_id,
                                  advertisement.user_id,
                                  advertisement.email_address,
                                  advertisement.cost,
                                )
                              }
                            >
                              <X size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="text-center w-full text-gray-500"
                        colSpan="10"
                      >
                        No pending {pendingFilter.toLowerCase()} found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {pendingFilter === "Transactions" && (
            <div className="overflow-auto pt-5">
              <table className="table">
                {/* head */}
                <thead>
                  <tr>
                    <th></th>
                    <th>Status</th>
                    <th>Transaction Id</th>
                    <th>User Id</th>
                    <th>Email Address</th>
                    <th>Option</th>
                    <th>Amount</th>
                    <th>Created</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="overflow-y-visible">
                  {/* row 1 */}
                  {pending && pending.length > 0 ? (
                    pending.map((transaction, index) => (
                      <tr key={index} className="overflow-y-visible">
                        <th>{index + 1}</th>
                        <td> {transaction.status}</td>
                        <td>{transaction.transaction_id}</td>

                        <td>
                          <Link
                            to={`/user/${transaction.user_id}`}
                            className="link link-primary"
                          >
                            {transaction.user_id}
                          </Link>
                        </td>
                        <td>{transaction.email_address}</td>
                        <td>{transaction.option}</td>
                        <td>{transaction.amount} Keys</td>
                        <td>{transaction.created}</td>
                        <td className="overflow-y-visible">
                          <div className="flex gap-3">
                            <button
                              className="btn-xs btn rounded-full btn-success"
                              onClick={() =>
                                handleAcceptTransaction(
                                  transaction.transaction_id,
                                  transaction.user_id,
                                  transaction.email_address,
                                  transaction.amount,
                                  transaction.option,
                                )
                              }
                            >
                              <Check size={16} />
                            </button>
                            <button
                              className="btn-xs btn rounded-full btn-error"
                              onClick={() =>
                                handleDeleteTransaction(
                                  transaction.transaction_id,
                                  transaction.user_id,
                                  transaction.email_address,
                                  transaction.amount,
                                  transaction.option,
                                )
                              }
                            >
                              <X size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="text-center w-full text-gray-500"
                        colSpan="10"
                      >
                        No pending {pendingFilter.toLowerCase()} found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-end justify-start">
          <label className="form-control w-fit col-span-1">
            <div className="label">
              <span>Remove liquidity manually</span>
            </div>
            <input
              type="text"
              placeholder="Example: 32.00"
              className="input input-bordered w-fit "
              value={amount || ""}
              onChange={(e) => setAmount(e.target.value)}
            />
          </label>
          <button
            className="btn btn-primary btn-sm"
            disabled={amount ? false : true}
            onClick={() => handleUpdateLiquidity()}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Admin;
