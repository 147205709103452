import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/MainContext";
import Admin from "./Admin";

const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const { user, setUser } = useContext(UserContext);
  useEffect(() => {
    if (
      user?.email_address === "jonathanandrewtrevino@gmail.com" ||
      user?.email_address === "admin@myllave.com"
    ) {
      setAuthenticated(true);
    }
  }, [user]);
  return (
    <>
      {authenticated && <Admin />}
      {!authenticated && (
        <p className="text-center text-red-500 pt-32">
          You do not have permission.
        </p>
      )}
    </>
  );
};

export default ProtectedRoute;
