import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {  userByIdPreview } from '../actions/userById'
import { propertyImpressions } from '../actions/propertyImpression'
const PropColumn = ({property}) => {
  const nav = useNavigate()
  const [poster, setPoster] = useState(null)
  useEffect(()=>{
    userByIdPreview(property?.user_id, {setPoster})
  },[])
    const handleClick = (property_id) => {
        propertyImpressions(property_id)
        nav(`/property/${property_id}`)
      }
       function currencyFormat(num) {
        if (!num) {
          return '$0?'
        }
        let money = '$' + Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
         return money.substring(0,money.length-3)
      }
  return (
    <div className="bg-slate-200 relative flex flex-col shadow-md h-[243px]  hover:scale-[1.01] hover:cursor-pointer transition-all w-[243px] group" onClick={()=>handleClick(property?.property_id)}>
        <div className="h-[800px]">
            {/* <img className="w-full h-[100%] object-cover" src="./property.png" alt="Property"/> */}
            <img className="w-full h-[243px] object-cover" src={`https://res.cloudinary.com/diur0igkv/image/upload/${property?.media_url.split(",")[0]}`} alt="Property Preview"/>
        </div>
        <div className="flex flex-col flex-[1] absolute text-white font-extrabold w-full h-full bg-black/30 group-hover:hidden shadow-xl  gap-2">
            <div className="flex justify-between py-2 items-center pr-2">
              {property?.property_status === "Sold" && property.for === 'Sale' ? 
                <h1 className="bg-red-500 text-white p-2">Sold</h1>
                :
                ''
              }
              {property?.property_status === "Sold" && property.for === 'Lease' ?
                <h1 className="bg-red-500 text-white p-2">Leased</h1>
                :
                ''
              }
              {property?.for === 'Sale' && property?.property_status !== 'Sold' ? 
              <h1 className="bg-red-500 text-white p-2">For {property?.for}</h1>
              :
              ''
              }
              {property?.for === 'Lease' && property?.property_status !== 'Sold' ? 
              <h1 className="bg-red-500 text-white p-2">For {property?.for}</h1>
              :
              ''
              }
              {property?.property_status === "Sold"}
              <h1 className="text-19">{currencyFormat(property?.price)}{property?.for === "Lease" ? "/month" : ''}</h1>
            </div>
            <div className="absolute bottom-0 p-2">
              <p className="text-sm">{property?.street_number} {property?.dir_prefix} {property?.street_name} , {property?.city}, TX, {property?.zip}</p>
              <p className="text-sm">{property?.beds_total} Bed | {property?.baths_full} | {property?.living_sqft} sf</p>
              <p className="text-sm">{poster?.legal_first_name} {poster?.legal_last_name}</p>
              <p className="text-sm">{poster?.brokerage_name}</p>
            </div>
        </div>
        {/* <img className="w-[275px] h-[187px] object-cover" src="./property2.jpg" alt="Property"/> */}
    </div>
  )
}

export default PropColumn